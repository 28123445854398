import { ServiceUrl } from '../ServiceURL';

export async function GetProfileInformation() {
    debugger;
    var ExpertId = sessionStorage.getItem("ExpertId")
    let Success = '';
    //parseInt(ExpertId)
    if (ExpertId !== undefined && ExpertId !== "") {
        const data = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ UserId: parseInt(ExpertId) })
        };
        await fetch(ServiceUrl + '/api/GetProfileInformation', data) //https://localhost:5001/api/GetProfileInformation
            .then((response) => response.json()) //https://peachylife.com/peachylifeapi/api/GetProfileInformation
            .then((responseJson) => {
                Success = responseJson;
            })
        return Success;
    }
}

export async function GetReviewInfo() {
    debugger;
    var ExpertId = sessionStorage.getItem("ExpertId")
    //var ClientId = sessionStorage.getItem("UserId")
    let Success = '';
    //parseInt(ExpertId)
    if (ExpertId !== undefined && ExpertId !== "") {
        const data = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ ExpertId: parseInt(ExpertId) })
        };
        await fetch(ServiceUrl + '/api/GetReview', data) //http://192.227.67.200/peachylifeapi/api/GetReview //https://localhost:5001/api/AddReview
            .then((response) => response.json()) //https://peachylife.com/peachylifeapi/api/
            .then((responseJson) => {
                Success = responseJson;
            })
        return Success;
    }
}

export async function SaveReview(review, rating) {
    debugger;
    var ExpertId = sessionStorage.getItem("ExpertId")
    var ClientId = sessionStorage.getItem("UserId")
    let Success = '';
    //parseInt(ExpertId)
    if (ExpertId !== undefined && ExpertId !== "") {
        const data = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ ExpertId: parseInt(ExpertId), ClientId: parseInt(ClientId), Review: review, Rating: parseInt(rating) })
        };
        await fetch(ServiceUrl + '/api/AddReview', data) //http://192.227.67.200/peachylifeapi/api/AddReview //https://localhost:5001/api/AddReview
            .then((response) => response.json()) //https://peachylife.com/peachylifeapi/api/
            .then((responseJson) => {
                Success = responseJson;
            })
        return Success;
    }
}

export async function SaveContactDetails(ContactName, ContactEmail, ContactNote, ContactPhoneNumber, ContactZipCode, ContactVirtualConsultation) {
    debugger;
    var ExpertId = sessionStorage.getItem("ExpertId")
    var Speciality = sessionStorage.getItem("Speciality")
    let Success = '';
    if (ExpertId !== undefined && ExpertId !== "") {
        const data = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                ExpertId: parseInt(ExpertId), ContactName: ContactName, ContactEmail: ContactEmail, Note: ContactNote,
                ExpertEmail: sessionStorage.getItem('ExpertEmail'), Speciality: Speciality, ContactPhoneNumber: ContactPhoneNumber,
                ContactZipCode: ContactZipCode, ContactVirtualConsultation: ContactVirtualConsultation
            })
        };
        await fetch(ServiceUrl + '/api/SendContactDetails', data) //https://localhost:5001/api/SendContactDetails
            .then((response) => response.json()) //https://peachylife.com/peachylifeapi/api/SendContactDetails
            .then((responseJson) => {
                Success = responseJson;
            })
        return Success;
    }
}

export async function GetUserProfileId(eMail) {
    let GetUserField = [];
    const data = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ UserName: eMail })
    };
    await fetch(ServiceUrl + '/api/GetUserProfileId', data) //https://localhost:5001/api/GetUserProfileId //http://192.227.67.200/peachylifeapi/api/GetUserProfileId
        .then((response) => response.json()) //https://peachylife.com/PeachyLifeApi/api/
        .then((responseJson) => {
            GetUserField = responseJson;
        })
    return GetUserField;
}


export async function GetAnswersByExpert(ExpertId) {
    let GetUserField = [];
    const data = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ExpertId })
    };
    await fetch(ServiceUrl + '/api/GetAnswersByExpert', data) //https://localhost:5001/api/GetAnswersByExpert
        .then((response) => response.json()) //https://peachylife.com/PeachyLifeApi/api/GetAnswersByExpert
        .then((responseJson) => {
            GetUserField = responseJson;
        })
    return GetUserField;
}

export async function GetExpertArticles(ExpertId) {
    let GetUserField = [];
    const data = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ExpertId })
    };
    await fetch(ServiceUrl + '/api/GetExpertArticles', data) //https://localhost:5001/api/GetExpertArticles
        .then((response) => response.json()) //https://peachylife.com/PeachyLifeApi/api/GetExpertArticles
        .then((responseJson) => {
            GetUserField = responseJson;
        })
    return GetUserField;
}

export async function GetExpertVideos(ExpertId) {
    let GetUserField = [];
    const data = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ExpertId })
    };
    await fetch(ServiceUrl + '/api/GetExpertVideos', data) //https://localhost:5001/api/GetExpertVideos
        .then((response) => response.json()) //https://peachylife.com/PeachyLifeApi/api/GetExpertVideos
        .then((responseJson) => {
            GetUserField = responseJson;
        })
    return GetUserField;
}