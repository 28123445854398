import React from 'react';
import { Button, Modal, Input, Slider, Popover, Space, Carousel, Menu, Dropdown, Row, Col, Card, Divider, Form, Radio, Checkbox } from 'antd';
import 'antd/dist/antd.css';
import RLDD from 'react-list-drag-and-drop/lib/RLDD';
import { Motion, spring } from 'react-motion';
import '../Css/Peachy.css';
import { SaveUserInformation, GetUserInformation } from './InformationService';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ReactGA from 'react-ga';

class Information extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Name: '', Email: '', Password: '', RepeatPassword: '', loginShow: false, AdviceMenu: [], ArticlesMenu: [],
            City: '', State: '', MaritalStatus: '', Sex: '', Age: 20, FieldsOfInterest: [], VirtualConsultations: '', ZipCode: '',
            // StyleMatch: [{ id: 0, title: "COLLABORATIVE", body: "Consensus oriented working towards agreement", color: "#7bbe31" },
            // { id: 1, title: "AGGRESSIVE", body: "Focussed on a goal and determined to win", color: "#eb616c" },
            // { id: 2, title: "CREATIVE", body: "Looking for unique solutions and ways forward", color: "#4593ea" },
            // { id: 3, title: "EMPATHETIC", body: "Considerate of all parties and their happiness", color: "#333852" }],
            StyleMatch: [{ id: 0, dragId: 'item-0', title: "COLLABORATIVE", body: "Efficient, prudent, goal-oriented, team player, cooperative", color: "#7bbe31" },
            { id: 1, dragId: 'item-1', title: "AGGRESSIVE", body: "Forceful, directive, assertive, commanding, dynamic", color: "#eb616c" },
            { id: 2, dragId: 'item-2', title: "CREATIVE", body: "Strategic, leading edge, innovative, enterprising, imaginative", color: "#4593ea" },
            { id: 3, dragId: 'item-3', title: "EMPATHETIC", body: "Good listener, patient, sensitive, earnest, compassionate, understanding", color: "#333852" },
            { id: 4, dragId: 'item-4', title: "APPROCHABLE", body: "Understanding, direct, sympathetic, considerate, responsive", color: "#FFD20A" }],
            ContactPreference: '', Comments: '',
            checkBoxValue: [
                { label: 'Family', value: 'Family' }, { label: 'Therapy', value: 'Therapy' },
                { label: 'Financial', value: 'Financial' }, { label: 'ChildCare', value: 'ChildCare' },
                { label: 'Estate', value: 'Estate' },
                { label: 'Education', value: 'Education' },
            ],
        }
        this.itemRenderer = this.itemRenderer.bind(this);
        this.handleRLDDChange = this.handleRLDDChange.bind(this);
    }

    async componentDidMount() {
        // const script = document.createElement("script");
        // script.src = "https://peachylife.com/MT/js/plmarriagetest3-944b06bbb-864f41afbdea5.js";
        // script.async = true;

        // document.body.appendChild(script);

        import('../Css/components.css')
        import('../Css/normalize.css')
        import('../Css/plmarriagetest.css')

        ReactGA.initialize('G-JT4HS6ZZSX');
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.gtag('event', 'conversion', { 'send_to': 'AW-10806936548/AfZeCJKY9YQDEOSHk6Eo' });
        
        if (sessionStorage.getItem("Success") === "true") {
            if (sessionStorage.getItem('UserId') !== '' && sessionStorage.getItem('UserId') !== "null" && sessionStorage.getItem('UserId') !== null) {
                var x = sessionStorage.getItem('UserId')
                let returnedData;
                returnedData = await GetUserInformation();
                if (returnedData.userId !== 0) {
                    if (returnedData.styleMatch !== "" || returnedData.styleMatch !== undefined) {
                        var tempStyleMatch = JSON.parse(returnedData.styleMatch);
                    }
                    if (tempStyleMatch !== undefined) {
                        this.setState({ StyleMatch: tempStyleMatch })
                    }
                    if (returnedData.specialist !== "" || returnedData.specialist !== undefined) {
                        var data = JSON.parse(returnedData.specialist);
                        this.setState({ FieldsOfInterest: data })
                    }
                    console.log(this.state.FieldsOfInterest)

                    var tempAge = returnedData.age.toString();

                    this.setState({
                        Age: tempAge, City: returnedData.city, Comments: returnedData.comments,
                        ContactPreference: returnedData.contactPreference,
                        Sex: returnedData.gender, MaritalStatus: returnedData.maritalStatus,
                        State: returnedData.state, VirtualConsultations: returnedData.videoConference,
                        ZipCode: returnedData.zipCode
                    })
                }
            }
        }
    }

    onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const StyleMatch = this.reorder(
            this.state.StyleMatch,
            result.source.index,
            result.destination.index
        );

        this.setState({
            StyleMatch
        });
    }

    // a little function to help us with reordering the result
    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    InfomationDataSubmit = async () => {
        debugger;
        if (sessionStorage.getItem('UserId') !== '' && sessionStorage.getItem('UserId') !== "null" && sessionStorage.getItem('UserId') !== null) {
            var sendData = []
            let jsonSpecialist = JSON.stringify(this.state.StyleMatch);
            let jsonFieldsOfInterest = JSON.stringify(this.state.FieldsOfInterest);
            sendData = [this.state.Age, this.state.City, this.state.Comments, this.state.ContactPreference, jsonFieldsOfInterest,
            this.state.MaritalStatus, this.state.Sex, this.state.State, jsonSpecialist, this.state.VirtualConsultations, this.state.ZipCode]
            var returnedData = await SaveUserInformation(sendData);

            if (returnedData.success === true) {
                Modal.success({ content: "Saved Successfully" });
            }
            else if (returnedData.success === false)
                Modal.warning({ content: "Data not Saved" });
            else
                Modal.warning({ content: "Data not Saved" });
        }
        else {
            Modal.warning({ content: "Kindly Login/Register to save the data" });
        }
    }

    AfterChange = (e) => {
        this.setState({ Age: e })
    }
    CheckBoxChange = (Values) => {
        this.setState({ FieldsOfInterest: Values })
    }
    itemRenderer(item, index) {
        return (
            <div className="item">
                <Row>
                    <Col span={10}>
                        <p className="title dndReact" style={{ backgroundColor: item.color }}>{item.title}</p>
                    </Col>
                    <Col span={14}>
                        <p className="body dndReact">{item.body}</p>
                    </Col>
                </Row>
            </div>
        );
    }

    TermsClick = () => {
        var data = { component: 'Terms' }
        this.ContentLoad(data);
    }

    handleRLDDChange(reorderedItems) {
        this.setState({ StyleMatch: reorderedItems });
    }

    render() {
        const { TextArea } = Input

        const items = this.state.StyleMatch;

        return (
            <div>
                <div class="w-layout-grid formgrid1">
                    <div id="w-node-ddc8f959b84e-933ed30c" class="formleft">
                        <h1 class="subheadline a">INFORMATION</h1>
                        <h3 class="heading i">Location</h3>
                        <h3 class="heading b">Marital Status</h3>
                        <h3 class="heading d">Sex</h3>
                        <h3 class="heading d">Your Age</h3>
                        <h3 class="heading e"><span class="text-span-10">Specialists</span><br /><span class="text-span-13">Do you have needs or<br />interests in these areas ?<br />(check all that apply)</span></h3>
                        <h3 class="heading f"><span class="text-span-11">Style Match</span><br /><span class="text-span-14">When selecting a professional<br />we can match to the general<br />style or approach you prefer.<br /><br />Drag the choices to re-order<br />to your preferences</span></h3>
                        <div class="div-block-28"></div>
                        <h3 class="heading a">Contact Preference</h3>
                        <h3 class="heading g">Comments/ Questions</h3>
                    </div>
                    <div id="w-node-ddc8f959b85c-933ed30c" class="accountssectiondiv">
                        <div class="div-block-12"></div>
                    </div>
                    <div id="w-node-ddc8f959b85d-933ed30c" class="accountsection">
                        <div class="bluetitle">Tell us About Yourself<br /><br /></div><br /><br /><br />
                        <a class="formboxtextentry top w-inline-block">
                            <Input className="formfieldbox short" placeholder="City" value={this.state.City} onChange={(e) => { this.setState({ City: e.target.value }) }} />
                        </a>
                        <a class="formboxtextentry w-inline-block">
                            <Input className="formfieldbox short" placeholder="State" value={this.state.State} onChange={(e) => { this.setState({ State: e.target.value }) }} />
                        </a>
                        <a class="formboxtextentry w-inline-block">
                            <Input className="formfieldbox short" placeholder="ZipCode" value={this.state.ZipCode} onChange={(e) => { this.setState({ ZipCode: e.target.value }) }} />
                        </a>
                        <div class="formfieldbox chek w-inline-block">
                            <div class="bluetitle">Relationship Status</div>
                            <Radio.Group name="MaritialStatus" className="shorter-checkbox" value={this.state.MaritalStatus} onChange={(e) => { this.setState({ MaritalStatus: e.target.value }) }}>
                                <div className="InfoCheckbox">
                                    <Radio className="checkbox2" value="2" ></Radio>
                                    <div class="chckboxtitle-v2">Married </div>
                                </div>
                                <div className="InfoCheckbox">
                                    <Radio className="checkbox2" value="1" ></Radio>
                                    <div class="chckboxtitle-v2">Single</div>
                                </div>
                                <div className="InfoCheckbox">
                                    <Radio className="checkbox2" value="3" ></Radio>
                                    <div class="chckboxtitle-v2">Couple</div>
                                </div>
                            </Radio.Group>
                        </div>

                        <div className="formfieldbox chek w-inline-block">
                            <div className="bluetitle">Sex</div>
                            <Radio.Group name="Gender" className="shorter-checkbox" value={this.state.Sex} onChange={(e) => { this.setState({ Sex: e.target.value }) }}>
                                <div className="InfoCheckbox">
                                    <Radio className="checkbox2" value="1"></Radio>
                                    <div className="chckboxtitle">Man</div>
                                </div>
                                <div className="InfoCheckbox">
                                    <Radio className="checkbox2" value="2"></Radio>
                                    <div className="chckboxtitle">Women</div>
                                </div>
                                <div className="InfoCheckbox">
                                    <Radio className="checkbox2" value="3"></Radio>
                                    <div className="chckboxtitle">Other</div>
                                </div>
                            </Radio.Group>

                        </div>
                        <div className="AgeSlider">
                            <div class="bluetitle">Age</div>
                            <div className="">
                                <Slider value={this.state.Age} min={20} onChange={this.AfterChange} tooltipVisible
                                    handleStyle={{
                                        transitionDelay: " 0s",
                                        width: "30px",
                                        height: "30px",
                                        position: "absolute",
                                        transitionDuration: "0s",
                                        backgroundSize: "cover",
                                        content: "",
                                        left: " -1px",
                                        top: "-6px"
                                    }} />
                            </div>
                        </div>
                        <div className="profession-selector">
                            <div className="bluetitle a">Select specialists you may be interested in</div>
                            <Checkbox.Group className="FieldsOfInterestCheckBoxGroup" value={this.state.FieldsOfInterest} onChange={this.CheckBoxChange}>
                                <div className="shorter-checkbox w-inline-block">
                                    <div className="InfoCheckbox">
                                        <Checkbox className="checkbox2" value="Family"></Checkbox>
                                        <div className="chckboxtitle-v2">Family Law</div>
                                    </div>
                                    <div className="InfoCheckbox">
                                        <Checkbox className="checkbox2" value="Therapy"></Checkbox>
                                        <div className="chckboxtitle-v2">Therapy</div>
                                    </div>
                                    <div className="InfoCheckbox">
                                        <Checkbox className="checkbox2" value="Childcare"></Checkbox>
                                        <div className="chckboxtitle-v2">Childcare</div>
                                    </div>
                                </div>
                                <div className="shorter-checkbox w-inline-block">
                                    <div className="InfoCheckbox">
                                        <Checkbox className="checkbox2" value="Financial"></Checkbox>
                                        <div className="chckboxtitle-v2">Financial</div>
                                    </div>
                                    <div className="InfoCheckbox">
                                        <Checkbox className="checkbox2" value="Estate"></Checkbox>
                                        <div className="chckboxtitle-v2">Estate</div>
                                    </div>
                                    <div className="InfoCheckbox">
                                        <Checkbox className="checkbox2" value="Education"></Checkbox>
                                        <div className="chckboxtitle-v2">Education</div>
                                    </div>
                                </div>
                            </Checkbox.Group>
                        </div>
                        <div class="profession-selector">
                            <div class="bluetitle a">Drag bars below to re-order to your preference</div>
                            {/* <Form.Item name="StyleMatch" className="dragAnddrop">
                                <RLDD
                                    cssClasses="example dditem"
                                    items={items}
                                    itemRenderer={this.itemRenderer}
                                    onChange={this.handleRLDDChange}
                                />
                            </Form.Item> */}
                            <DragDropContext onDragEnd={this.onDragEnd}>
                                <Droppable droppableId="droppable">
                                    {(provided, snapshot) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                        >
                                            <div name="StyleMatch" className="dragAnddrop">
                                                {this.state.StyleMatch.map((item, index) => (
                                                    <Draggable key={item.id} draggableId={item.dragId} index={index}>
                                                        {(provided, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <div className="item">
                                                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                                                        <Col span={10}>
                                                                            <p className="title dndReact" style={{ backgroundColor: item.color, height: 'auto', fontSize: 'large', fontWeight: 100 }}>{item.title}</p>
                                                                        </Col>
                                                                        <Col span={14}>
                                                                            <p className="body">{item.body}</p>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                        <div class="profession-selector-v2">
                            <a class="link-block-4 w-inline-block">
                                <div class="bluetitle center">Are you interested in Virtual Consultations? <br /><span class="text-span-12">( Meetings conducted online)</span></div>
                            </a>
                            <div className="formfieldbox-v2 w-inline-block">
                                <Radio.Group className="shorter-checkbox" value={this.state.VirtualConsultations} onChange={(e) => { this.setState({ VirtualConsultations: e.target.value }) }} defaultValue={this.state.VirtualConsultations}>
                                    <div className="InfoCheckbox">
                                        <Radio className="checkbox2" value="1"></Radio>
                                        <div class="chckboxtitle-v2">YES</div>
                                    </div>
                                    <div className="InfoCheckbox">
                                        <Radio className="checkbox2 a" value="Possibly"></Radio>
                                        <div class="chckboxtitle-v2">Possibly</div>
                                    </div>
                                    <div className="InfoCheckbox">
                                        <Radio className="checkbox2" value="0"></Radio>
                                        <div class="chckboxtitle-v2">NO</div>
                                    </div>
                                </Radio.Group>
                            </div>
                        </div>
                        <div class="profession-selector-v2">
                            <a class="link-block-4 w-inline-block">
                                <div class="bluetitle center">Ok to e-mail me with relevant information: <br /></div>
                            </a>
                            <div className="formfieldbox-v2 w-inline-block">
                                <Radio.Group className="shorter-checkbox" value={this.state.ContactPreference} onChange={(e) => { this.setState({ ContactPreference: e.target.value }) }} defaultValue={this.state.ContactPreference}>
                                    <div className="InfoCheckbox">
                                        <Radio className="checkbox2" value="1"></Radio>
                                        <div class="chckboxtitle-v2">YES</div>
                                    </div>
                                    <div className="InfoCheckbox">
                                        <Radio className="checkbox2 a" value="2"></Radio>
                                        <div class="chckboxtitle-v2">Only Related to my Account</div>
                                    </div>
                                </Radio.Group>
                            </div>
                        </div>
                        <div class="profession-selector box">
                            <div class="bluetitle left">Please note any questions or comments here</div>
                            <TextArea className="formfieldbox w-inline-block" autoSize={{ minRows: 6, maxRows: 6 }} className="InfoTextArea" value={this.state.Comments} onChange={(e) => { this.setState({ Comments: e.target.value }) }}>
                            </TextArea>
                        </div>
                        <div class="savecaptcha">
                            <a href="#" class="infosavebutton w-button" onClick={this.InfomationDataSubmit} >Save</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}
export default Information