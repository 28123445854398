import React from 'react';
import { Collapse } from 'antd';
import { withRouter } from 'react-router';
import ReactGA from 'react-ga';
import { GetAllFAQ } from '../Question/questionService';

class TopQuestion extends React.Component {
    constructor(props) {
        super(props);

        this.state = { QuestionList: [] }
        this.myDivToFocus = React.createRef();
    }

    async componentDidMount() {
        // debugger;
        // let questionList = await GetAllFAQ();

        // this.setState({ QuestionList: questionList.question })

        ReactGA.initialize('G-JT4HS6ZZSX');
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.gtag('event', 'conversion', { 'send_to': 'AW-10806936548/AfZeCJKY9YQDEOSHk6Eo' });

    }

    getExperts = () => {
        debugger;
        var x = this.myDivToFocus;
        this.behavior(x);
    }

    behavior = (x) => {
        x.current.scrollIntoView({
            behavior: "smooth",
            block: "nearest"
        })
    }

    Questionclick = () => {
        this.props.history.push('/Questions');
    }

    render() {

        const { Panel } = Collapse;

        const PeachyLogo = process.env.PUBLIC_URL + '/images/PeachyLifeQs_1PeachyLifeQs.png';
        const MyWyeLogo = process.env.PUBLIC_URL + '/images/logoqa.jpg.png';

        return (
            <div>
                {/* <div class="section2"> */}
                <div class="questionspromo">
                    {/* <h3 class="subtitle-2red">Ask a<br />Top Professional<br />a Question</h3> */}
                    <h3 class="subtitle-2red">Ask a Professional<br /></h3>
                    <div class="ctared">Meet Top Professionals</div>
                    <h1 class="ctablue">If you have a question or concern about anything related to marriage, divorce, relationships, children &amp; education, family finances, or even real estate, we have professional resources to help you...</h1>
                    <div class="questionsblock">
                        <div class="ctawhite top">Ask Your Question Here</div>
                        <div class="div-block-56" onClick={this.Questionclick}>
                            <div class="text-block-82"><strong class="bold-text">Q</strong></div>
                            <div class="div-block-57" ><img src={process.env.PUBLIC_URL + "/images/Blinking-Cursor2.gif"} loading="lazy" alt="" class="image-20" /></div>
                        </div>
                        <div class="ctawhite" onClick={() => this.getExperts}>See Popular Questions</div>
                    </div>
                    <h1 class="ctaexplaiungrey">Ask a question you have concerns about, we send your question to our community of peer-reviewed professionals. You get replies back and choose when to make contact if you want. You have no-obligation. Your contact information is not shared.</h1>
                    <div data-duration-in="300" data-duration-out="100" ref={this.myDivToFocus} class="w-tabs">
                        <div class="tabs-menu w-tab-menu">
                            <a data-w-tab="Tab 2" class="tablink w-inline-block w-tab-link"><img src={process.env.PUBLIC_URL + '/images/Insurance_1.png'} loading="lazy" alt="" class="procircle" />
                                <div class="div-block-42">
                                    <div class="protitle">Financial</div>
                                </div>
                            </a>
                            <a data-w-tab="Tab 3" class="tablink w-inline-block w-tab-link w--current"><img src={process.env.PUBLIC_URL + '/images/Counselling.png'} loading="lazy" alt="" class="procircle" />
                                <div class="div-block-42">
                                    <div class="protitle">Family Law</div>     {/*w--current*/}
                                </div>
                            </a>
                            <a data-w-tab="Tab 4" class="tablink w-inline-block w-tab-link"><img src={process.env.PUBLIC_URL + '/images/Lawyer_2.png'} loading="lazy" alt="" class="procircle" />
                                <div class="div-block-42">
                                    <div class="protitle">Investments</div>
                                </div>
                            </a>
                            <a data-w-tab="Tab 5" class="tablink w-inline-block w-tab-link"><img src={process.env.PUBLIC_URL + '/images/Financial_1.png'} loading="lazy" alt="" class="procircle" />
                                <div class="div-block-42">
                                    <div class="protitle">Counseling</div>
                                </div>
                            </a>
                        </div>

                        <div class="tabs-content w-tab-content">
                            <div data-w-tab="Tab 2" class="tab-pane-tab-1 w-tab-pane">
                                <div class="questionsslug">
                                    <div class="title-reverse-spaced">POPULAR FINANCIAL QUESTIONS</div>
                                    <div class="w-layout-grid enlargedqgrid" onClick={this.Questionclick}>
                                        <a id="w-node-2cc67bc3a2ca-ee322428" href="#" class="questiontitle w-inline-block">
                                            <div class="articletitle">Do I need a pre-nuptial or post-nuptial agreement</div>
                                        </a>
                                        <a id="w-node-2cc67bc3a2cd-ee322428" href="#" class="questiontitle w-inline-block">
                                            <div class="articletitle">What are common financial mistakes in a divorce</div>
                                        </a>
                                        <a id="w-node-2cc67bc3a2d0-ee322428" href="#" class="questiontitle w-inline-block">
                                            <div class="articletitle">What value does a Financial Planner bring to couples?</div>
                                        </a>
                                        <a id="w-node-2cc67bc3a2d3-ee322428" href="#" class="questiontitle w-inline-block">
                                            <div class="articletitle">Do you need your own health insurance?</div>
                                        </a>
                                        <a id="w-node-2cc67bc3a2d6-ee322428" href="#" class="questiontitle w-inline-block">
                                            <div class="articletitle">How much of my retirement funds does my ex get?</div>
                                        </a>
                                        <a id="w-node-2cc67bc3a2d9-ee322428" href="#" class="questiontitle w-inline-block">
                                            <div class="articletitle">I make less money. Do I have to pay child support?</div>
                                        </a>
                                    </div>
                                    <div class="div-block-37" onClick={this.Questionclick}>
                                        <div data-hover="1" data-delay="0" class="dropdown-q a w-dropdown">
                                            <div class="q-stogglebig w-dropdown-toggle">
                                                <a href="#" class="qsblock w-inline-block"><img src={process.env.PUBLIC_URL + '/images/PeachyLifeQs_1PeachyLifeQs.png'} loading="lazy" alt="" class="questionq" /></a>
                                            </div>
                                            <nav class="qsdropdown w-dropdown-list" onClick={this.Questionclick}>
                                                <div class="dropdown-list-full-wrapper-copy">
                                                    <a href="#" class="dropdown-qssection w-inline-block">
                                                        <div class="icon-square"><img src={process.env.PUBLIC_URL + '/images/PeachyLifeQs_1PeachyLifeQs.png'} alt="" class="qinset" /></div>
                                                        <p class="paragraph-4"><span class="text-span-18">Ask a Top Professional:<br /></span><span class="text-span-20" onClick={this.Questionclick} >GET YOUR QUESTION ANSWERED</span></p>
                                                        <p class="categories">• FAMILY LAW<br />• FINANCIAL<br />• MEDIATION<br />• INSURANCE<br />• COUNSELING<br />• REAL ESTATE</p>
                                                    </a>
                                                </div>
                                            </nav>
                                        </div>
                                        <div class="ctared" onClick={this.Questionclick}>Ask Your Question</div>
                                    </div>
                                </div>
                            </div>

                            <div data-w-tab="Tab 3" class="tab-pane-tab-1 w-tab-pane w--tab-active">
                                <div class="questionsslug">
                                    <div class="title-reverse-spaced">QUESTIONS ABOUT FAMILY LAW</div>
                                    <div class="w-layout-grid enlargedqgrid" onClick={this.Questionclick}>
                                        <a id="w-node-dffd699ce5a3-ee322428" href="#" class="questiontitle w-inline-block">
                                            <div class="articletitle">How long do I have to dispute the support payment proposed?</div>
                                        </a>
                                        <a id="w-node-dffd699ce5a6-ee322428" href="#" class="questiontitle w-inline-block">
                                            <div class="articletitle">Can I refuse to accept a divorce filing?</div>
                                        </a>
                                        <a id="w-node-dffd699ce5a9-ee322428" href="#" class="questiontitle w-inline-block">
                                            <div class="articletitle">Why does my spouse get custody of our family dog?</div>
                                        </a>
                                        <a id="w-node-dffd699ce5ac-ee322428" href="#" class="questiontitle w-inline-block">
                                            <div class="articletitle">How much time do I have to dispute a settlement agreement?</div>
                                        </a>
                                        <a id="w-node-dffd699ce5af-ee322428" href="#" class="questiontitle w-inline-block">
                                            <div class="articletitle">Who is more likely to keep the house?</div>
                                        </a>
                                        <a id="w-node-dffd699ce5b2-ee322428" href="#" class="questiontitle w-inline-block">
                                            <div class="articletitle">When do I have to tell my work?</div>
                                        </a>
                                    </div>
                                    <div class="div-block-37">
                                        <div data-hover="1" data-delay="0" class="dropdown-q a w-dropdown">
                                            <div class="q-stogglebig w-dropdown-toggle">
                                                <a href="#" class="qsblock w-inline-block"><img src={process.env.PUBLIC_URL + '/images/PeachyLifeQs_1PeachyLifeQs.png'} loading="lazy" alt="" class="questionq" /></a>
                                            </div>
                                            <nav class="qsdropdown w-dropdown-list">
                                                <div class="dropdown-list-full-wrapper-copy">
                                                    <a href="#" class="dropdown-qssection w-inline-block" onClick={this.Questionclick}>
                                                        <div class="icon-square"><img src={process.env.PUBLIC_URL + '/images/PeachyLifeQs_1PeachyLifeQs.png'} alt="" class="qinset" /></div>
                                                        <p class="paragraph-4"><span class="text-span-18">Ask a Top Professional:<br /></span><span class="text-span-20" onClick={this.Questionclick}>GET YOUR QUESTION ANSWERED</span></p>
                                                        <p class="categories">• FAMILY LAW<br />• FINANCIAL<br />• MEDIATION<br />• INSURANCE<br />• COUNSELING<br />• REAL ESTATE</p>
                                                    </a>
                                                </div>
                                            </nav>
                                        </div>
                                        <div class="ctared" onClick={this.Questionclick}>Ask Your Question</div>
                                    </div>
                                </div>
                            </div>

                            <div data-w-tab="Tab 4" class="tab-pane-tab-1 w-tab-pane">
                                <div class="questionsslug">
                                    <div class="title-reverse-spaced">POPULAR INVESTMENT QUESTIONS</div>
                                    <div class="w-layout-grid enlargedqgrid" onClick={this.Questionclick}>
                                        <a id="w-node-2cc67bc3a2ca-ee322428" href="#" class="questiontitle w-inline-block">
                                            <div class="articletitle">Can I continue to be on the title of the house?</div>
                                        </a>
                                        <a id="w-node-2cc67bc3a2cd-ee322428" href="#" class="questiontitle w-inline-block">
                                            <div class="articletitle">Do I still have equal rights to future growth in the value of our house?</div>
                                        </a>
                                        <a id="w-node-2cc67bc3a2d0-ee322428" href="#" class="questiontitle w-inline-block">
                                            <div class="articletitle">What Investments do I have to disclose in a divorce?</div>
                                        </a>
                                        <a id="w-node-2cc67bc3a2d3-ee322428" href="#" class="questiontitle w-inline-block">
                                            <div class="articletitle">Are stock options in private companies ok to keep secret?</div>
                                        </a>
                                        <a id="w-node-2cc67bc3a2d6-ee322428" href="#" class="questiontitle w-inline-block">
                                            <div class="articletitle">How much money can I hide from my spouse without getting in trouble?</div>
                                        </a>
                                        <a id="w-node-2cc67bc3a2d9-ee322428" href="#" class="questiontitle w-inline-block">
                                            <div class="articletitle">How long do we continue to file joint taxes?</div>
                                        </a>
                                    </div>
                                    <div class="div-block-37" onClick={this.Questionclick}>
                                        <div data-hover="1" data-delay="0" class="dropdown-q a w-dropdown">
                                            <div class="q-stogglebig w-dropdown-toggle">
                                                <a href="#" class="qsblock w-inline-block"><img src={MyWyeLogo} loading="lazy" alt="" class="questionq" /></a>
                                            </div>
                                            <nav class="qsdropdown w-dropdown-list" onClick={this.Questionclick}>
                                                <div class="dropdown-list-full-wrapper-copy">
                                                    <a href="#" class="dropdown-qssection w-inline-block">
                                                        <div class="icon-square"><img src={process.env.PUBLIC_URL + '/images/PeachyLifeQs_1PeachyLifeQs.png'} alt="" class="qinset" /></div>
                                                        <p class="paragraph-4"><span class="text-span-18">Ask a Top Professional:<br /></span><span class="text-span-20" onClick={this.Questionclick} >GET YOUR QUESTION ANSWERED</span></p>
                                                        <p class="categories">• FAMILY LAW<br />• FINANCIAL<br />• MEDIATION<br />• INSURANCE<br />• COUNSELING<br />• REAL ESTATE</p>
                                                    </a>
                                                </div>
                                            </nav>
                                        </div>
                                        <div class="ctared" onClick={this.Questionclick}>Ask Your Question</div>
                                    </div>
                                </div>
                            </div>

                            <div data-w-tab="Tab 5" class="tab-pane-tab-1 w-tab-pane">
                                <div class="questionsslug">
                                    <div class="title-reverse-spaced">QUESTIONS ABOUT COUNSELING</div>
                                    <div class="w-layout-grid enlargedqgrid" onClick={this.Questionclick}>
                                        <a id="w-node-dffd699ce5a3-ee322428" href="#" class="questiontitle w-inline-block">
                                            <div class="articletitle">If I’m angry with my ex, should I hide it from the children?</div>
                                        </a>
                                        <a id="w-node-dffd699ce5a6-ee322428" href="#" class="questiontitle w-inline-block">
                                            <div class="articletitle">I love my spouse, but we fight a lot. Should I get a divorce?</div>
                                        </a>
                                        <a id="w-node-dffd699ce5a9-ee322428" href="#" class="questiontitle w-inline-block">
                                            <div class="articletitle">Is it better to stay in a bad marriage until the kids are grown up?</div>
                                        </a>
                                        <a id="w-node-dffd699ce5ac-ee322428" href="#" class="questiontitle w-inline-block">
                                            <div class="articletitle">How many people have affairs and hide the fact?</div>
                                        </a>
                                        <a id="w-node-dffd699ce5af-ee322428" href="#" class="questiontitle w-inline-block">
                                            <div class="articletitle">Is it better to tell the truth and be hurtful or lie and be nice?</div>
                                        </a>
                                        <a id="w-node-dffd699ce5b2-ee322428" href="#" class="questiontitle w-inline-block">
                                            <div class="articletitle">Who gets to keep our best friends if we split up?</div>
                                        </a>
                                    </div>
                                    <div class="div-block-37">
                                        <div data-hover="1" data-delay="0" class="dropdown-q a w-dropdown">
                                            <div class="q-stogglebig w-dropdown-toggle">
                                                <a href="#" class="qsblock w-inline-block"><img src={process.env.PUBLIC_URL + '/images/PeachyLifeQs_1PeachyLifeQs.png'} loading="lazy" alt="" class="questionq" /></a>
                                            </div>
                                            <nav class="qsdropdown w-dropdown-list">
                                                <div class="dropdown-list-full-wrapper-copy">
                                                    <a href="#" class="dropdown-qssection w-inline-block" onClick={this.Questionclick}>
                                                        <div class="icon-square"><img src={process.env.PUBLIC_URL + '/images/PeachyLifeQs_1PeachyLifeQs.png'} alt="" class="qinset" /></div>
                                                        <p class="paragraph-4"><span class="text-span-18">Ask a Top Professional:<br /></span><span class="text-span-20" onClick={this.Questionclick}>GET YOUR QUESTION ANSWERED</span></p>
                                                        <p class="categories">• FAMILY LAW<br />• FINANCIAL<br />• MEDIATION<br />• INSURANCE<br />• COUNSELING<br />• REAL ESTATE</p>
                                                    </a>
                                                </div>
                                            </nav>
                                        </div>
                                        <div class="ctared" onClick={this.Questionclick}>Ask Your Question</div>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="FAQCollapse">
                                <Collapse accordion bordered={false} ghost className="site-collapse-custom-collapse qandaCollapse">
                                    {this.state.QuestionList.length !== 0 ? this.state.QuestionList.map((data, index) => {
                                        return <Panel header={data.question} key={index}>
                                            <p>{data.answer}</p>
                                        </Panel>
                                    }) : null}
                                </Collapse>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(TopQuestion)