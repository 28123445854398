import React from 'react';
import BoxComponent from '../BoxComponent/BoxComponent';
import ReactDOM from 'react-dom';
import VideoPlayer from 'react-video-js-player'; // VIDEO JS PLAYER (IOS and others)
import { Player, ControlBar, ClosedCaptionButton, BigPlayButton } from 'video-react';
import 'video-react/dist/video-react.css';
import Footer from '../Footer/Footer';
import Header from '../Dashboard/Dashboard';
import {
    GetProfileInformation, SaveReview, GetReviewInfo, SaveContactDetails, GetUserProfileId, GetAnswersByExpert, GetExpertArticles,
    GetExpertVideos
} from '../Profile/ProfessionalProfileService';
import { Row, Col, Rate, Modal, message, Input, Divider, Spin, Image, Collapse, Checkbox } from 'antd';
import MetaTags from 'react-meta-tags';
import { FrownOutlined, MehOutlined, SmileOutlined, SettingOutlined } from '@ant-design/icons';
import '../MarriageCss/plmarriagetest3-944b06bbb-3aff3ee893f30.webflow.css';
import { ServiceUrl, FilesURL } from '../ServiceURL';
import { GetMetaTags } from '../MetaTags/MetaTagsService';
import ReactGA from 'react-ga';


class Profile extends React.Component {
    constructor(props) {
        super(props);

        // window.addEventListener('scroll', this.scrollFunc, true);

        this.state = {
            Introclicked: true, q1Clicked: false, q2Clicked: false, q3Clicked: false, Spinner: true,

            Firstname: '', Lastname: '', Speciality: '', Rating: 0,
            StreetAddress: '', City: '', State: '', ZipCode: '', CompleteAddress: '', PhoneNumber: '', Latitude: '', Longitude: '',
            BackgroundIntroduction: '', BackgroundDetails: '', Video1: '', Video2: '', Video3: '', Video4: '',
            PROFESSIONAL: [], CREDENTIALS: [], isModalVisible: false, Reviews: '', Rating: 0,
            ReviewsList: [], AverageRating: 0, ReviewsCount: 0, ReadReviews: false,
            backgroundImage: 'https://mywye.com/peachylife_/media/images/head_bob.gif',
            ContactFirstName: '', ContactEmail: '', ContactLastName: '', ContactNote: '', ExpertEmail: '',
            ContactPhoneNumber: '', ContactZipCode: '', ContactVirtualConsult: false,
            Video1Content: '', Video2Content: '', Video3Content: '', Video4Content: '',
            Subtitle1: '', Subtitle2: '', Subtitle3: '', Subtitle4: '',
            Video1Image: '', Video2Image: '', Video3Image: '', Video4Image: '',
            Transcript1: '', Transcript2: '', Transcript3: '', Transcript4: '',
            SquarePortrait: "", LargerHorizontal: "", Website: '',
            QuestionsList: [], ArticlesList: [], VideoList: [], MetaTags: [], Sticky: 0,
            MetaTitle: '',
        }

        // Reference for moving between elements
        this.IntroDiv = React.createRef()
        this.AboutDiv = React.createRef()
        this.QxADiv = React.createRef()
        this.VideosDiv = React.createRef()
        this.ArticlesDiv = React.createRef()
        this.ContactDiv = React.createRef()
        this.ReviewsDiv = React.createRef()
        // End of Declaration

        // Reference fot Video Player
        this.setMuted = this.setMuted.bind(this);
        // End of References
    }

    componentWillMount() {
        import('../Css/components.css')
        import('../Css/normalize.css')
        import('../Css/plmarriagetest.css')

        ReactGA.initialize('G-JT4HS6ZZSX');
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.gtag('event', 'conversion', { 'send_to': 'AW-10806936548/AfZeCJKY9YQDEOSHk6Eo' });

        // this.gtag_report_conversion(this.props.location.pathname)
    }

    // async componentDidMount() {
    //     debugger;
    //     window.scrollTo({ top: 0 })

    //     let returnedData = await GetProfileInformation();

    //     let returnedReviewList = await GetReviewInfo();

    //     if (returnedReviewList !== undefined && returnedReviewList !== "undefined" && returnedReviewList !== []) {
    //         if (returnedReviewList.success === true || returnedReviewList.success === 'true') {
    //             var reviewCount = returnedReviewList.reviewModelList.length;
    //             this.setState({ ReviewsList: returnedReviewList.reviewModelList, AverageRating: returnedReviewList.averageRating, ReviewsCount: reviewCount })
    //         }
    //     }
    //     else {
    //         Modal.error({ content: 'Something went wrong' })
    //     }

    //     if (returnedData !== undefined && returnedData !== "undefined") {
    //         if (returnedData.success === true || returnedData.success === "true") {
    //             if (returnedData.credentials !== "" ) {
    //                 var jsonCREDENTIALS = JSON.parse(returnedData.credentials)
    //             }
    //             else
    //             {
    //                 jsonCREDENTIALS = false;
    //             }
    //             this.setState({
    //                 Firstname: returnedData.firstName, Lastname: returnedData.lastName, BackgroundIntroduction: returnedData.backgroundIntroduction,
    //                 BackgroundDetails: returnedData.backgroundDetails, Video1: returnedData.video1, Video2: returnedData.video2, Video3: returnedData.video3,
    //                 Video4: returnedData.video4, Rating: parseInt(returnedData.rating), Speciality: returnedData.speciality, CREDENTIALS: jsonCREDENTIALS.Education,
    //                 ExpertEmail: returnedData.email, Video1Content: returnedData.video1Content, Video2Content: returnedData.video2Content, Video3Content: returnedData.video3Content,
    //                 Video4Content: returnedData.video4Content, 
    //             })
    //             sessionStorage.setItem('ExpertEmail', returnedData.email)
    //         }
    //     }
    //     else {
    //         Modal.error({ content: 'Something went wrong' })
    //     }

    //     var vid = document.getElementsByTagName("video")[0];
    //     vid.loop = true;
    // }

    async componentDidMount() {
        debugger;
        this.setState({ Spinner: true })
        const script = document.createElement("script");

        script.src = FilesURL + "/Peachylife_/static/js/plmarriagetest3-944b06bbb-864f41afbdea5.js";
        script.async = true;
        document.body.appendChild(script);
        window.scrollTo({ top: 0 })


        //Getting User Id for the particular Username from URL
        if (this.props.match.params.handle !== undefined) {
            const token = this.props.match.params.handle;

            var returnedId = await GetUserProfileId(token);
            if ((returnedId.success === true || returnedId.success === "true") && returnedId.userId !== 0) {
                var ExpertId = parseInt(returnedId.userId)
                sessionStorage.setItem("ExpertId", ExpertId)
            }
            else {
                this.props.history.push('/')
                message.error("No profile found!")
            }
        }
        else if (this.props.location.pathname !== undefined || this.props.location.pathname !== "") {
            var url = this.props.location.pathname;
            var len = url.length;
            let username = url.substring(9, len);
            let returnedId = await GetUserProfileId(username);
            if ((returnedId.success === true || returnedId.success === "true") && returnedId.userId !== 0) {
                var ExpertId = parseInt(returnedId.userId)
                sessionStorage.setItem("ExpertId", ExpertId)
            }
            else {
                this.props.history.push('/')
                message.error("No profile found!")
            }
        }
        //End of Function

        let returnedData;
        let returnedReviewList;
        let questionsList, articlesList, videoList, metaTags;

        if (sessionStorage.getItem("ExpertId") === "" || sessionStorage.getItem("ExpertId") === "null" || sessionStorage.getItem("ExpertId") === undefined) {
            returnedData = await GetProfileInformation(sessionStorage.getItem("UserId"));
            returnedReviewList = await GetReviewInfo(sessionStorage.getItem("UserId"));
            questionsList = await GetAnswersByExpert(parseInt(sessionStorage.getItem("UserId")));
            articlesList = await GetExpertArticles(parseInt(sessionStorage.getItem("UserId")));
            videoList = await GetExpertVideos(parseInt(sessionStorage.getItem("UserId")));
            metaTags = await GetMetaTags(parseInt(sessionStorage.getItem("UserId")), returnedData.speciality);

            this.setState({
                QuestionsList: questionsList.question, ArticlesList: articlesList.articleDetailsList,
                VideoList: videoList.videoDetailsList, MetaTags: metaTags.metaTagModelList,
            })

            if (metaTags.metaTagModelList.length > 0) {
                this.setState({ MetaTitle: metaTags.metaTagModelList[0].title })
            }
        }
        else {
            returnedData = await GetProfileInformation(sessionStorage.getItem("ExpertId"));
            returnedReviewList = await GetReviewInfo(sessionStorage.getItem("ExpertId"));
            questionsList = await GetAnswersByExpert(parseInt(sessionStorage.getItem("ExpertId")));
            articlesList = await GetExpertArticles(parseInt(sessionStorage.getItem("ExpertId")));
            videoList = await GetExpertVideos(parseInt(sessionStorage.getItem("ExpertId")));
            metaTags = await GetMetaTags(parseInt(sessionStorage.getItem("ExpertId")), returnedData.speciality);

            this.setState({
                QuestionsList: questionsList.question, ArticlesList: articlesList.articleDetailsList,
                VideoList: videoList.videoDetailsList, MetaTags: metaTags.metaTagModelList
            })

            if (metaTags.metaTagModelList.length > 0) {
                this.setState({ MetaTitle: metaTags.metaTagModelList[0].title })
            }
        }

        if (returnedReviewList !== undefined && returnedReviewList !== "undefined") {
            if (returnedReviewList.success === true || returnedReviewList.success === 'true') {
                var reviewCount = returnedReviewList.reviewModelList.length;
                this.setState({ ReviewsList: returnedReviewList.reviewModelList, AverageRating: returnedReviewList.averageRating, ReviewsCount: reviewCount })
            }
        }
        else {
            Modal.error({ content: 'Something went wrong' })
        }

        if (returnedData !== undefined || returnedData !== "undefined") {
            if (returnedData.success === true || returnedData.success === "true") {
                if (returnedData.credentials !== "" && returnedData.credentials !== undefined) {
                    var jsonCREDENTIALS = JSON.parse(returnedData.credentials)
                }
                else {
                    var jsonCREDENTIALS = false
                }
                this.setState({
                    Firstname: returnedData.firstName, Lastname: returnedData.lastName, BackgroundIntroduction: returnedData.backgroundIntroduction,
                    BackgroundDetails: returnedData.backgroundDetails, Video1: returnedData.video1, Video2: returnedData.video2, Video3: returnedData.video3,
                    Video4: returnedData.video4, Rating: parseInt(returnedData.rating), Speciality: returnedData.speciality, CREDENTIALS: jsonCREDENTIALS.Education,
                    ExpertEmail: returnedData.email, Video1Content: returnedData.video1Content, Video2Content: returnedData.video2Content, Video3Content: returnedData.video3Content,
                    Video4Content: returnedData.video4Content, Subtitle1: returnedData.video1Subtitle, Subtitle2: returnedData.video2Subtitle,
                    Subtitle3: returnedData.video3Subtitle, Subtitle4: returnedData.video4Subtitle, Video1Image: returnedData.video1Image,
                    Video2Image: returnedData.video2Image, Video3Image: returnedData.video3Image, Video4Image: returnedData.video4Image,
                    StreetAddress: returnedData.streetAddress, City: returnedData.city, State: returnedData.state, ZipCode: returnedData.zipCode, PhoneNumber: returnedData.phoneNumber,
                    Transcript1: returnedData.transcript1, Transcript2: returnedData.transcript2, Transcript3: returnedData.transcript3, Transcript4: returnedData.transcript4,
                    SquarePortrait: returnedData.squarePortrait, LargerHorizontal: returnedData.largerHorizontal, Website: returnedData.webSite
                })
                sessionStorage.setItem("ExpertEmail", returnedData.email)
                sessionStorage.setItem("Speciality", returnedData.speciality)

                var address = returnedData.streetAddress + ',' + returnedData.city + ',' + returnedData.state + ',' + returnedData.zipCode
                this.setState({ CompleteAddress: address })
            }
        }
        else {
            Modal.error({ content: 'Something went wrong' })
        }
        if (sessionStorage.getItem('UserId') !== '' && sessionStorage.getItem('UserId') !== sessionStorage.getItem('ExpertId')) {
            this.setState({ showContactBtn: true, ContactFirstName: sessionStorage.getItem('Name'), ContactEmail: sessionStorage.getItem('UserName') })
        }

        var vid = document.getElementsByTagName("video")[0];
        //vid.loop = true;
        if (returnedData.success) {
            this.setState({ Spinner: false, Introclicked: true })
        }
    }

    // Video Players
    setMuted(muted) { // Func to set the video muted
        return () => {
            this.player.muted = muted;
        };
    }
    //End of Video Player settings and controls

    //Scrolling between elements!!

    scrollDown = (a) => {
        var x;
        if (a === 'Intro') {
            x = this.IntroDiv;
            this.behavior(x);
        }
        else if (a === 'About') {
            x = this.AboutDiv;
            this.behavior(x);
        }
        else if (a === 'QxA') {
            x = this.QxADiv;
            this.behavior(x);
        }
        else if (a === 'Videos') {
            x = this.VideosDiv;
            this.behavior(x);
        }
        else if (a === 'Articles') {
            x = this.ArticlesDiv;
            this.behavior(x);
        }
        else if (a === 'Contact') {
            x = this.ContactDiv;
            this.behavior(x);
        }
        else if (a === 'Reviews') {
            x = this.ReviewsDiv;
            this.behavior(x);
        }
        else {
            x = this.IntroDiv;
            this.behavior(x);
        }
    }

    behavior = (x) => {
        x.current.scrollIntoView({
            behavior: "smooth",
            block: "nearest"
        })
    }

    //End of functions to scroll

    muteVideo = (videoURL) => {
        if (videoURL.includes('embed')) {

        }
        else {

            var vid = document.getElementsByTagName("video")[0];
            if (vid.muted) {
                vid.muted = false;
            }
            else {
                vid.muted = true;
            }
        }
    }

    videoload = (e, x, video) => {
        //var Caption1, Caption2, Caption3, Caption4
        // Caption1 = document.getElementById("VideoTrack1");
        // Caption2 = document.getElementById("VideoTrack2");
        // Caption3 = document.getElementById("VideoTrack3");
        // Caption4 = document.getElementById("VideoTrack4");

        if (e !== "") {
            var vid = document.getElementsByTagName("video")[0];

            //vid.textTracks.mode = 'showing';
            //vid.addTextTrack('subtitles', 'English', 'en')

            if (vid !== undefined) {
                //vid.setAttribute('src', process.env.PUBLIC_URL + `${e}`)
                vid.setAttribute('src', e)
                vid.muted = true;
                vid.load();
                // vid.play();
            }
        }

        // if (x !== '') {
        //     var caption = document.getElementById("VideoTrack1");
        //     caption.setAttribute('src', x)
        //     caption.setAttribute('async', false)
        //     vid.load();
        //     vid.play();
        // }
    }

    // videoload = (e, x) => {
    //     debugger;
    //     if (e !== "") {
    //         var vid = document.getElementsByTagName("video")[0];

    //         vid.textTracks.mode = 'showing';
    //         vid.addTextTrack('subtitles', 'English', 'en')

    //         if (vid !== undefined) {
    //             //vid.setAttribute('src', process.env.PUBLIC_URL + `${e}`)
    //             vid.setAttribute('src', e)
    //             vid.muted = false;
    //             vid.load();
    //             vid.play();
    //         }
    //     }

    //     if (x !== '') {
    //         var caption = document.getElementById("VideoTrack");
    //         caption.setAttribute('src', x)
    //     }
    // }

    redirectToQuestion = () => {
        this.props.history.push('/Questions')
    }

    openModal = () => {
        this.setState({ isModalVisible: true })
    }

    handleReviewSubmit = async () => {
        if (sessionStorage.getItem('UserId') !== '' && sessionStorage.getItem('UserId') !== null && sessionStorage.getItem('UserId') !== undefined) {
            if (this.state.Reviews !== "" || this.state.Rating !== 0) {
                var returnedData = await SaveReview(this.state.Reviews, this.state.Rating)
                if (returnedData.success === true) {
                    message.success("Review saved Successfully")
                }
                else
                    message.error("Review Cannot be Saved")
            }
            else {
                message.warning("Please type your review and give a rating to submit")
            }
        }
        else
            message.warning("Please login to continue")
    }

    handleCancel = () => {
        this.setState({ isModalVisible: false })
    };

    handleRating = e => {
        debugger;
        this.setState({ Rating: e })
    }

    handleCancel = () => {
        this.setState({ isModalVisible: false })
    };

    onCancel = () => {
        this.setState({
            showModal: false
        })
    }

    handleContactForm = () => {
        this.setState({
            showModal: true,
        })
    }

    sendContactDetails = async () => {
        debugger;
        var ContactName = this.state.ContactFirstName + ' ' + this.state.ContactLastName;

        if (this.state.ContactFirstName === '' && this.state.ContactLastName === '') {
            Modal.warning({ content: "Name is Mandatory" })
        }
        else if (this.state.ContactEmail === '') {
            Modal.warning({ content: "Email is Mandatory" })
        }
        else if (this.state.ContactPhoneNumber === '') {
            Modal.warning({ content: "PhoneNumber is Mandatory" })
        }
        else if (this.state.ContactZipCode === '') {
            Modal.warning({ content: "ZipCode is Mandatory" })
        }
        else if (sessionStorage.getItem('ExpertId') === '' || sessionStorage.getItem('ExpertId') === null) {
            Modal.warning({ content: "Choose a professional to contact" })
            this.setState({ showModal: false })
        }
        else {
            var IsSuccess = await SaveContactDetails(ContactName, this.state.ContactEmail, this.state.ContactNote,
                this.state.ContactPhoneNumber, this.state.ContactZipCode, this.state.ContactVirtualConsult);
        }
        //Returned Data Validation
        if (IsSuccess === true) {
            Modal.success({ content: "Contact Details Sent Successfully" })
            this.setState({ ContactFirstName: '', ContactLastName: '', ContactEmail: '', ContactNote: '', showModal: false })
        }
        else {
            Modal.error({ content: "Error sending Details!" })
        }
    }

    VideoConference = () => {
        message.info("This feature is coming soon!")
    }

    gtag_report_conversion(url) {
        debugger;
        var callback = function () {
            if (typeof (url) != 'undefined') {
                window.location = url;
                // window.location = url;
            }
        };
        window.gtag('event', 'conversion', { 'send_to': 'AW-10806936548/AfZeCJKY9YQDEOSHk6Eo', 'event_callback': callback });
        
        return false;
    }

    render() {
        const { TextArea } = Input;

        const { Panel } = Collapse;

        const customIcons = {
            1: <FrownOutlined />,
            2: <FrownOutlined />,
            3: <MehOutlined />,
            4: <SmileOutlined />,
            5: <SmileOutlined />,
        };

        const antIcon = <SettingOutlined style={{ fontSize: 45 }} spin />

        /* Menu Scroll */
        window.onscroll = function () { myFunction(); }

        function myFunction() {
            var header = document.getElementById("myHeader");
            var sticky = header.getBoundingClientRect().top;
            // console.log("Sticky : " + sticky, "PageYOffset : " + window.pageYOffset)
            // var sticky = header.offsetTop;
            if (window.pageYOffset > 860) {
                header.classList.add("sticky");
            }
            else {
                header.classList.remove("sticky");
            }
        }

        const Introclick = (e) => {
            this.setState({
                Introclicked: true, q1Clicked: false, q2Clicked: false, q3Clicked: false
            })
        }

        const Q1click = (e) => {
            this.setState({
                q1Clicked: true, Introclicked: false, q2Clicked: false, q3Clicked: false
            })
        }

        const Q2click = (e) => {
            this.setState({
                q2Clicked: true, Introclicked: false, q3Clicked: false, q1Clicked: false
            })
        }

        const Q3click = (e) => {
            this.setState({
                q3Clicked: true, Introclicked: false, q1Clicked: false, q2Clicked: false
            })
        }

        let className1 = 'navoption';
        let className2 = 'navoption';
        let className3 = 'navoption';
        let className4 = 'navoption';
        var playhead1 = 'playhead';
        let playhead2 = 'playhead';
        let playhead3 = 'playhead';
        let playhead4 = 'playhead';
        let videoUrl = '';
        let SubtitleUrl = '';
        if (this.state.Introclicked) {
            className1 += ' a';
            playhead1 += ' a';
            //videoUrl = '/videos/sample-mp4-file.MP4';
            videoUrl = this.state.Video1;
            SubtitleUrl = this.state.Subtitle1;
            this.videoload(videoUrl, SubtitleUrl);
        }
        if (this.state.q1Clicked) {
            className2 += ' a';
            playhead2 += ' a';
            //videoUrl = '/videos/jellyfish-25-mbps-hd-hevc.m4v';
            videoUrl = this.state.Video2;
            SubtitleUrl = this.state.Subtitle2;
            //this.videoload(videoUrl, SubtitleUrl);
        }
        if (this.state.q2Clicked) {
            className3 += ' a';
            playhead3 += ' a';
            //videoUrl = '/videos/intro.webm';
            videoUrl = this.state.Video3
            SubtitleUrl = this.state.Subtitle3;
            //this.videoload(videoUrl, SubtitleUrl);
        }
        if (this.state.q3Clicked) {
            className4 += ' a';
            playhead4 += ' a';
            //videoUrl = '/videos/loop.mp4';
            videoUrl = this.state.Video4
            SubtitleUrl = this.state.Subtitle4;
            //this.videoload(videoUrl, SubtitleUrl);
        }

        const pauseVideo = (e) => {
            var vid = document.getElementsByTagName("video")[0];
            if (vid.paused) {
                vid.play();
                if (e === "intro") { playhead1 += ' a' }
                if (e === "q1") { playhead2 += ' a' }
                if (e === "q2") { playhead3 += ' a' }
                if (e === "q3") { playhead4 += ' a' }
            }
            else {
                vid.pause();
                if (e === "intro") { playhead1 = 'playhead' }
                if (e === "q1") { playhead2 = 'playhead' }
                if (e === "q2") { playhead3 = 'playhead' }
                if (e === "q3") { playhead4 = 'playhead' }
            }
        }

        const Video1Json = {
            "@context": "https://schema.org",
            "@type": "VideoObject",
            "name": this.state.Video1Content,
            "thumbnailUrl": this.state.Video1Image,
            "Width": "640px",
            "Height": "480px",
            "contentUrl": this.state.Video1,
            "Ispartof": this.state.Firstname + " " + this.state.Lastname + " " + "Mywye",
            "Publisher": "Mywye",
            "Genre": "instructional",
            "description": this.state.BackgroundIntroduction,
            "transcript": this.state.Transcript1,
            "interactionStatistic": {
                "@type": "InteractionCounter",
                "interactionType": { "@type": "http://schema.org/WatchAction" },
                "userInteractionCount": 5647018
            },
        }

        const Video2Json = {
            "@context": "https://schema.org",
            "@type": "VideoObject",
            "name": this.state.Video2Content,
            "thumbnailUrl": this.state.Video2Image,
            "Width": "640px",
            "Height": "480px",
            "contentUrl": this.state.Video2,
            "description": this.state.BackgroundIntroduction,
            "transcript": this.state.Transcript2,
            "interactionStatistic": {
                "@type": "InteractionCounter",
                "interactionType": { "@type": "http://schema.org/WatchAction" },
                "userInteractionCount": 5647018
            },
        }

        const Video3Json = {
            "@context": "https://schema.org",
            "@type": "VideoObject",
            "name": this.state.Video3Content,
            "thumbnailUrl": this.state.Video3Image,
            "Width": "640px",
            "Height": "480px",
            "contentUrl": this.state.Video3,
            "description": this.state.BackgroundIntroduction,
            "transcript": this.state.Transcript3,
            "interactionStatistic": {
                "@type": "InteractionCounter",
                "interactionType": { "@type": "http://schema.org/WatchAction" },
                "userInteractionCount": 5647018
            },
        }

        const Video4Json = {
            "@context": "https://schema.org",
            "@type": "VideoObject",
            "name": this.state.Video3Content,
            "thumbnailUrl": this.state.Video3Image,
            "Width": "640px",
            "Height": "480px",
            "contentUrl": this.state.Video3,
            "description": this.state.BackgroundIntroduction,
            "transcript": this.state.Transcript4,
            "interactionStatistic": {
                "@type": "InteractionCounter",
                "interactionType": { "@type": "http://schema.org/WatchAction" },
                "userInteractionCount": 5647018
            },
        }

        const sampleYt = "https://www.youtube.com/embed/IwUv6Omfi4o";

        return (
            <div>
                <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(Video1Json) }} />
                <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(Video2Json) }} />
                <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(Video3Json) }} />
                <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(Video4Json) }} />

                <MetaTags>
                    <title>Profile - {this.state.Firstname + " " + this.state.Lastname}</title>
                    <meta property="og:url" content="http://www.mywye.com/#/Profile" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content={this.state.MetaTitle} />
                </MetaTags>

                <Spin indicator={antIcon} tip="Loading..." style={{ position: "fixed", color: "rgb(255, 103, 120)" }} spinning={this.state.Spinner}>
                    <Header></Header>
                    <div class="videoheader">
                        <div class="videocenterblock">
                            <div class="profileheroportrait">
                                <div id="videoplayer" class="profileheroportrait">

                                    {this.state.Introclicked ?
                                        this.state.Video1.includes('mywye') ?
                                            <Player id="VideoPlayer" ref={(player) => { this.player = player }} disableDefaultControls videoId="video-1" muted loop>
                                                <source
                                                    src={this.state.Video1}
                                                    type="video/mp4"
                                                />
                                                <BigPlayButton position="center" />
                                            </Player> :

                                            <iframe class="profileheroportrait" src={this.state.Video1} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>

                                            </iframe>
                                        : null
                                    }

                                    {this.state.q1Clicked ?
                                        this.state.Video2.includes('mywye') ?
                                            <Player disableDefaultControls id="VideoPlayer" videoId="video-1" loop>
                                                <source
                                                    src={this.state.Video2}
                                                    type="video/mp4"
                                                />
                                                <BigPlayButton position="center" />
                                                {/* <track id="VideoTrack1"
                                                    kind="captions"
                                                    src={this.state.Subtitle2}
                                                    srcLang="en"
                                                    label="English"
                                                    default
                                                /> */}
                                                {/* <ControlBar autoHide={false}>
                                    <ClosedCaptionButton order={7} />
                                </ControlBar> */}
                                            </Player> :


                                            <iframe class="profileheroportrait" src={this.state.Video2} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>

                                            </iframe>
                                        : null}

                                    {this.state.q2Clicked ?
                                        this.state.Video3.includes('mywye') ?
                                            <Player id="VideoPlayer" disableDefaultControls videoId="video-1" loop>
                                                <source
                                                    src={this.state.Video3}
                                                    type="video/mp4"
                                                />
                                                <BigPlayButton position="center" />
                                            </Player> :

                                            <iframe class="profileheroportrait" src={this.state.Video3} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>

                                            </iframe>
                                        : null}

                                    {this.state.q3Clicked ?
                                        this.state.Video4.includes('mywye') ?
                                            <Player id="VideoPlayer" disableDefaultControls videoId="video-1" loop>
                                                <source
                                                    src={this.state.Video4}
                                                    type="video/mp4"
                                                />
                                                <BigPlayButton position="center" />
                                            </Player> :

                                            <iframe class="profileheroportrait" src={this.state.Video4} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>

                                            </iframe>
                                        : null
                                    }

                                    {/* <video class="profileheroportrait" preload="metadata">
                                    <source src={`${videoUrl}`} type="video/mp4"></source>
                                    <track id="VideoTrack" kind="subtitles" srclang="en" label="English" default />
                                </video> */}

                                </div>
                                <div class="playpause" onClick={() => this.muteVideo(videoUrl)}></div>
                            </div>
                            <div class="profileinfoblock">
                                <div class="professional-profile">PROFESSIONAL PROFILE</div>
                                <div class="peachybrand-inset"><img src="images/MyWye_Final_Logos_Color-07.png" loading="lazy" alt="PeachyLife Brand Icon White" class="image-15" /></div>
                                <h1 class="professionalname">{`${this.state.Firstname}`} &nbsp; {`${this.state.Lastname}`}</h1>
                                <div class="category-name">{`${this.state.Speciality}`}</div>
                                <div class="navigation-block">
                                    <div class={className1}>
                                        <div class={playhead1} onClick={() => pauseVideo("intro")}></div>
                                        <div class="text-block-70" onClick={() => Introclick()}>{this.state.Video1Content}</div>
                                    </div>
                                    <div class={className2}>
                                        <div class={playhead2} onClick={() => pauseVideo("q1")}></div>
                                        <div class="text-block-70" onClick={() => Q1click()}>{this.state.Video2Content}</div>
                                    </div>
                                    <div class={className3}>
                                        <div class={playhead3} onClick={() => pauseVideo("q2")}></div>
                                        <div class="text-block-70" onClick={() => Q2click()}>{this.state.Video3Content}</div>
                                    </div>
                                    <div class={className4}>
                                        <div class={playhead4} onClick={() => pauseVideo("q3")}></div>
                                        <div class="text-block-70" onClick={() => Q3click()}>{this.state.Video4Content}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="TopImageCouple" youngmarriedcouple="Young Married Couple" class="page-section">

                        <Row className="profilebody introContent" ref={this.IntroDiv}>
                            {/* <div>
                            <Image src={this.state.SquarePortrait} />
                        </div> */}
                            <div class="profilebody">
                                {/* <Col xs={{ span: 24 }} lg={{ span: 12 }}> */}
                                <p class="profileintro">
                                    <img className="LeftProfilePic" src={this.state.SquarePortrait} />
                                    <span className="RightDesc">
                                        {`${this.state.BackgroundIntroduction}`}
                                    </span>
                                </p>
                                {/* </Col> */}
                            </div>
                            {/* <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <Image className="LeftProfilePic" src={this.state.SquarePortrait} />
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <p class="profileintro">{`${this.state.BackgroundIntroduction}`}</p>
                            </Col> */}
                        </Row>

                        <div className="profilebody">
                            <nav class="dropdown-list w-dropdown-list w--open myList" id="myHeader" aria-labelledby="w-dropdown-toggle-4" >
                                <div class="searchcriteriabuttons">
                                    <div class="searchbuttonoutlne-copy" style={{ cursor: "pointer" }} onClick={() => this.scrollDown('Intro')}>
                                        <div class="buttontextpink-2">Intro</div>
                                    </div>
                                    <div class="searchbuttonoutlne-copy" style={{ cursor: "pointer" }} onClick={() => this.scrollDown('About')}>
                                        <div class="buttontextpink-2">About</div>
                                    </div>
                                    <div class="searchbuttonoutlne-copy" style={{ cursor: "pointer" }} onClick={() => this.scrollDown('QxA')}>
                                        <div class="buttontextpink-2">QxA</div>
                                    </div>
                                    <div class="searchbuttonoutlne-copy" style={{ cursor: "pointer" }} onClick={() => this.scrollDown('Articles')}>
                                        <div class="buttontextpink-2">Articles</div>
                                    </div>
                                    <div class="searchbuttonoutlne-copy" style={{ cursor: "pointer" }} onClick={() => this.scrollDown('Videos')}>
                                        <div class="buttontextpink-2">Videos</div>
                                    </div>
                                    <div class="searchbuttonoutlne-copy" style={{ cursor: "pointer" }} onClick={() => this.scrollDown('Reviews')}>
                                        <div class="buttontextpink-2">Reviews</div>
                                    </div>
                                    <div class="searchbuttonoutlne-copy" style={{ cursor: "pointer" }} onClick={() => this.scrollDown('Contact')}>
                                        <div class="buttontextpink-2">Contact</div>
                                    </div>
                                </div>
                            </nav>
                        </div>

                        <div class="profilebody" ref={this.AboutDiv}>
                            <p class="profile-bodycopy">
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>                            {/* <span class="profileheadline">Family Law History</span><br /> */}
                                    {/* Lisa.R Murray received her license to practice law in California in 1987 and in Hawaii in 2001. Ms. Murray has practiced family law since 1989, assisting families through the difficult divorce process. Ms. Murray serves her community as a mediator, Collaborative Practice divorce lawyer, settlement counsel, court-appointed child advocate representing children in divorce cases, and Judge Pro Tempore. <br /><br />Ms. Murray also volunteers with various community-based organizations participating in programs which provide low cost legal assistance to individuals. Ms. Murray&#x27;s focus on Divorce Mediation, Collaborative Practice Divorce, and settlement negotiation counsel was borne from her experience of the destructive manner in which litigation impacts the lives of parents and children. Ms. Murray has been a member of the International Academy of Collaborative Professionals since 2004.Ms. Murray has been named a Northern California Super Lawyer from 2006 through the present. <br /><br />In 2009 she was honored with a Eureka Award by Collaborative Practice California for her significant contributions and demonstration of abiding dedication to establishing and sustaining Collaborative Practice in California.Ms. Murray received her Juris Doctor from the University of San Francisco in 1987, after graduating cum laude from Boston University with a Bachelor of Arts Degree.<br />‍<br /><br /> */}
                                    <Col span={12}>
                                        {/* CATEGORY HISTORY SECTION */}
                                        <span class="profileheadline">{this.state.Speciality} History</span><br /><br />{this.state.BackgroundIntroduction}<br /><br />

                                        {/* BACKGROUND HISTORY SECTION */}
                                        {/* <span class="headline">Background</span><br /><br />
                                        {this.state.BackgroundDetails}<br /><br />

                                        {this.state.PROFESSIONAL && this.state.PROFESSIONAL.map((Education) => {
                                            return <div>
                                                <strong>{Education.Company}</strong> &nbsp; {Education.StartDate} - {Education.EndDate}
                                                <br /> {Education.Role}
                                            </div>
                                        })}<br /> */}
                                    </Col>

                                    <Col span={12}>
                                        <div>
                                            {/* AREA OF SPECIALITY SECTION */}
                                            <span class="headline">Areas of Speciality</span><br /><br />
                                            {this.state.Speciality === "Family Law" && <span class="text-span-25">
                                                •  Family Law<br />
                                                •  Divorce<br />
                                                •  Mediation<br />
                                                •  Collaborative Divorce<br />
                                                •  Negotiated Settlement<br />
                                                •  Consensual Dispute Resolution
                                            </span>
                                            }
                                            {this.state.Speciality === "Therapist / Counselors" && <span class="text-span-25">
                                                1. Marriage Counseling<br /> 2. Family Therapy<br /> 3. Child Counseling<br /> 4. Counseling
                                            </span>
                                            }
                                            {this.state.Speciality === "Financial Experts" && <span class="text-span-25">
                                                1. Investment Strategy<br /> 2. Retirement Funds<br /> 3. Asset Protection
                                            </span>
                                            }
                                            {this.state.Speciality === "Accountants / Tax Planners" && <span class="text-span-25">
                                                1. Tax Planning<br /> 2. Accounting<br /> 3. Financial Audits
                                            </span>
                                            }
                                            {this.state.Speciality === "Real Estate Agents" && <span class="text-span-25">
                                                1. Real Estate Estimates<br /> 2. Property Sales<br /> 3. Real State Consultation
                                            </span>
                                            }
                                            <br /><br />
                                        </div>

                                        {/* EDUCATION DETAILS SECTION */}
                                        <span class="headline">Education</span>
                                        <br />
                                        <span class="text-span-25"><br />
                                            {this.state.CREDENTIALS && this.state.CREDENTIALS.map((Education) => {
                                                return <div>
                                                    <strong>{Education.School}</strong> &nbsp; {Education.StartDate} - {Education.EndDate}
                                                    <br /> {Education.Degree}
                                                </div>
                                            })}
                                        </span><br /><br />
                                    </Col>

                                    {/* BACKGROUND HISTORY SECTION */}
                                    <Col span={24}>
                                        <span class="headline">Background</span><br /><br />
                                        {this.state.BackgroundDetails}<br /><br />

                                        {this.state.PROFESSIONAL && this.state.PROFESSIONAL.map((Education) => {
                                            return <div>
                                                <strong>{Education.Company}</strong> &nbsp; {Education.StartDate} - {Education.EndDate}
                                                <br /> {Education.Role}
                                            </div>
                                        })}<br />
                                    </Col>
                                </Row>
                            </p>
                        </div>

                        {/* This is Q & A Section */}
                        <div class="profilebody" ref={this.QxADiv}>
                            <span class="headline">Q & A</span>
                            <Collapse accordion bordered={true} className="site-collapse-custom-collapse qandaCollapse">
                                {this.state.QuestionsList.length !== 0 ? this.state.QuestionsList.map((data, index) => {
                                    return <Panel header={data.question} key={index}>
                                        <p>{data.answer}</p>
                                    </Panel>
                                }) : <span className="descline">Q & A coming soon</span>}
                            </Collapse>
                        </div>
                        {/* End of Q&A Section */}

                        {/* This is Articles Section */}
                        <div class="profilebody" ref={this.ArticlesDiv}>
                            <span class="headline">Articles</span>
                            <div className="navigation-drop-container-profilePage articlesBlock">
                                <Row>
                                    {this.state.ArticlesList.length !== 0 ? this.state.ArticlesList.map(articles =>
                                        // <div className="navigation-column sw">
                                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                            <div class="inset-block sc">
                                                <a className="dropdown-link-mobile w-inline-block" target="blank" href={articles.hostedPath}>
                                                    <div class="icon-square"><img src={articles.posterImage ? articles.posterImage : "/images/webclip.png"} alt="" class="imagepro _1" /></div>
                                                    <div class="nav-content-wrap">
                                                        <div class="menu-title tp">{articles.title}</div>
                                                        <div class="nav-link-details">{articles.description}</div>
                                                    </div>
                                                </a>
                                            </div>
                                        </Col>
                                        //</div>
                                    ) : <span className="descline">Articles Coming Soon</span>}
                                </Row>
                            </div>
                        </div>
                        {/* End of Articles Section */}

                        {/* This is Videos Section */}
                        {/* <div class="profilebody" ref={this.VideosDiv}>
                            <span class="headline">Videos</span>
                            <div class="w-layout-grid articlesfinance videosSectionP">
                                {this.state.VideoList.length !== 0 ? this.state.VideoList.map((videos) => {
                                    return <div style={{ display: 'contents' }}>
                                        {videos.hostedPath !== "" && videos.hostedPath.includes('embed') ?
                                            <div id="w-node-85187a6be990-89cdb2e6" data-w-id="e2965df9-4057-bd5a-ee8b-85187a6be990" class="articlepromo">
                                                <iframe width="300" height="417" src={videos.hostedPath} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>

                                                </iframe> </div> : <div id="w-node-85187a6be990-89cdb2e6" data-w-id="e2965df9-4057-bd5a-ee8b-85187a6be990" class="articlepromo">
                                                <div class="articlestoryimage">
                                                    <Player id="VideoPlayer" videoId="video-1" muted>
                                                        <source
                                                            src={videos.hostedPath}
                                                            type="video/mp4"
                                                        />
                                                    </Player>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                }) : <span className="descline">Oops, No Videos Found!</span>}
                            </div>
                        </div> */}
                        {/* End of Videos Section */}

                        {/* This is Videos Section */}
                        <div class="profilebody" ref={this.VideosDiv}>
                            <span class="headline">Videos</span>
                            <div class="w-layout-grid articlesfinance videosSectionP">
                                {this.state.VideoList.length !== 0 ? this.state.VideoList.map((videos) => {
                                    return <div style={{ display: 'contents' }}>
                                        {videos.hostedPath !== "" && videos.hostedPath.includes('mywye') ?
                                            <div id="w-node-85187a6be990-89cdb2e6" data-w-id="e2965df9-4057-bd5a-ee8b-85187a6be990" class="articlepromo">
                                                <div class="articlestoryimage">
                                                    <Player id="VideoPlayer" videoId="video-1" muted>
                                                        <source
                                                            src={videos.hostedPath}
                                                            type="video/mp4"
                                                        />
                                                    </Player>
                                                </div>
                                            </div> : <div id="w-node-85187a6be990-89cdb2e6" data-w-id="e2965df9-4057-bd5a-ee8b-85187a6be990" class="articlepromo">
                                                <iframe width="300" height="417" src={videos.hostedPath} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>

                                                </iframe> </div>
                                        }
                                    </div>
                                }) : <span className="descline">Videos Coming Soon</span>}
                            </div>
                        </div>

                        {/* End of Videos Section */}

                        {/* This is Reviews Section */}
                        <div class="profilebody" ref={this.ReviewsDiv}>
                            <span class="headline">Reviews</span>
                            <div className="reviewsBody">
                                <div className="reviewsAverage">
                                    <div className="reviewsAverageRating">{this.state.AverageRating} <Rate disabled value={this.state.AverageRating}></Rate></div>
                                    <div className="reviewsReviewsCount">{this.state.ReviewsCount} Reviews & Ratings</div>
                                </div>
                                <div class="w-tab-content">
                                    <div data-w-tab="Tab 1" class="tab-pane-tab-1-2 w-tab-pane w--tab-active ReviewsDiv">
                                        <div class="reviews-block-holder" >
                                            {this.state.ReviewsList !== "" && this.state.ReviewsList.map((reviews) => {
                                                return <div class="reviewsumaryblock w-clearfix">
                                                    <div class="contacticon-2" style={{ backgroundImage: `url(${this.state.backgroundImage})` }}></div>
                                                    <div class="div-block-69">
                                                        <div class="reviewsummarytext">{reviews.review}<span class="text-span-31">‍</span></div>
                                                        <div class="star-rating"><Rate disabled defaultValue={reviews.rating}></Rate></div>
                                                    </div>
                                                </div>
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End of Q&A Section */}

                        {/* This is CONTACT SECTION */}
                        <div className="profilebody ContactSection" ref={this.ContactDiv}>
                            <span class="headline contactsHeadline">Contacts</span>
                            <div class="profileheader">
                                {/* <div className="contactSectionBody"> */}
                                <div class="profilerightside">
                                    <div class="map">
                                        <div class="html-embed w-embed w-iframe"> {/*AIzaSyBvuLLySSJgTa3gnPWF5Au2ZXaYTW7plJQ (OLD) Besant+Nagar,Chennai+India AIzaSyCwqVEVTE_lPnlkzIV7jvZ0VtvdW0F5ouU*/}
                                            <iframe
                                                width="100%"
                                                height="300"
                                                style={{ border: 0 }}
                                                loading="lazy"
                                                src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyCwqVEVTE_lPnlkzIV7jvZ0VtvdW0F5ouU&q=${this.state.CompleteAddress}`}>
                                            </iframe>
                                        </div>
                                    </div>
                                    {/* <div class="matchingblock">
                                    <div class="div-block-44"></div>
                                    <div class="matchingcopy">
                                        <div class="text-block-71">This guide shows how this professional ranks their own values and approach.<br /><br />It is intended to help in matching to compatibility. <br /><br /><strong>more</strong></div>
                                    </div>
                                </div> */}
                                </div>
                                <div class="leftside">
                                    <div class="contacts">
                                        <div class="text-block-77">CONTACTS</div>
                                        {/* <div class="div-block-47">
                                            <div class="contacticon"></div>
                                            <div class="contacticon _3"></div>
                                            <div class="contacticon _1"></div>
                                            <div class="div-block-48"></div>
                                        </div> */}
                                        <div className="menu-title tp">
                                            We have a team of professionals that can assist in all of your needs.
                                        </div>
                                    </div>

                                    <div class="profilebutton" style={{ cursor: "pointer" }} onClick={this.handleContactForm}>
                                        <div class="buttontextwhite">CONTACT</div>
                                    </div>

                                    <div id="ReferalModal">
                                        {this.state.showModal &&
                                            <Modal onCancel={this.onCancel} visible={this.state.showModal} closable={false} footer={null}>
                                                <div class="formpopup">
                                                    <div class="closexbutton" onClick={this.onCancel}></div>
                                                    <div class="formblock">
                                                        <div class="menutoptitle-2">Contact Form</div>
                                                        <div class="subheaddescript a">Use this form to contact My Wye</div>
                                                        <div class="logintabfield">
                                                            <Input className="clientreferralname" placeholder="First Name" onBlur={(e) => { this.setState({ ContactFirstName: e.target.value }) }} />
                                                        </div>
                                                        <div class="logintabfield">
                                                            <Input autoFocus={true} className="clientreferralname" placeholder="Last Name" onBlur={(e) => { this.setState({ ContactLastName: e.target.value }) }} />
                                                        </div>
                                                        <div class="logintabfield">
                                                            <Input className="clientreferralname" placeholder="e mail" onBlur={(e) => { this.setState({ ContactEmail: e.target.value }) }} />
                                                        </div>
                                                        <div class="logintabfield">
                                                            <Input className="clientreferralname" placeholder="Phone Number" onBlur={(e) => { this.setState({ ContactPhoneNumber: e.target.value }) }} />
                                                        </div>
                                                        <div class="logintabfield">
                                                            <Input className="clientreferralname" placeholder="Zip Code" onBlur={(e) => { this.setState({ ContactZipCode: e.target.value }) }} />
                                                        </div>
                                                        <div class="logintabfield">
                                                            <Checkbox value={this.state.ContactVirtualConsult} className="profileVCcheckBox" onChange={(e) => this.setState({ ContactVirtualConsult: e.target.checked })}>
                                                                Open to a Virtual consultation?
                                                            </Checkbox>
                                                        </div>
                                                        <div class="form-fieldnote-copy"> {/* Note: (please supply some context) */}
                                                            <TextArea className="clientreferralname" style={{ resize: 'none', height: 100, width: 375 }}
                                                                placeholder="please let us know how we can help you, a good time to call you or if you prefer email."
                                                                onBlur={(e) => { this.setState({ ContactNote: e.target.value }) }}
                                                            />
                                                        </div>
                                                        <div class="sendbuttonblock">
                                                            <a href="#" class="sendbutton2 w-inline-block">
                                                                <div class="sendbutton" style={{ marginLeft: '50px', marginTop: '4px', color: 'white' }} onClick={this.sendContactDetails}>SEND</div>
                                                            </a>
                                                            <div class="subheaddescript">We will send an email to all parties to confirm</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Modal>}
                                    </div>
                                    <div class="profilebuttonoutlne" style={{ cursor: "pointer" }} onClick={this.handleContactForm}>
                                        <div class="buttontextpink">VIDEO CONFERENCE</div> {/* onClick={this.VideoConference} */}
                                    </div>
                                    <div class="profilebuttonoutlne"> {/* onClick={() => this.gtag_report_conversion(this.state.Website)}  href={this.state.Website}  */}
                                        {/* <div class="buttontextpink" onClick={() => { window.open(this.state.Website, '_blank') }} style={{ cursor: "pointer" }}>ASK A QUESTION</div> */}
                                        <a class="buttontextpink" target="blank" href={this.state.Website} style={{ cursor: "pointer" }}>VISIT MY WEBSITE</a>
                                    </div>
                                    <div class="profilebuttonoutlne">
                                        <div class="buttontextpink" onClick={this.openModal} style={{ cursor: "pointer" }}>ADD A REVIEW</div>
                                    </div>
                                    <div class="profilebuttonoutlne">
                                        {/* <div class="buttontextpink" style={{ cursor: "pointer" }}>Call : {this.state.PhoneNumber}</div> */}
                                        <a class="buttontextpink" href={"tel:" + this.state.PhoneNumber} style={{ cursor: "pointer" }}>Call : {this.state.PhoneNumber}</a>
                                    </div>

                                    <Modal id="ModalReview" className="ReviewModal" style={{ height: '200px' }} title="Add a review & rating" visible={this.state.isModalVisible} onOk={this.handleReviewSubmit} onCancel={this.handleCancel}>
                                        <label>Review :</label><br />
                                        <TextArea bordered autoSize={{ minRows: 3, maxRows: 5 }} maxLength="200" onBlur={(e) => { this.setState({ Reviews: e.target.value }) }}></TextArea>
                                        <br /><br />
                                        <Divider></Divider>
                                        <label>Rating</label><br />
                                        <Rate className="ReviewRating" character={({ index }) => customIcons[index + 1]} onChange={this.handleRating}></Rate>
                                    </Modal>

                                    <div data-duration-in="300" data-duration-out="100" class="reviewsdropdown w-tabs">
                                        {/* <div class="ratings">
                                            <div class="text-block-74">{this.state.ReviewsCount} reviews<br /><Rate disabled value={this.state.AverageRating}></Rate><br /></div>
                                            <div class="reviewtrigger" style={{ cursor: "pointer" }} onClick={() => { this.setState({ ReadReviews: true }) }}>Read Reviews</div>
                                        </div> */}

                                        {this.state.ReadReviews &&
                                            <div class="w-tab-content">
                                                <div data-w-tab="Tab 1" class="tab-pane-tab-1-2 w-tab-pane w--tab-active" style={{ height: '100px', overflow: 'auto', width: '80%' }}>
                                                    <div class="reviews-block-holder" >
                                                        {this.state.ReviewsList !== "" && this.state.ReviewsList.map((reviews) => {
                                                            return <div class="reviewsumaryblock w-clearfix">
                                                                <div class="contacticon-2" style={{ backgroundImage: `url(${this.state.backgroundImage})` }}></div>
                                                                <div class="div-block-69">
                                                                    <div class="reviewsummarytext">{reviews.review}<span class="text-span-31">‍</span></div>
                                                                    <div class="star-rating"><Rate disabled defaultValue={reviews.rating}></Rate></div>
                                                                </div>
                                                            </div>
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                {/* </div> */}
                            </div>
                        </div>

                    </div>




                    <div class="footersection">
                        {/* <a class="profilequestionfooter w-inline-block" onClick={this.redirectToQuestion}>
                            <div class="qblockfooter" onClick={this.redirectToQuestion}></div>
                            <div class="byline a" onClick={this.redirectToQuestion}>ASK ME A QUESTION<br /></div>
                            <div class="authorimage b" onClick={this.redirectToQuestion}></div>
                            <div class="byline a" onClick={this.redirectToQuestion}>BARBARA MARQUARDT<br /><span class="text-span-23">Insurance Specialist</span></div>
                        </a> */}
                    </div>
                    {/* <BoxComponent></BoxComponent> */}
                    <Footer></Footer>
                </Spin>
            </div >

        )
    }
}

export default Profile