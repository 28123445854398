import React from 'react';
import Components from '../Dashboard/Component';
import 'antd/dist/antd.css';
import { LoginUser, GetMenuDetails, CheckMarriageTest, ForgotLogin } from '../Home/HomeService';
import { AddQuestion } from '../Question/questionService';
import { Modal, Input, Popover, Row, Col, message } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { connect } from 'react-redux';
import { AdviceCategory, PageName } from '../../Redux/Redux.Action';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { CheckEmail } from '../Register/RegisterService';
import { FilesURL } from '../ServiceURL';

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loginShow: false, logout: false, questionVisible: false, spinning: true, ResetEmail: '',
            AdviceMenu: [], ArticlesMenu: [], componentvalue: [{ component: '' }],
            Reload: false, isModalVisible: false, LoginModal: false, GeneralQuestionModal: false,
            ContactFirstName: '', ContactLastName: '', ContactEmail: '', ContactNote: ''
        }
    }

    async componentDidMount() {
        import('../Css/components.css')
        import('../Css/normalize.css')
        import('../Css/plmarriagetest.css')

        const script = document.createElement("script");
        //script.src = "http://192.227.67.200/Peachylife_/static/js/plmarriagetest3-944b06bbb-864f41afbdea5.js"; //http://18.223.153.219/
        script.src = FilesURL + "/Peachylife_/static/js/plmarriagetest3-944b06bbb-864f41afbdea5.js";
        script.async = true;

        document.body.appendChild(script);

        if (sessionStorage.getItem("Success") === "true") {
            this.setState({
                logout: true
            })
        }

        if (this.props.Value === "" || this.props.Value === null || this.props.Value === undefined) {
            var data = { component: 'Home' }
            this.ContentLoad(data);
        }
        else {
            var data = { component: this.props.Value }
            this.ContentLoad(data);
        }
        let MenuList = await GetMenuDetails();
        this.setState({
            AdviceMenu: MenuList.adviceMenus,
            ArticlesMenu: MenuList.articleMenus
        })
    }

    componentDidUpdate(prevState) {
        var x = prevState.Value;
        var PageName = this.props.Value;

        if (x !== PageName) {
            if (PageName !== "") {
                var data = { component: PageName }
                this.ContentLoad(data);
            }
        }
    }

    ContentLoad(data) {
        let comvalue = this.state.componentvalue
        comvalue[0].component = data.component;
        this.setState({
            componentvalue: comvalue,
        })
    }

    showtest = async () => {
        if ((sessionStorage.getItem('UserId') !== "") || (sessionStorage.getItem('UserId') !== null)) {
            let exist = await CheckMarriageTest()
            if (exist === true) {
                Modal.confirm({
                    content: (<div><p>You have already taken a Test.</p><p style={{ marginLeft: 40 }}> Do you wish to take a Re-Test?</p></div>),
                    okText: "Take Test",
                    cancelText: "View Results",
                    onOk: this.RedirectToTest,
                    onCancel: this.showReport
                });
            }
            else {
                this.RedirectToTest();
            }
        }
    }

    showReport = () => {
        this.props.history.push('/MarriageResult')
    }

    RedirectToTest = () => { this.props.history.push('/MarriageTest') }

    questionclick = () => {
        this.props.history.push('/Questions')
    }
    redirectToPopularQnA = () => {
        this.props.history.push('/PopularQnA')
    }

    showGeneralQuestion = () => {
        this.setState({ GeneralQuestionModal: !this.state.GeneralQuestionModal })
    }

    saveQuestion = async () => {
        let response = await AddQuestion(0, "General", this.state.ContactNote, this.state.ContactFirstName + " " + this.state.ContactLastName,
            this.state.ContactEmail, "", false)

        if (response) {
            Modal.success({ title: "Success", content: "We've sent the question. You'll be notified when you get answers from our professionals" })
            this.setState({ GeneralQuestionModal: false, ContactFirstName: '', ContactLastName: '', ContactEmail: '', ContactNote: '' })
        }
        else {
            message.error("Something went wrong!")
        }
    }

    LoginCLick = () => {
        this.setState({
            loginShow: true
        })
    }

    LoginModalClick = () => {
        this.setState({
            LoginModal: true
        })
    }

    CancelLoginModal = () => {
        this.setState({
            LoginModal: false
        })
    }

    HomeClick = () => {
        this.props.history.push('/Home')
    }

    Registerclick = () => {
        this.props.history.push('/Register')
    }

    Termsclick = () => {
        this.props.history.push('/Terms')
    }

    handleCancel = () => {
        this.setState({
            loginShow: false
        })
    }

    ResourceClick = () => {
        this.props.history.push('/Videos')
    }

    LogoutClick = () => {
        sessionStorage.setItem('UserId', null);
        sessionStorage.setItem('UserName', null);
        sessionStorage.setItem('Success', "false");
        Modal.success({ content: 'You have been Successfully Logged out' })
        this.props.history.push('/Home')
        this.setState({
            logout: false, LoginModal: false
        })
    }

    FinalSubmit = async () => {
        debugger;
        var returnedData;
        if (this.state.Email === "" || this.state.Password === "" || this.state.Email === undefined || this.state.Password === undefined) {
            Modal.warning({
                title: 'Error Login',
                content: 'Please enter your Username and Password',
            });
        }
        else {
            var returnedData = await LoginUser(this.state.Email, this.state.Password);
            sessionStorage.setItem('UserId', returnedData.userID);
            sessionStorage.setItem('UserName', returnedData.userName);
            sessionStorage.setItem('Success', returnedData.success);
            if (returnedData.success !== true) {
                Modal.error({ title: 'Error Login', content: "Invalid Credentials" });
            }
            else {
                this.setState({ loginShow: false })
                var data = { component: 'Home' }
                this.ContentLoad(data);
                this.setState({
                    logout: true, LoginModal: false
                })
            }
            if (returnedData === "") {
                Modal.error({ title: 'Error Login', content: 'Server Error Occured' })
            }
            this.setState({ visible: false, LoginStatus: true, home: true });
        }
    }

    AccountClick = () => {
        this.props.history.push('/MyAccount')
    }

    FamilyLaw = (category, e) => {
        window.gtag('event', 'conversion', {
            send_to: 'AW-10806936548/AfZeCJKY9YQDEOSHk6Eo',
        })
        this.props.history.push('/Advice')
        this.props.AdviceCategory(category);
    }

    gotoTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" })
    }

    forgetLogin = () => {
        this.setState({
            isModalVisible: true
        })
    }

    handleReset = async () => {
        debugger;
        var x = await CheckEmail(this.state.ResetEmail);
        if (this.state.ResetEmail === "" || this.state.ResetEmail === null) {
            Modal.warning({
                title: "Warning",
                content: (<p>Email id cannot be null . <br /> Please provide your registered Email to continue</p>),
            });
        }
        else if (x === false) {
            Modal.warning({
                content: 'Email id does not exists . Please register to continue',
            });
        }
        else {
            var x = await ForgotLogin(this.state.ResetEmail)
            if (x.sent === true) {
                Modal.success({
                    content: 'Reset mail sent successfully',
                });
            }
        }
        this.setState({
            isModalVisible: false
        })
    }

    handleResetCancel = () => {
        this.setState({
            isModalVisible: false
        })
    }

    ArticlesMenu = (category) => {
        debugger;
        // this.props.history.push('/Articles:' + category)
        //Pushing to the screen along with values!!
        this.props.history.push({
            pathname: '/Articles',
            // search: '?query=abc',
            state: { category: category }
        })
    }

    handleKeypress = e => {
        debugger;
        //it triggers by pressing the enter key
        if (e.keyCode === 13 || e.code === "Enter" || e.code === "NumpadEnter") {
            this.FinalSubmit();
        }
    };

    AllProfiles = () => {
        debugger;
        this.props.history.push('/AllProfiles')
    }

    render() {

        var mybutton = document.getElementById("myBtn");
        window.onscroll = function () { scrollFunction() };

        // const QImg1 = process.env.PUBLIC_URL + '/images/PeachyLifeQs_1PeachyLifeQs.png';
        // const QImg2 = process.env.PUBLIC_URL + '/images/PeachyLifeQs_1PeachyLifeQs.png';
        //Const PeachyLogo = process.env.PUBLIC_URL + '/images/Peachy_Brand_220PX.png';

        const QImg1 = process.env.PUBLIC_URL + '/images/logoqa.jpg';
        const QImg2 = process.env.PUBLIC_URL + '/images/askthepros.png';
        const MyWyeLogo = process.env.PUBLIC_URL + '/images/MyWye_Final_Logos_Color-07.png';

        //logoqa.jpg
        // const QImg1 = 'https://peachylife.com/peachylife_/media/images/thumbnail_QxA.jpg';
        // const QImg2 = 'https://peachylife.com/peachylife_/media/images/thumbnail_Q%20x%20A.jpg';

        function scrollFunction() {
            if (document.body.scrollTop > 10 || document.documentElement.scrollTop > 10) {
                mybutton.style.display = "block";
            } else {
                mybutton.style.display = "none";
            }
        }

        const { TextArea } = Input;

        const GetHelpPopOverTitle = (<div class="menutoptitle">Talk to a Professional</div>)
        const GetHelpPopOverContent = (
            <div className="dropdown-list-full-wrapper">
                <div className="navigation-drop-container">
                    <div className="navigation-column sw">
                        <div class="inset-block sc">
                            {this.state.AdviceMenu.map(menulist =>
                                <a onClick={this.FamilyLaw.bind(this, menulist.menuName)} className="dropdown-link-mobile w-inline-block">
                                    <div class="icon-square"><img src={process.env.PUBLIC_URL + `${menulist.imageUrl}`} alt="" class="imagepro _1" /></div>
                                    <div class="nav-content-wrap">
                                        <div class="menu-title tp">{menulist.menuName}</div>
                                        <div class="nav-link-details">{menulist.description}</div>
                                    </div>
                                </a>)
                            }
                        </div>
                    </div>
                </div>
                <div class="navigation-drop-container-footer sb">
                    <a onClick={this.AllProfiles} class="link-block-3 w-inline-block">
                        <div class="text-block-62">SEARCH ALL PROFESSIONAL PROFILES</div>
                    </a>
                </div>
            </div>
        )

        const ArticlesPopOverTitle = (<div className="menutoptitle">Articles, Books and Videos</div>)
        const ArticlesPopOverContent = (<div className="dropdown-list-full-wrapper articles">
            <div class="navigation-drop-container">
                <div class="navigation-column">
                    <div class="nav-card">
                        {this.state.ArticlesMenu.map((menulist, index) => {
                            return index < this.state.ArticlesMenu.length / 2 ? <a href="#" class="menu-item-slug w-inline-block" onClick={() => this.ArticlesMenu(menulist.category)}>
                                <div class="icon-square"><img src={process.env.PUBLIC_URL + `${menulist.imageUrl}`} alt="" class="questionqicon" /></div>
                                <div class="nav-content-wrap">
                                    <div class="menu-title tp">{menulist.menuName}</div>
                                    <div class="nav-link-details">{menulist.description}</div>
                                </div>
                            </a> : null
                        })}
                    </div>
                </div>
                <div class="navigation-column">
                    <div class="nav-card">
                        {this.state.ArticlesMenu.map((menulist, index) => {
                            return index >= this.state.ArticlesMenu.length / 2 ? <a href="#" class="menu-item-slug w-inline-block" onClick={() => this.ArticlesMenu(menulist.category)}>
                                <div class="icon-square"><img src={process.env.PUBLIC_URL + `${menulist.imageUrl}`} alt="" class="questionqicon" /></div>
                                <div class="nav-content-wrap">
                                    <div class="menu-title tp">{menulist.menuName}</div>
                                    <div class="nav-link-details">{menulist.description}</div>
                                </div>
                            </a> : null
                        })}
                    </div>
                </div>
            </div>
            <div class="navigation-drop-container-footer sb">
                <a class="link-block-3 w-inline-block" onClick={() => this.ArticlesMenu("All")}>
                    <div class="text-block-62">SEARCH ALL ARTICLES</div>
                </a>
            </div>
        </div>)

        const Question = (<div>
            <div class="dropdown-list-full-wrapper">
                <a class="navigation-drop-container w-inline-block" onClick={this.showGeneralQuestion}>
                    <div class="div-block-54">
                        <div class="icon-square"><img src={QImg2} alt="" class="questionqicon" /></div>
                        <div class="menu-title q"><span class="text-span-4"></span></div>
                        <div class="text-block-81">Click here to ask a general question which will be reviewed and answered by multiple professionals throughout our network. You will be directly emailed answers and it will also appear on our main QxA section.</div>
                    </div>
                </a>
                <a class="navigation-drop-container w-inline-block" onClick={this.questionclick}>
                    <div class="div-block-54">
                        <div class="icon-square"><img src={QImg2} alt="" class="questionqicon" /></div>
                        <div class="menu-title q"><span class="text-span-4"></span></div>
                        <div class="text-block-81">Click here to directly and privately contact a professional in your area.</div>
                    </div>
                </a>
                <a class="navigation-drop-container w-inline-block" onClick={this.redirectToPopularQnA}>
                    <div class="div-block-54">
                        <div class="icon-square"><img src={QImg2} alt="" class="questionqicon" /></div>
                        {/* <div class="menu-title q"><span class="text-span-4">Ask a Professional ANYTHING</span></div> */}
                        {/* <div class="text-block-81">You get a private answer and can choose to start a consultation. <span class="text-span-31">ASK YOUR QUESTION</span></div> */}
                        <div class="menu-title q"><span class="text-span-4"></span></div>
                        <div class="text-block-81">View Popular Questions and Answers <br /><span class="text-span-31">ASK YOUR QUESTION</span></div>
                    </div>
                </a>
            </div>
        </div>)



        return (
            <div>
                <a id="myBtn" onClick={this.gotoTop}><img src={process.env.PUBLIC_URL + '/images/top.svg'}></img></a>
                <div class="w-embed w-iframe">
                    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WQNXXP9" height="0" width="0" style={{ display: "none", visibility: "hidden" }}></iframe></noscript>
                </div>
                <div class="header">
                    <div data-collapse="medium" data-animation="default" data-duration="400" role="banner" class="navigation w-nav">
                        <div class="navigation-full">
                            <div class="navigation-container">
                                <a href="#" aria-current="page" onClick={this.HomeClick} class="brand w-nav-brand w--current logo"><img src={process.env.PUBLIC_URL + '/images/MyWye_Final_Logos_Color-07.png'} data-w-id="a3f690b4-743a-6aa0-cbe9-ad91eab37478" alt="MyWye Brand" class="plbrand" /></a>
                                <nav role="navigation" class="nav-menu w-nav-menu">
                                    {this.state.logout === false ?
                                        <div class="account-links">
                                            {/* <a href="#" data-w-id="a4209aa6-b177-b2ca-6f8f-f51127c41e92" class="account-link a w-nav-link" onClick={this.HomeClick}>Home</a> */}
                                            <a href="#" data-w-id="a4209aa6-b177-b2ca-6f8f-f51127c41e92" class="account-link a w-nav-link" onClick={this.Registerclick}>Register</a>
                                            <div data-w-id="2ab565e2-977b-7259-3af3-9e148454d6a9" class="account-login-toggle" onClick={this.LoginCLick} style={{ cursor: "pointer" }}>
                                                <div data-w-id="800db7e1-739f-c281-1194-60067aecafe0" class="account-link" onClick={this.LoginCLick}>Log In</div>
                                            </div>
                                        </div> :
                                        <div class="account-links">
                                            {/* <a href="#" data-w-id="a4209aa6-b177-b2ca-6f8f-f51127c41e92" class="account-link a w-nav-link" onClick={this.HomeClick}>Home</a> */}
                                            <a href="#" data-w-id="a4209aa6-b177-b2ca-6f8f-f51127c41e92" class="account-link a w-nav-link" onClick={this.AccountClick}>Account</a>
                                            <div data-w-id="2ab565e2-977b-7259-3af3-9e148454d6a9" style={{ cursor: "pointer" }} class="account-login-toggle">
                                                <div class="account-link" onClick={this.LogoutClick}>LogOut</div>
                                            </div>
                                        </div>}

                                    <div class="accountloginpopupmodal">
                                        <div class="loginpopup">
                                            <div data-w-id="bb9d16ba-4d75-699f-1fe8-19463999f0e3" class="closexbutton" onClick={this.handleCancel}></div>
                                            <div>
                                                <div class="menutoptitle" style={{ cursor: "pointer" }}>Login</div>
                                                <div class="logintabfield w-inline-block">
                                                    <Input className="logintitle" placeholder="User name / email" onChange={(e) => { this.setState({ Email: e.target.value }) }} />
                                                </div>
                                                <div class="logintabfield w-inline-block">
                                                    <Input type="password" iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} placeholder="Password" className="logintitle" onChange={(e) => { this.setState({ Password: e.target.value }) }} />
                                                </div>
                                            </div>
                                            <div class="link-block-8 w-inline-block">
                                                <div data-w-id="bb9d16ba-4d75-699f-1fe8-19463999f0e3" class="menu-title" style={{ cursor: "pointer" }} onClick={this.forgetLogin}>forgot login</div>
                                            </div>
                                            <div class="okbutton w-inline-block" style={{ cursor: "pointer" }}>
                                                <div data-w-id="bb9d16ba-4d75-699f-1fe8-19463999f0e3" onKeyPress={this.handleKeypress} class="button-copy" style={{ cursor: "pointer" }} onClick={this.FinalSubmit}>Login</div>
                                            </div>
                                        </div>
                                    </div>

                                    {this.state.LoginModal &&
                                        <Modal width={700} footer={false} visible={this.state.LoginModal} onOk={this.handleReset} onCancel={this.CancelLoginModal}>
                                            {/* <div data-w-id="bb9d16ba-4d75-699f-1fe8-19463999f0e3" class="closexbutton" onClick={this.CancelLoginModal}></div> */}
                                            <Row>
                                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                    <a href="#" aria-current="page" class="brand w-nav-brand w--current" onClick={this.HomeClick}>
                                                        <img src={MyWyeLogo} data-w-id="a3f690b4-743a-6aa0-cbe9-ad91eab37478" alt="MyWye Brand" class="plbrand" />
                                                    </a>
                                                </Col>
                                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                    <div className="Margin">
                                                        <div class="menutoptitle" style={{ cursor: "pointer" }}>Login</div>
                                                        <div href="#" class="logintabfield w-inline-block">
                                                            <Input className="logintitle" placeholder="User name / email" onChange={(e) => { this.setState({ Email: e.target.value }) }} />
                                                        </div>
                                                        <div href="#" class="logintabfield w-inline-block">
                                                            <Input type="password" className="logintitle" placeholder="Password" onChange={(e) => { this.setState({ Password: e.target.value }) }} />
                                                        </div>
                                                        <a href="#" class="okbutton w-inline-block Reset" style={{ cursor: "pointer" }}>
                                                            <div data-w-id="bb9d16ba-4d75-699f-1fe8-19463999f0e3" class="button-copy" style={{ cursor: "pointer" }} onClick={this.FinalSubmit}>Login</div>
                                                        </a>
                                                        <div class="link-block-8 w-inline-block">
                                                            <div data-w-id="bb9d16ba-4d75-699f-1fe8-19463999f0e3" class="menu-title" style={{ cursor: "pointer" }} onClick={this.forgetLogin}>forgot login</div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Modal>
                                    }

                                    {this.state.isModalVisible &&
                                        <Modal width={700} footer={false} closable={false} visible={this.state.isModalVisible} onOk={this.handleReset} onCancel={this.handleResetCancel}>
                                            <div className="closexbutton" onClick={this.handleResetCancel}></div>
                                            <Row>
                                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                    <a href="#" aria-current="page" class="brand w-nav-brand w--current forgotLoginLogo" onClick={this.HomeClick}>
                                                        <img src={MyWyeLogo} data-w-id="a3f690b4-743a-6aa0-cbe9-ad91eab37478" alt="MyWye Brand" class="plbrand" />
                                                    </a>
                                                </Col>
                                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                    <div className="Margin">
                                                        <div class="menutoptitle" style={{ cursor: "pointer" }}>Forgot Login</div>
                                                        <div>Please enter your email address below and we will send you information to change your password.</div>
                                                        <a href="#" class="logintabfield w-inline-block">
                                                            <Input className="logintitle" placeholder="User name / email" onChange={(e) => { this.setState({ ResetEmail: e.target.value }) }} />
                                                        </a>
                                                        <a href="#" class="okbutton w-inline-block Reset" style={{ cursor: "pointer" }}>
                                                            <div data-w-id="bb9d16ba-4d75-699f-1fe8-19463999f0e3" class="button-copy" style={{ cursor: "pointer" }} onClick={this.handleReset}>Reset</div>
                                                        </a>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Modal>
                                    }

                                    <Popover placement="bottomRight" id="GetAdvice" arrowContent={false} title={GetHelpPopOverTitle} content={GetHelpPopOverContent}>
                                        <div data-hover="1" data-delay="0" class="dropdown w-dropdown">
                                            <div class="dropdown-toggle w-dropdown-toggle">
                                                <div class="drop-arrow-plain w-icon-dropdown-toggle"></div>
                                                <div class="text-block-63">Get Advice</div>
                                            </div>
                                        </div>
                                    </Popover>

                                    <Popover className="dropdown w-dropdown" placement="bottomRight" title={ArticlesPopOverTitle} content={ArticlesPopOverContent}>
                                        <div data-hover="1" data-delay="0" class="dropdown w-dropdown">
                                            <div class="dropdown-toggle w-dropdown-toggle">
                                                <div class="drop-arrow-plain w-icon-dropdown-toggle"></div>
                                                <div class="text-block-63">Articles</div>
                                            </div>
                                        </div>
                                    </Popover>

                                    <a href="#" class="navigation-link w-nav-link" onClick={this.ResourceClick}>Videos</a>

                                    <Popover className="dropdown w-dropdown" placement="bottomRight" content={Question}>
                                        <div data-hover="1" data-delay="0" class="dropdown w-dropdown">
                                            <div class="dropdown-toggle w-dropdown-toggle">
                                                <a href="#" class="qsblocknav w-inline-block"><img src={QImg1} loading="lazy" alt="" class="questionq-nav" /></a>
                                            </div>
                                        </div>
                                    </Popover>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div data-collapse="medium" data-animation="default" data-duration="400" data-easing="ease-out" data-easing2="ease-out" role="banner" class="navigation-mob w-nav">
                        <div class="navigation-container-mob">
                            <a href="#" class="w-nav-brand" onClick={this.HomeClick}><img src={MyWyeLogo} alt="" class="pllogotnav" onClick={this.HomeClick} /></a>
                            <nav role="navigation" class="mobile-nav w-nav-menu">
                                <a href="#" class="mobile-nav-link w-nav-link" onClick={this.HomeClick} style={{ cursor: "pointer" }}>Home</a>
                                {this.state.logout === false ?
                                    <a href="#" data-w-id="2ab565e2-977b-7259-3af3-9e148454d6a9" class="mobile-nav-link w-nav-link" onClick={this.LoginModalClick} style={{ cursor: "pointer" }}>login</a> : null
                                }
                                {this.state.logout === false ?
                                    <a href="#" class="mobile-nav-link w-nav-link" onClick={this.Registerclick} style={{ cursor: "pointer" }}>Register</a> :
                                    <a href="#" class="mobile-nav-link w-nav-link" onClick={this.AccountClick} style={{ cursor: "pointer" }}>My Account</a>
                                }
                                <a href="#" class="mobile-nav-link w-nav-link" onClick={() => this.ArticlesMenu("All")}>Articles</a>
                                <a href="#" class="mobile-nav-link w-nav-link" onClick={this.AllProfiles}>Find Professionals</a>
                                <a href="#" class="mobile-nav-link w-nav-link" style={{ cursor: "pointer" }} onClick={this.questionclick}>Questions</a>
                                <a href="#" class="mobile-nav-link w-nav-link" style={{ cursor: "pointer" }} onClick={this.ResourceClick}>Videos</a>
                                {this.state.logout !== false ?
                                    <a href="#" class="mobile-nav-link w-nav-link" style={{ cursor: "pointer" }} onClick={this.LogoutClick}>Logout</a> : null
                                }
                            </nav>
                            <div class="menu-mob w-nav-button">
                                <div class="icon-3 w-icon-nav-menu"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal visible={this.state.GeneralQuestionModal}
                    closable
                    onCancel={this.showGeneralQuestion}
                    footer={null}
                    className="DashContactModal"
                >
                    <div class="formpopup">
                        <div class="closexbutton" onClick={this.showGeneralQuestion}></div>
                        <div class="formblock">
                            <div class="menutoptitle-2">Contact Form</div>
                            <div class="subheaddescript a">Use this form to contact My Wye</div>

                            <div class="logintabfield">
                                <Input className="clientreferralname" placeholder="First Name" autoFocus onBlur={(e) => { this.setState({ ContactFirstName: e.target.value }) }} />
                            </div>
                            <div class="logintabfield">
                                <Input className="clientreferralname" placeholder="Last Name" onBlur={(e) => { this.setState({ ContactLastName: e.target.value }) }} />
                            </div>
                            <div class="logintabfield">
                                <Input className="clientreferralname" placeholder="e mail" onBlur={(e) => { this.setState({ ContactEmail: e.target.value }) }} />
                            </div>
                            <div class="form-fieldnote-copy"> {/* Note: (please supply some context) */}
                                <TextArea className="clientreferralname" style={{ resize: 'none', height: 100, width: 375 }}
                                    placeholder="Question for the Pros."
                                    onBlur={(e) => { this.setState({ ContactNote: e.target.value }) }}
                                />
                            </div>
                            <div class="sendbuttonblock">
                                <a href="#" class="sendbutton2 w-inline-block">
                                    <div class="sendbutton" style={{ marginLeft: '50px', marginTop: '4px', color: 'white' }} onClick={this.saveQuestion}>SEND</div>
                                </a>
                                <div class="subheaddescript">We will send an email to all parties to confirm</div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div >
        )
    }
}


const mapStateToProp = state => {
    return {
        Value: state.Value,
        Category: state.Category
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        PageName: () => dispatch(PageName()),
        AdviceCategory: Category => dispatch(AdviceCategory(Category))
    }
}

export default compose(withRouter, connect(mapStateToProp, mapDispatchToProps))(Dashboard);