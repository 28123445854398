import React from 'react';
import { Button, Modal, Input, Slider, Popover, Space, Carousel, Menu, Dropdown, Row, Col, Card, Divider, Form, Radio, Checkbox } from 'antd';
import 'antd/dist/antd.css';
import { InviteFriendService } from './InviteFriendService'
import {ServiceUrl, FilesURL} from '../ServiceURL';

class InviteFriend extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Email: '', Message: ''
        }
    }

    InviteClick = async () => {
        debugger;
        let sent = []
        if (this.state.Email === '' || this.state.Message === '') {
            Modal.warning({ content: "Fill both the fields" })
        }
        else {
            sent = await InviteFriendService(this.state.Email, this.state.Message);
            if (sent.sent === true) {
                Modal.success({ content: 'Mail Sent Successfully' });
                this.setState({Email:'',Message:''})
            }
            else
                Modal.error({ content: 'Something went wrong' });
        }
    }

    render() {
        return (
            <div>
                <div class="w-layout-grid gridinvite">
                    <div id="w-node-70d20cb57f5f-933ed30c" class="div-block-19"><img src="images/InviteSpose.png" loading="lazy" alt="" class="image-13" /></div>
                    <div id="w-node-70d20cb57f61-933ed30c" class="div-block-12"></div>
                    <div id="w-node-70d20cb57f62-933ed30c" class="resultsone form">
                        <h1 class="formfield"><Input placeholder="Email" value={this.state.Email} onChange={(e) => { this.setState({ Email: e.target.value }) }} /></h1>
                        <h1 class="formfield"><Input placeholder="Message" value={this.state.Message} onChange={(e) => { this.setState({ Message: e.target.value }) }} /></h1>
                        <div class="div-block-20">
                            <a href="#" class="button w-button" onClick={this.InviteClick}>Send</a>
                            <p class="smallhelptext">Invite others to take their own test. Compare results</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default InviteFriend;