import { ServiceUrl } from '../ServiceURL';

export async function AddMarriageAnswer(answer, tab) {
    debugger;
    let GetUserField = [];
    var userID = sessionStorage.getItem('UserId');
    var UserName = sessionStorage.getItem('UserName');
    const data = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ UserId: userID, UserName: UserName, AnswerJson: answer, PageName: tab })
    };
    await fetch(ServiceUrl + '/api/AddTestResults', data) //http://192.227.67.200/peachylifeapi/api/AddTestResults
        .then((response) => response.json()) //https://peachylife.com/peachylifeapi/api/
        .then((responseJson) => {
            GetUserField = responseJson;
        })
    return GetUserField;
}

export async function GetMarriageAnswer(tab) {
    debugger;
    let GetUserField = [];
    var userID = sessionStorage.getItem('UserId');
    const data = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ UserId: userID, PageName: tab })
    };
    await fetch(ServiceUrl + '/api/GetTestResults', data) //https://localhost:5001/ http://192.227.67.200/peachylifeapi/api/GetTestResults
        .then((response) => response.json()) //https://peachylife.com/peachylifeapi/api/
        .then((responseJson) => {
            GetUserField = responseJson;
        })
    return GetUserField;
}