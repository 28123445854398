import React from 'react'
import { connect } from 'react-redux';
import { PageName, AdviceCategory } from '../../Redux/Redux.Action';
import BoxComponent from '../BoxComponent/BoxComponent'
import { GetPageInformation, GetbyExperience, GetbyRating, GetAllSpecialistInformation } from './ProfileService'
import { Row, Col, Rate } from 'antd';
import MetaTags from 'react-meta-tags';
import Footer from '../Footer/Footer';
import Header from '../Dashboard/Dashboard';
import { ServiceUrl, FilesURL } from '../ServiceURL';
import ReactGA from 'react-ga';

class ProfessionalProfiles extends React.Component {
    constructor(props) {
        super(props);
        this.state = { ProfessionalCategory: [], Experts: [], PageDetails: [] }
        this.myDivToFocus = React.createRef();
    }

    async componentDidMount() {
        debugger;
        const script = document.createElement("script");
        //script.src = "http://192.227.67.200/Peachylife_/static/js/plmarriagetest3-944b06bbb-864f41afbdea5.js";
        script.src = FilesURL + "/Peachylife_/static/js/plmarriagetest3-944b06bbb-864f41afbdea5.js";
        script.async = true;
        document.body.appendChild(script);

        ReactGA.initialize('G-JT4HS6ZZSX');
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.gtag('event', 'conversion', { 'send_to': 'AW-10806936548/AfZeCJKY9YQDEOSHk6Eo' });

        this.props.PageName("")
        var Category = this.props.Category;
        var PageDetails = await GetPageInformation(this.props.Category);
        if (PageDetails.professionalCategories.length > 0) {
            var jsonPageDetails = JSON.parse(PageDetails.professionalCategories[0].pageDetails)
        }
        this.setState({
            ProfessionalCategory: PageDetails.professionalCategories,
            Experts: PageDetails.getAdviceList, PageDetails: jsonPageDetails
        })
    }

    async componentDidUpdate(prevState) {
        debugger;
        var x = prevState.Category;
        var Category = this.props.Category;
        if (x != Category) {
            if (x != "") {
                var PageDetails = await GetPageInformation(this.props.Category);

                if (PageDetails.professionalCategories.length > 0) {
                    var jsonPageDetails = JSON.parse(PageDetails.professionalCategories[0].pageDetails)
                }

                this.setState({
                    ProfessionalCategory: PageDetails.professionalCategories,
                    Experts: PageDetails.getAdviceList, PageDetails: jsonPageDetails
                })
            }
        }

        if (Category === "Family Law") {
            var meta = document.createElement('meta');
            meta.title = "Find a Top Divorce Lawyer in your area - Free Consultations - MyWye";
            meta.content = "Our top rated divorce lawyers have all been vetted and are well known in their area.  You will find the top divorce Attorneys in your area.";
            document.head.appendChild(meta);
        }
        else if (Category === "Financial Experts") {
            var meta = document.createElement('meta');
            meta.title = "Find a Top Financial Advisor in your area - Free Consultations - MyWye";
            meta.content = "Our top rated financial advisors have all been vetted and are well known in their area.  You will find the top financial advisors in your area.";
            document.head.appendChild(meta);
        }
    }

    componentWillMount() {
        import('../Css/components.css')
        import('../Css/normalize.css')
        import('../Css/plmarriagetest.css')
    }

    ProfileClick = (data) => {
        debugger;
        var ExpertId = parseInt(data.expertId)
        sessionStorage.setItem("ExpertId", ExpertId)
        // this.props.history.push('/Profile')
        this.props.history.push('/Profile/' + data.username)
    }

    orderbyrating = async () => {
        debugger;
        var PageDetails = await GetbyRating(this.props.Category);
        this.setState({
            Experts: PageDetails.getAdviceList
        })
    }

    orderbyexperience = async () => {
        debugger;
        var PageDetails = await GetbyExperience(this.props.Category);
        this.setState({
            Experts: PageDetails.getAdviceList
        })
    }

    getExperts = () => {
        debugger;
        var x = this.myDivToFocus;
        this.behavior(x);
    }

    behavior = (x) => {
        x.current.scrollIntoView({
            behavior: "smooth",
            block: "nearest"
        })
    }

    QuestionClick = () => {
        this.props.history.push('/Questions')
    }

    render() {

        const Practitioners = (<p>Practitioners</p>)

        return (
            <div>
                <Header></Header>
                <MetaTags>
                    <title>Advice</title>
                    <meta property="og:url" content="http://www.mywye.com/#/Advice" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="Find a Top Divorce Lawyer in your area - Free Consultations - MyWye" />
                </MetaTags>
                <div class="subject-header">
                    <div class="div-block-49">
                        <div class="blueinset-3">
                            <div class="leftheader">
                                <p class="white-heading whiteheading36">{this.state.ProfessionalCategory.length > 0 ? this.state.ProfessionalCategory[0].pageTitle : null}</p>
                                <a href="#" class="blue-button-3 w-button" onClick={this.getExperts}>Find a Professional</a>
                            </div>
                            <div class="rightsideheadder">
                                <div class="text-block-78"><span class="text-span-28">The best and the brightest...</span> {this.state.ProfessionalCategory.length > 0 ? this.state.ProfessionalCategory[0].pageDescription : null}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="TopImageCouple" youngmarriedcouple="Young Married Couple" class="page-section">
                    <div class="mapmobile"></div>
                    <div class="mobilegeo"><span class="text-span-32"><strong>SERVICE AREA</strong></span><strong><br />‍</strong>My Wye currently serves the Southern California area. Some services are available via online conference for customers out of this area. <br /><br />Inclusion in the network is by invitation only at this time.</div>
                    <div class="geographic">
                        <div class="mapclass large"></div>
                        <div class="div-block-50">
                            <div class="div-block-51">
                                <div class="text-block-79"><span class="text-span-32"><strong>SERVICE AREA</strong></span><strong><br />‍</strong>My Wye currently serves the Southern California area. Some services are available via online conference for customers out of this area. <br /><br />Inclusion in the network is by invitation only at this time.</div>
                            </div>
                        </div>
                    </div>
                    <div class="searchheader">
                        <div class="div-block-55">
                            <div class="headline">Trusted Professional {this.state.ProfessionalCategory.length > 0 ? this.state.ProfessionalCategory[0].pageTitle : null} </div>
                            {/* <div class="searchcriteriabuttons">
                                <div class="searchbuttonoutlne-copy">
                                    <div class="buttontextpink" onClick={this.orderbyrating}>BY RATING</div>
                                </div>
                                <div class="searchbuttonoutlne-copy">
                                    <div class="buttontextpink">WITH A FIRM</div>
                                </div>
                                <div class="searchbuttonoutlne-copy">
                                    <div class="buttontextpink">MATCH STYLE</div>
                                </div>
                                <div class="searchbuttonoutlne-copy">
                                    <div class="buttontextpink">BY LOCATION</div>
                                </div>
                                <div class="searchbuttonoutlne-copy">
                                    <div class="buttontextpink" onClick={this.orderbyexperience}>EXPERIENCE</div>
                                </div>
                            </div> */}
                        </div>
                        <Row ref={this.myDivToFocus}>
                            <div class="w-layout-grid search-professionals-profile">
                                {this.state.Experts.map((ExpertList, index) => {
                                    return <Col md={8} sm={24} xs={24}>
                                        <div id="w-node-0be2bd9d11ca-cca2022f" class="search-profiles-roundv" onClick={() => this.ProfileClick(ExpertList)}> {/* this.ProfileClick.bind(this, ExpertList.expertId) */}
                                            <div class="searchprofilepic a"><img src={`${ExpertList.imageUrl}`} loading="lazy" alt="" class="ExpertImage" /></div>
                                            <div class="searchtitletag-round">{`${ExpertList.firstName}`} &nbsp; {`${ExpertList.lastName}`}<br /><span class="searchsubtitle">{ExpertList.category}</span></div>
                                            <div class="reviewssearch">{ExpertList.reviewsCount} reviews<br /><span class="text-span-27"><Rate disabled value={ExpertList.averageRating}></Rate></span><span class="text-span-26"><br />9 Contacts<br />1 Article<br />{ExpertList.city} {ExpertList.state}<br />{ExpertList.experience <= 10 ? ExpertList.experience + `${" yrs"}` : "10+ yrs"}</span><br /></div>
                                            <div class="searchprofilebutton">
                                                <div class="buttontextwhite-copy">VIEW PROFILE</div> {/* .bind(this,ExpertList.expertId) */}
                                            </div>
                                        </div>
                                        <div class="ask-a-q" onClick={this.QuestionClick}></div>
                                        {/*<img src={process.env.PUBLIC_URL + `${ExpertList.imageUrl}`} loading="lazy" alt="" class="ExpertImage" /> */}
                                    </Col>
                                })}
                            </div>
                        </Row>
                    </div>
                    <div class="specialty-subjectbackground">
                        <div class="headline">Essential Guidelines to {this.state.ProfessionalCategory.length > 0 ? this.state.ProfessionalCategory[0].pageTitle : null}</div>
                        <p class="categoryguidecopy">
                            {this.state.PageDetails !== undefined && this.state.PageDetails.map((PageDetails) => {
                                return <div>
                                    <div dangerouslySetInnerHTML={{ __html: PageDetails.Question }} />
                                    <br />
                                    <p dangerouslySetInnerHTML={{ __html: PageDetails.Answer }}>
                                    </p>
                                </div>
                            })
                            }
                        </p>
                    </div>
                </div>
                <div class="footersection"></div>
                <BoxComponent></BoxComponent>
                <Footer></Footer>
            </div>
        )
    }
}

const mapStateToProp = state => {
    return {
        Value: state.Value,
        Category: state.Category
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        PageName: Value => dispatch(PageName(Value)),
        AdviceCategory: () => dispatch(AdviceCategory())
    }
}

export default connect(mapStateToProp, mapDispatchToProps)(ProfessionalProfiles);