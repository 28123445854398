import { Pagename, Advicecategory, ForgetPwdemail } from './Redux.Type'

export const PageName = (Value) => {
    return {
        type: Pagename,
        Value
    }
}

export const AdviceCategory = (Category) => {
    return {
        type: Advicecategory,
        Category
    }
}