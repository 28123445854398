import React from 'react'
import { withRouter } from 'react-router'
import { GetUserProfileId } from './UserProfileLinkService'

class UserProfileLink extends React.Component {
    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        debugger;
        const query = new URLSearchParams(this.props.location.search);
        const token = query.get('Email')
        var returnedData = await GetUserProfileId(token);
        if (returnedData.success === true || returnedData.success === "true") {
            var ExpertId = parseInt(returnedData.userId)
            sessionStorage.setItem("ExpertId", ExpertId)
            this.props.history.push('/Profile')
        }
        else
            this.props.history.push('/Home')
    }

    render(){
        return(
            <div>

            </div>
        )
    }
}

export default withRouter(UserProfileLink)