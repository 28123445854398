import React from 'react';
import { withRouter } from 'react-router';
import { CheckMarriageTest } from '../Home/HomeService';
import { Modal } from 'antd';

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loginShow: false, logout: false, questionVisible: false, spinning: true, ResetEmail: '',
            AdviceMenu: [], ArticlesMenu: [], componentvalue: [{ component: '' }], Reload: false, isModalVisible: false,
        }
    }

    Termsclick = () => {
        this.props.history.push('/Terms')
    }

    ArticlesMenu = () => {
        debugger;
        // this.props.history.push('/Articles:' + category)
        //Pushing to the screen along with values!!
        this.props.history.push({
            pathname: '/Articles',
            // search: '?query=abc',
            state: { category: "All" }
        })
    }

    questionclick = () => {
        this.props.history.push('/Questions')
    }

    showtest = async () => {
        debugger
        if ((sessionStorage.getItem('UserId') !== "") && (sessionStorage.getItem('UserId') !== null) && (sessionStorage.getItem('UserId') !== "null")) {
            let exist = await CheckMarriageTest()
            if (exist === true) {
                Modal.confirm({
                    content: (<div><p>You have already taken a Test.</p><p style={{ marginLeft: 40 }}> Do you wish to take a Re-Test?</p></div>),
                    okText: "Take Test",
                    cancelText: "View Results",
                    onOk: this.RedirectToTest,
                    onCancel: this.showReport
                });
            }
            else {
                this.RedirectToTest();
            }
        }
        else {
            this.props.history.push('/MarriageTest')
        }
    }

    RedirectToTest = () => { this.props.history.push('/MarriageTest') }

    FAQClick = () => {
        this.props.history.push('/FAQ')
    }

    HomeClick = () => {
        this.props.history.push('/Home')
    }

    render() {
        return (
            <div>
                <footer class="footer2-2">
                    <div class="footercontainter w-container">
                        <div class="w-layout-grid grid">
                            <div id="w-node-19844c8798ba-4c8798b7" class="div-block-3">
                                <a data-w-id="bb1b0c96-cd83-4f5c-3913-19844c8798bb" href="#" onClick={this.HomeClick} class="link-block w-inline-block logo"></a>
                                <div class="div-block-9">
                                    <a href="#" class="socialmediaicon-2" onClick={this.Termsclick}>Privacy Policy</a>
                                    <a href="#" class="socialmediaicon-2" onClick={this.Termsclick}>Terms and Conditions</a>
                                    <a href="#" target="_blank" class="socialmediaicon-2" onClick={this.showtest}>Marriage Test</a>
                                    <a href="#" class="socialmediaicon-2" onClick={this.ArticlesMenu}>Articles</a>
                                    <a href="#" class="socialmediaicon-2 space" onClick={this.questionclick} >Ask A Professional</a>
                                    <a href="#" class="socialmediaicon-2 space" onClick={this.FAQClick}>Help / FAQs</a>
                                </div>
                                <div class="social-icons">
                                    <h1 class="yelloheader17-2">JOIN THE CONVERSATION</h1>
                                    <a href="https://twitter.com/mywye1" target="_blank" class="socailmedialink w-inline-block"><img src="images/TwitterICON.svg" loading="lazy" alt="Twitter PeachyLife ICon" /></a>
                                    <a href="https://www.facebook.com/mywye1/" target="_blank" class="socailmedialink w-inline-block"><img src="images/FacebookIcon.svg" loading="lazy" alt="FaceBook PeachyLife Icon" /></a>
                                    <a href=" https://www.youtube.com/channel/UC9XkGJg7hwIf_RaGwDsswUQ" target="_blank" class="socailmedialink w-inline-block"><img src="images/YouTubeICON.svg" loading="lazy" alt="Youtube PeachyLife Icon" /></a>
                                    {/* <a href="#" target="_blank" class="socailmedialink w-inline-block"><img src="images/QuoraIcon.svg" loading="lazy" alt="FaceBook PeachyLife Icon" width="50" class="image-6" /></a> */}
                                    {/* <div class="whiteubuntu17-4">Accessibility: If you are vision-impaired or have some other impairment covered by the Americans with Disabilities Act or a similar law, and you wish to discuss potential accommodations related to using this website, please contact us directly.</div> */}
                                </div>
                                <div class="whiteubuntu17-4">Accessibility: If you are vision-impaired or have some other impairment covered by the Americans with Disabilities Act or a similar law, and you wish to discuss potential accommodations related to using this website, please contact us directly.</div>
                            </div>
                            <div id="w-node-19844c8798d2-4c8798b7" class="div-block-5">
                                <h1 class="whitesubhead-2">OUR MISSION:<br />IS TO CONNECT YOU WITH TOP PROFESSIONALS AND RESOURCES FOR WHATEVER SERVICES YOU NEED TO LIVE YOUR BEST LIFE.</h1>
                                <div class="whiteubuntu17-2">What is the state of your relationship? Are you happy? Do you see a bright future and feel connected to your partner? <br /><br />Get started by taking the marriage test to assess the state of your relationship.  Whether you’re looking to improve your relationship or contemplating a separation, we have professionals who can help. <br /><br />At MyWye you can connect with others who are going through similar challenges. We have a community that you can access anonymously, from resources to help you to people that you can really talk to.<br /></div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        )
    }
}

export default withRouter(Footer);