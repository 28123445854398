import { ServiceUrl } from '../ServiceURL';

export async function InviteFriendService(Email, Message) {
    debugger;
    let GetUserField = [];
    const data = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ToEmail: Email, Message: Message })
    };
    await fetch(ServiceUrl + '/api/SendSMS', data) //http://192.227.67.200/peachylifeapi/api/SendSMS https://localhost:5001/peachylifeapi/api/SendSMS
        .then((response) => response.json()) //https://peachylife.com/peachylifeapi/api/
        .then((responseJson) => {
            GetUserField = responseJson;
        })
    return GetUserField;
}