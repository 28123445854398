import React, { useRef, useState, createRef } from 'react';
import { Modal, Carousel, Checkbox, Input } from 'antd';
import MetaTags from 'react-meta-tags';
import 'antd/dist/antd.css';
import '../Css/Peachy.css';
import BoxComponent from '../BoxComponent/BoxComponent'
import TextArea from 'antd/lib/input/TextArea';
import Footer from '../Footer/Footer';
import Header from '../Dashboard/Dashboard';
import { AddQuestion } from './questionService'
import TopQuestions from '../TopQuestions/TopQuestions';
import { ServiceUrl, FilesURL } from '../ServiceURL';
import ReactGA from 'react-ga';

class question extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Email: '', Password: '', loginShow: false, AdviceMenu: [], ArticlesMenu: [], NextButton: true, SubmitButton: false
            , UserQuestion: '', UserCity: '', UserEmail: '', UserName: '', Category: '', VirtualConsultations: false
        }
        this.carouselRef = createRef();
        this.myDivToFocus = React.createRef();
    }

    componentWillMount() {
        import('../Css/components.css')
        import('../Css/normalize.css')
        import('../Css/plmarriagetest.css')
    }

    componentDidMount = () => {
        const script = document.createElement("script");
        //script.src = "http://192.227.67.200/Peachylife_/static/js/plmarriagetest3-944b06bbb-864f41afbdea5.js";
        script.src = FilesURL + "/Peachylife_/static/js/plmarriagetest3-944b06bbb-864f41afbdea5.js";
        script.async = true;
        document.body.appendChild(script);

        ReactGA.initialize('G-JT4HS6ZZSX');
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.gtag('event', 'conversion', { 'send_to': 'AW-10806936548/AfZeCJKY9YQDEOSHk6Eo' });

        window.scrollTo({ top: 0 })
    }

    QuestionCLick = () => {
        debugger;
        window.scrollTo({ top: 0 })
    }

    slideNextChanger = () => {
        this.carouselRef.current.next();
    }

    slidePrevChanger = () => {
        this.carouselRef.current.prev();
    }
    currentSLider = (a) => {
        // if (a === 4) {
        //     this.setState({ SubmitButton: true, NextButton: false })
        // }
        // else
        //     this.setState({ SubmitButton: false, NextButton: true })

        if (a === 1) {
            this.setState({ SubmitButton: true, NextButton: false })
        }
        else
            this.setState({ SubmitButton: false, NextButton: true })
    }

    Frequentquestion = () => {
        // var x = this.myDivToFocus;
        // this.behavior(x);
        this.props.history.push('/PopularQnA')
    }

    behavior = (x) => {
        x.current.scrollIntoView({
            behavior: "smooth",
            block: "nearest"
        })
    }

    SubmitQuestion = async () => {
        debugger;
        // if (sessionStorage.getItem('UserId') === "" || sessionStorage.getItem('UserId') === null || sessionStorage.getItem('UserId') === undefined || sessionStorage.getItem('UserId') === "null") {
        //     Modal.error({ title: 'Login', content: 'Please login to submit your Question' })
        // }
        if (this.state.Category === "" || this.state.Category === null || this.state.Category === undefined) {
            Modal.error({ title: 'Category', content: 'Please select a category' })
        }
        else if (this.state.UserQuestion === "" || this.state.UserQuestion === null || this.state.UserQuestion === undefined) {
            Modal.error({ title: 'Question', content: 'Question cannot be empty' })
        }
        else if (this.state.UserName === "" || this.state.UserName === null || this.state.UserName === undefined) {
            Modal.error({ title: 'Name', content: 'Name cannot br empty' })
        }
        else if (this.state.UserEmail === "" || this.state.UserEmail === null || this.state.UserEmail === undefined) {
            Modal.error({ title: 'Email', content: 'Email cannot be empty' })
        }
        else if (this.state.UserCity === "" || this.state.UserCity === null || this.state.UserCity === undefined) {
            Modal.error({ title: 'City', content: 'City cannot be empty' })
        }
        else if (!(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(this.state.UserEmail))) {
            Modal.warning({ title: 'Email', content: 'Please Enter a Valid Email' });
        }
        else {
            var Added = await AddQuestion(0, this.state.Category, this.state.UserQuestion, this.state.UserName, this.state.UserEmail, this.state.UserCity, this.state.VirtualConsultations);
            if (Added === true) {
                Modal.success({ title: 'Question', content: 'Thank you for writing to us.' })
                this.setState({ Category: '', UserQuestion: '', UserName: '', UserEmail: '', UserCity: '', VirtualConsultations: false })
            }
            else
                Modal.error({ content: "Error submitting question" })
        }
    }

    render() {

        return (
            <div>
                <MetaTags>
                    <title>Questions</title>
                    <meta property="og:url" content="http://www.mywye.com/#/Questions" />
                    <meta name="description" content="Some description." />
                    <meta property="og:title" content="Ask Lawyers Questions - Divorce, Child Custody, Alimony and More" />
                </MetaTags>
                <div class="w-embed w-iframe">
                    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WQNXXP9" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
                </div>
                <Header></Header>
                <div id="Questions">
                    <div id="TopImageCouple" youngmarriedcouple="Young Married Couple" class="questionssection one">
                        <div class="div-block-39">
                            <div class="questions">
                                <div data-animation="slide" data-duration="600" data-infinite="1" class="questionsslider w-slider">
                                    <div class="w-slider-mask">
                                        <Carousel dotPosition="top" swipe ref={this.carouselRef} afterChange={this.currentSLider}>
                                            <div class="slideone w-slide">
                                                <h1 class="headlineubuntu">Ask a Top Professional a Question</h1>
                                                <h3 class="questiontitletop">1. Select a Category</h3>
                                                <div class="w-layout-grid grid-8">
                                                    <div id="w-node-4ace8022d218-ee322428" class="categoryicon" onClick={() => { this.carouselRef.current.next(); this.setState({ Category: 'Family Law' }) }}>
                                                        <div class="professionalcategory">Family Law</div>
                                                    </div>
                                                    <div id="w-node-21456bde274d-ee322428" class="categoryicon" onClick={() => { this.carouselRef.current.next(); this.setState({ Category: 'Insurance Experts' }) }}>
                                                        <div class="professionalcategory" >Insurance</div>
                                                    </div>
                                                    <div id="w-node-cf6bbf8c206d-ee322428" class="categoryicon" onClick={() => { this.carouselRef.current.next(); this.setState({ Category: 'Financial Experts' }) }}>
                                                        <div class="professionalcategory" >Financial</div>
                                                    </div>
                                                    <div id="w-node-b3a97f1545bc-ee322428" class="categoryicon" onClick={() => { this.carouselRef.current.next(); this.setState({ Category: 'Accountants / Tax Planners' }) }}>
                                                        <div class="professionalcategory" >Tax</div>
                                                    </div>
                                                    <div id="w-node-9133be03bf6b-ee322428" class="categoryicon" onClick={() => { this.carouselRef.current.next(); this.setState({ Category: 'Child Counselor' }) }}>
                                                        <div class="professionalcategory" >Children</div>
                                                    </div>
                                                    <div id="w-node-d0b946d2a862-ee322428" class="categoryicon" onClick={() => { this.carouselRef.current.next(); this.setState({ Category: 'Therapist / Counselors' }) }}>
                                                        <div class="professionalcategory" >Counseling</div>
                                                    </div>
                                                    <div id="w-node-764a834d9dc7-ee322428" class="categoryicon" onClick={() => { this.carouselRef.current.next(); this.setState({ Category: 'Mediation' }) }}>
                                                        <div class="professionalcategory" >Mediation</div>
                                                    </div>
                                                    <div id="w-node-02bbe0ef972b-ee322428" class="categoryicon" onClick={() => { this.carouselRef.current.next(); this.setState({ Category: 'Life Coach' }) }}>
                                                        <div class="professionalcategory" >Life Coach</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="slidetwo w-slide">
                                                {/* <div class="div-block-41">
                                                    <div className='textplaceholder'>What is your Question:</div>
                                                    <TextArea style={{ resize: 'none', height: 280, paddingLeft: 25 }} value={this.state.UserQuestion} onChange={(e) => { this.setState({ UserQuestion: e.target.value }) }} className="textArea" />

                                                    <div className='textplaceholder'>Your Name:</div>
                                                    <Input style={{ resize: 'none', height: 275, paddingLeft: 25 }} value={this.state.UserName} onChange={(e) => { this.setState({ UserName: e.target.value }) }} className="textArea overflowHidden" />
                                                    <div className='textplaceholder'>Your email:</div>
                                                    <Input style={{ resize: 'none', height: 275, paddingLeft: 25 }} value={this.state.UserEmail} onChange={(e) => { this.setState({ UserEmail: e.target.value }) }} className="textArea" />

                                                    <div className='textplaceholder'>Your City /State:</div>
                                                    <Input style={{ resize: 'none', height: 280, paddingLeft: 25 }} value={this.state.UserCity} onChange={(e) => { this.setState({ UserCity: e.target.value }) }} className="textArea" />

                                                    <div class="div-block-31-copy">
                                                        <div class="checkbox2"></div>
                                                        <Checkbox value={this.state.VirtualConsultations} onChange={(e) => this.setState({ VirtualConsultations: e.target.checked })} />
                                                        <div class="qualifyer b">I am open to Virtual Consultations <br />(online meetings)</div>
                                                    </div>
                                                </div> */}

                                                <div class="div-block-41">
                                                    <div className='textplaceholder'>What is your Question:</div>
                                                    <TextArea style={{ resize: 'none', height: 280, paddingLeft: 25 }} value={this.state.UserQuestion} onChange={(e) => { this.setState({ UserQuestion: e.target.value }) }} className="textArea qxaText" />

                                                    <div className='textplaceholder'>Your Name:</div>
                                                    <Input className="" style={{ resize: 'none', height: 275, paddingLeft: 25 }} value={this.state.UserName} onChange={(e) => { this.setState({ UserName: e.target.value }) }} className="textArea overflowHidden qxaText" />
                                                    <div className='textplaceholder'>Your email:</div>
                                                    <Input style={{ resize: 'none', height: 275, paddingLeft: 25 }} value={this.state.UserEmail} onChange={(e) => { this.setState({ UserEmail: e.target.value }) }} className="textArea qxaText" />

                                                    <div className='textplaceholder'>Your City /State:</div>
                                                    <Input style={{ resize: 'none', height: 280, paddingLeft: 25 }} value={this.state.UserCity} onChange={(e) => { this.setState({ UserCity: e.target.value }) }} className="textArea qxaText" />

                                                    <div class="div-block-31-copy">
                                                        <div class="checkbox2"></div>
                                                        <Checkbox value={this.state.VirtualConsultations} onChange={(e) => this.setState({ VirtualConsultations: e.target.checked })} />
                                                        <div class="qualifyer b">I am open to Virtual Consultations <br />(online meetings)</div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* <div class="slidethree w-slide">
                                                <div class="div-block-41">
                                                    <div className='textplaceholder'>Your Name:</div>
                                                    <TextArea style={{ resize: 'none', height: 275, paddingLeft: 25 }} value={this.state.UserName} onChange={(e) => { this.setState({ UserName: e.target.value }) }} className="textArea" />
                                                    <div class="qualifyer b">PRIVACY: We will send your question to qualified professionals in the network. Your contact information is not shared until you have seen the answers and decide to open a conversation</div>
                                                </div>
                                            </div>

                                            <div class="slidefour w-slide">
                                                <div class="div-block-41">
                                                    <div className='textplaceholder'>Your email:</div>
                                                    <div class="questionprompt"><span class="text-span-22">CONTACT</span></div>
                                                    <TextArea style={{ resize: 'none', height: 275, paddingLeft: 25 }} value={this.state.UserEmail} onChange={(e) => { this.setState({ UserEmail: e.target.value }) }} className="textArea" />
                                                    <div class="qualifyer b">We will never abuse your contact information. We do not use your information for advertising, we will not sell or share your email unless you specifically request it.</div>
                                                </div>
                                            </div>

                                            <div class="slidefive w-slide">
                                                <div class="div-block-41">
                                                    <div className='textplaceholder'>Your City /State:</div>
                                                    <div class="questionprompt"><span class="text-span-22">CONTACT</span></div>
                                                    <TextArea style={{ resize: 'none', height: 280, paddingLeft: 25 }} value={this.state.UserCity} onChange={(e) => { this.setState({ UserCity: e.target.value }) }} className="textArea" />
                                                    <div class="div-block-31-copy">
                                                        <div class="checkbox2"></div>
                                                        <Checkbox value={this.state.VirtualConsultations} onChange={(e) => this.setState({ VirtualConsultations: e.target.checked })} />
                                                        <div class="qualifyer b">I am open to Virtual Consultations <br />(online meetings)</div>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </Carousel>
                                        <div class="backbutton w-slider-arrow-left" onClick={this.slidePrevChanger}>
                                            <div class="icon-4 w-icon-slider-left"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {this.state.NextButton &&
                                <div class="nextbutton w-slider-arrow-right">
                                    <h1 class="buttontitle" onClick={this.slideNextChanger}>Next</h1>
                                </div>
                            }
                            {this.state.SubmitButton &&
                                <div class="nextbutton w-slider-arrow-right">
                                    <h1 class="buttontitle" onClick={this.SubmitQuestion}>Submit</h1>
                                </div>
                            }
                            {/* <a href="#" onClick={this.Frequentquestion} class="link-block-5 w-inline-block">
                                <div class="form-note">See top questions others have asked</div>
                            </a> */}
                        </div>
                    </div>
                </div >
                <div class="questionspromo" ref={this.myDivToFocus}>
                    <TopQuestions />
                </div>
                <BoxComponent></BoxComponent>
                <Footer></Footer>
            </div >
        )
    }
}

export default question