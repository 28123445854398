import React from 'react';
import Dashboard from '../Dashboard/Dashboard'
import Footer from '../Footer/Footer'
import '../Css/FAQ.css';
import BoxComponent from '../BoxComponent/BoxComponent';
import { ServiceUrl, FilesURL } from '../ServiceURL';
import ReactGA from 'react-ga';
import { MetaTags } from 'react-meta-tags';


class FAQ extends React.Component {
    constructor(props) {
        super(props);
        this.state = { MarriageTest: false, Questions: false, Reviews: false }
    }

    componentDidMount() {

        const script = document.createElement("script");
        //script.src = "http://192.227.67.200/Peachylife_/static/js/plmarriagetest3-944b06bbb-864f41afbdea5.js";
        script.src = FilesURL + "/Peachylife_/static/js/plmarriagetest3-944b06bbb-864f41afbdea5.js";
        script.async = true;

        ReactGA.initialize('G-JT4HS6ZZSX');
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.gtag('event', 'conversion', { 'send_to': 'AW-10806936548/AfZeCJKY9YQDEOSHk6Eo' });

        window.scrollTo({ top: 0 })
    }

    MarriageTestClick = () => {
        // this.props.history.push('/MarriageTest')
        this.setState({ MarriageTest: true })
    }

    ReviewsClick = () => {
        // this.props.history.push('/MarriageTest')
        this.setState({ Reviews: true })
    }

    QuestionsClick = () => {
        // this.props.history.push('/Questions')
        this.setState({ Questions: true })
    }

    TermsClick = () => {
        this.props.history.push('/Terms')
    }

    render() {
        return (
            <div>
                <MetaTags>
                    <title>FAQ</title>
                </MetaTags>
                <div class="w-embed w-iframe">
                    {/* <!--  Google Tag Manager (noscript)  --> */}
                    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WQNXXP9" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
                    {/* <!--  End Google Tag Manager (noscript)  --> */}
                </div>

                <Dashboard />

                <div id="TopImageCouple" youngmarriedcouple="Young Married Couple" class="page-section">
                    <div class="headerblock">
                        <div class="headingtitleblock">
                            <h1 class="headline">Support: Frequently Asked Questions</h1>
                            <div class="category-name">UPDATED: Jan 2021</div>
                            <a href="#" class="link-block-7 w-inline-block">
                                <div class="subnavigation" onClick={this.TermsClick}>Privacy Policy<br /></div>
                            </a>
                            <a href="#" class="link-block-6 w-inline-block">
                                <div class="subnavigation" onClick={this.TermsClick}>Terms of Use<br /></div>
                            </a>
                        </div>
                    </div>
                    <div class="article-body">
                        <div class="div-block-43">
                            <div class="highlightblock">
                                <div class="iconblock c"></div>
                                <div>Questions you ask go anonymously to selected professionals. Only you see the responses. You choose when to get in touch.</div>
                            </div>
                            <div class="highlightblock">
                                <div class="iconblock b"></div>
                                <div>We use cookies to make the site experience better. We care about your data and protect your privacy.</div>
                            </div>
                            <div class="highlightblock">
                                <div class="iconblock"></div>
                                <div>We do not share your information, unless you request it. We are not collecting data for advertising.</div>
                            </div>
                        </div>
                        <div class="div-block-60">
                            <div data-duration-in="300" data-duration-out="100" class="tabs w-tabs">
                                <div class="tabs-menu-2 w-tab-menu">
                                    <a data-w-tab="Tab 1" class="tab-link-tab-1-2 w-inline-block w-tab-link w--current">
                                        <div class="subnavigation" onClick={this.QuestionsClick}>About Questions </div>
                                    </a>
                                </div>

                                <div class="tabs-content-2 w-tab-content">
                                    <div data-w-tab="Tab 1" class="w-tab-pane w--tab-active">
                                        <div class="faq-question"><strong>Who gets to read my questions?</strong>  <br />Questions you ask are forwarded to top professionals who have registered on this site. The question does not include your contact information. A notice with a link is sent to your email address. When you click on the link, it takes you to the responses. Only you see the responses. You choose when to get in touch.<br /><br />We may publish summaries of some popular questions. They will not include any names, places or indications referring to anything personal.</div>
                                        <div class="faq-question"><strong>When I get an answer, do I have to respond? </strong><br />There is no obligation to respond to answers to your questions. This is a free service being offered by top professionals. </div>
                                        <div class="faq-question"><strong>Are answers to questions sometimes recorded as videos?</strong><br />It is entirely the option of the professional answering questions if they wish to write a response or record it as a video segment.</div>
                                        <div class="faq-question"><strong>Are answers legally advice?</strong><br />An agreement to represent is required to have a professional relationship established. Professionals are answering questions as if they are hypothetical. Without full details and background the questions may be missing critical facts, history, context and may omit substantially important factors. PROFESSIONAL ADVICE SHOULD ALWAYS BE TAKEN WITH FULL CONSULTATION AND AGREEMENT. We can help you to meet highly qualified, experienced professionals and to establish enduring relationships that will deliver informed advice. </div>
                                    </div>
                                </div>
                            </div>
                            <div data-duration-in="300" data-duration-out="100" class="tabs w-tabs">
                                <div class="tabs-menu-2 w-tab-menu">
                                    <a data-w-tab="Tab 1" class="tab-link-tab-1-2 w-inline-block ">
                                        <div class="subnavigation" onClick={this.ReviewsClick}><strong>Reviews. </strong><span class="text-span-33">(click to open)</span></div>
                                    </a>
                                </div>
                                {this.state.Reviews &&
                                    <div class="tabs-content-2 w-tab-content"> {/* class="tabs-content-2 w-tab-content" data-w-tab="Tab 1" class="w-tab-pane" */}
                                        <div >
                                            <div class="faq-question"><strong>Who writes reviews? </strong><br />Reviews are written by clients and professional industry peers. Only registered users of the site are able to write reviews.</div>
                                            <div class="faq-question"><strong>Can I change my review<br />‍</strong>You can always revise a review you have written</div>
                                            <div class="faq-question"><strong>I have read a review and wish to contact the person who wrote it</strong><br />We do not publish contact information for people who write reviews on our site. Only information that is on the site such as the user&#x27;s is available. </div>
                                            <div class="faq-question"><strong>If I write a review, who gets to see it ?</strong><br />Reviews are public information. Please be accurate and considerate in your reviews. We recommend writing only about your experience and opinion, and strongly advise that anything your write be truthful, accurate and factual. Peachy is not responsible for reviews written by third parties. We will never edit a review. We do however reserve the right to disqualify or delete any review that does not meet our guidelines.</div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div data-duration-in="300" data-duration-out="100" class="tabs w-tabs">
                                <div class="tabs-menu-2 w-tab-menu"> {/* data-w-tab="Tab 2" class="w-tab-pane" */}
                                    <a data-w-tab="Tab 2" class="tab-link-tab-1-2 w-inline-block ">
                                        <div class="subnavigation" onClick={this.MarriageTestClick}><strong>Marriage Test. </strong><span class="text-span-33">(click to open)</span></div>
                                    </a>
                                </div>
                                {this.state.MarriageTest &&
                                    <div class="tabs-content-2 w-tab-content">
                                        <div >
                                            <div class="faq-question">
                                                <strong>I got a terrible score on the marriage test? </strong><br />
                                                Everyone struggles in relationships from time to time. You may be being hard on yourself. There is hope that if you find the right professional help, you may be able to stage a comeback. There are resources to help and peer-reviewed network of professionals at:
                                                <a href={FilesURL} target='_blank'>www.mywye.com</a>
                                            </div>
                                            <div class="faq-question"><strong><br />I started the Marriage test, but didn&#x27;t finish, how do I get back to where I was?</strong><br />If you finished a section, you can probably get the answers to that section again..<br /><br />Step ONE: try going back to the test to see if your answers have been saved. We use cookies to remember who you are, if the answers are there- continue and be sure to register at the end of each section. If not, you can start that section again.
                                            <a href={FilesURL + "/#/MarriageTest"} target='blank'>test.mywye.com </a><br />
                                            Step TWO: During the process you were prompted to save your responses, which means your responses may be accessible. If you entered your email address, please contact us and we may be able to help.
                                            <a href="mailto:contact@peachylife.com?subject = Contact Peachy Life&body = Hi">mail to:contact@peachylife.com</a><br />‍</div>
                                            <div class="faq-question"><strong>Can I share my test results?</strong><br />You can easily share your results. <br />1. Social Media - the share links are on your results page- at the bottom.<br />2. Send your results to a friend/ your spouse: Goto your account on PeachyLife.com and login to your account. Click on Marriage Test results link, </div><img src="images/MarriageTestResults.png" loading="lazy" sizes="(max-width: 479px) 26vw, (max-width: 767px) 21vw, 22vw" srcset="images/MarriageTestResults.png 500w, images/MarriageTestResults.png 800w, images/MarriageTestResults.png 1014w" alt="" class="image-22" />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <BoxComponent />
                <Footer />
            </div>

        )
    }
}
export default FAQ