import { Pagename, Advicecategory, ForgetPwdemail } from './Redux.Type'

const initialstate = {
    Value: '', Category: '', ForgetEmail: ''
}

const ReduxReducer = (state = initialstate, action) => {
    switch (action.type) {
        case Pagename: return {
            ...state,
            Value: action.Value
        }
        case Advicecategory: return {
            ...state,
            Category: action.Category
        }
        default: return state
    }
}

export default ReduxReducer