import { ServiceUrl } from '../ServiceURL';

export async function LoginUser(Username, Password) {
    let GetUserField = [];
    const data = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ Email: Username, Password: Password, PageType: 'PeachyLife' })
    };
    await fetch(ServiceUrl + '/api/LoginUser', data) //http://192.227.67.200/peachylifeapi/api/LoginUser
        .then((response) => response.json()) //https://peachylife.com/peachylifeapi/api/
        .then((responseJson) => {
            GetUserField = responseJson;
        })
    return GetUserField;
}

export async function GetMenuDetails() {
    let GetUserField = [];
    const data = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };
    await fetch(ServiceUrl + '/api/GetMenuDetails', data) //http://192.227.67.200/peachylifeapi/api/GetMenuDetails
        .then((response) => response.json()) //https://peachylife.com/peachylifeapi/api/
        .then((responseJson) => {
            GetUserField = responseJson;
        })
    return GetUserField;
}

export async function CheckMarriageTest() {
    debugger;
    let GetUserField = [];
    var tempUserId = sessionStorage.getItem('UserId');
    const userID = parseInt(tempUserId);
    const data = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ UserId: userID })
    };
    await fetch(ServiceUrl + '/api/CheckMarriageTest', data) //http://192.227.67.200/peachylifeapi/api/LoginUser
        .then((response) => response.json()) //https://peachylife.com/peachylifeapi/api/
        .then((responseJson) => {
            GetUserField = responseJson;
        })
    return GetUserField;
}

export async function ForgotLogin(email) {
    let GetUserField = [];
    const data = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ Email: email })
    };
    await fetch(ServiceUrl + '/api/ForgotLogin', data) //http://192.227.67.200/peachylifeapi/api/LoginUser
        .then((response) => response.json()) //https://peachylife.com/peachylifeapi/api/
        .then((responseJson) => {
            GetUserField = responseJson;
        })
    return GetUserField;
}