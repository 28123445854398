import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Modal, Spin, Collapse, Row, Col } from 'antd';
import { PageName, AdviceCategory, PageLoader } from '../../Redux/Redux.Action';
import 'antd/dist/antd.css';
import BoxComponent from '../BoxComponent/BoxComponent';
import { CheckMarriageTest } from '../BoxComponent/BoxComponentService';
import Footer from '../Footer/Footer';
import Header from '../Dashboard/Dashboard';
import TopQuestions from '../TopQuestions/TopQuestions';
import { ServiceUrl, FilesURL } from '../ServiceURL';
import { GetMenuDetails } from '../Home/HomeService';
import { GetAnswered } from '../Question/questionService';

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = { componentvalue: [{ component: '' }], CategoriesList: [], CatQuestionsList: [], }
    }

    async componentDidMount() {
        import('../Css/components.css')
        import('../Css/normalize.css')
        import('../Css/plmarriagetest.css')

        const script = document.createElement("script");
        //script.src = "http://192.227.67.200/Peachylife_/static/js/plmarriagetest3-944b06bbb-864f41afbdea5.js";
        script.src = FilesURL + "/Peachylife_/static/js/plmarriagetest3-944b06bbb-864f41afbdea5.js";
        script.async = true;
        document.body.appendChild(script);
        window.scrollTo({ top: 0 })

        let categories = await GetMenuDetails();
        let catQuestionsList = await GetAnswered("Family Law");
        this.setState({ CategoriesList: categories.adviceMenus, CatQuestionsList: catQuestionsList.question })

        if ((parseInt(sessionStorage.getItem("LoadPage")) + 1) === 1) {
            sessionStorage.setItem("LoadPage", 1)
            window.location.reload(true)
        }
        this.props.PageName("");
    }

    questionclick = () => {
        this.props.history.push('/Questions');
    }

    RedirectToAllProfiles = () => {
        this.props.history.push('AllProfiles');
    }

    ContentLoad(data) {
        let comvalue = this.state.componentvalue
        comvalue[0].component = data.component;
        this.setState({
            componentvalue: comvalue,
        })
    }

    //Function to fetch all the questions and answers for specific category!
    getCatQuesList = async (category) => {
        let catQuestionsList = await GetAnswered(category);
        this.setState({ CatQuestionsList: catQuestionsList.question })
    }

    showtest = async () => {
        if ((sessionStorage.getItem('UserId') !== "") && (sessionStorage.getItem('UserId') !== null) && (sessionStorage.getItem('UserId') !== "null")) {
            let exist = await CheckMarriageTest()
            if (exist === true) {
                Modal.confirm({
                    content: (<div><p>You have already taken a Test.</p><p style={{ marginLeft: 40 }}> Do you wish to take a Re-Test?</p></div>),
                    okText: "Take Test",
                    cancelText: "View Results",
                    onOk: this.RedirectToTest,
                    onCancel: this.showReport
                });
            }
            else {
                this.RedirectToTest();
            }
        }
        else {
            this.props.history.push('/MarriageTest')
        }
    }
    showReport = () => {
        this.props.history.push('MarriageResult')
    }
    RedirectToTest = () => { this.props.history.push('MarriageTest') }

    render() {

        const SEOJson = {
            "@context": "https://schema.org",
            "@type": "Informative",
            "Title": "Professional Advice, Resources and Community for Couples",
            "Publisher": "Peachy Life",
            "Genre": "instructional",
            "description": "Connect with top peer-reviewed specialists through video profiles. Content and resources for couples; legal, financial, counselling, therapy and guidance. Get the answers you need.",
            "interactionStatistic": {
                "@type": "InteractionCounter",
                "interactionType": { "@type": "http://schema.org/WatchAction" },
                "userInteractionCount": 5647018
            },
        }

        const { Panel } = Collapse;

        return (
            <div>
                <meta property="og:url" content="http://www.mywye.com" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="MyWye - Divorce, Child Custody Lawyers in your area, Questions and Answers, Marriage Counseling and More" />
                <meta property="og:description" content="MyWye is a resource for anyone contemplating a divorce or going through a divorce.  Our network connects with you with all the resources you need in order to protect yourself in the short and long term - including divorce lawyers, marriage counseling, financial experts, mediation and much more" />
                <meta property="og:image" content="http://mywye.com/images/PeachyLife-HomepageScreen1.png   (important that the file attached is in this location)" />

                <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(SEOJson) }} />

                <div class="w-embed w-iframe">
                    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WQNXXP9" height="0" width="0" style={{ display: "none", visibility: "hidden" }}></iframe></noscript>
                    <script src={FilesURL + "/Peachylife_/static/js/plmarriagetest3-944b06bbb-864f41afbdea5.js"} crossOrigin="anonymous"></script>
                </div>
                <Header></Header>
                <div class="introsection">
                    <div class="homeslide w-slider"> {/* data-delay="3000" data-animation="cross" data-autoplay="1" data-duration="500" data-infinite="1"  */}
                        <div class="mask-2 w-slider-mask">
                            <div class="homeslide3 w-slide">
                                {/* <div class="slide3copy cursor" onClick={this.showtest}>
                                    <h3 class="slidetext3">Take the Marriage Test</h3>
                                    <a data-w-id="fb897bc8-a8ef-cc4b-a711-a1a77e573b03" href="#" class="div-block-32 w-inline-block logo"></a>
                                    <a href="#" target="_blank" class="slide3cta w-button" onClick={this.showtest}>30 QUESTIONS<br />INSTANT FEEDBACK</a>
                                </div> */}
                                {/* <div class="slide3copy slide3copy2 cursor" onClick={this.showtest}> */}
                                {/* <h3 class="slidetext slidetextcopy2">How's your relationship?</h3> */}
                                {/* <a href="#" class="outlinefirstbuttons w-button customBtnHomeCarousel" onClick={this.showtest}>TAKE THE MARRIAGE TEST</a> */}
                            </div>
                            <div class="slidebase3"></div>
                            {/* </div> */}
                            <div class="home-slide1 w-slide" onClick={this.RedirectToAllProfiles}>
                                {/* <div class="whitetranssquare a">
                                    <h3 class="slidetext">Connect with the best and brightest <br />peer-reviewed professionals</h3>
                                    <a href="#" class="outlinefirstbuttons w-button" onClick={this.RedirectToAllProfiles}>Find a Professional</a>
                                </div> */}
                                <div className="homeSlide2Div">
                                    <span className="homeSlide2Div1">
                                        An unexpected or expected death in the family can strain you emotionally and financially. It is important you consult with the proper professionals to protect yourself and your family. We can help you today!
                                    </span>
                                    <br />
                                    <br />
                                    <span className="homeSlide2Div2">
                                        Connect with Top professionals in your area today.
                                    </span> <br /> <br />
                                    <span className="homeSlide2Div3">
                                        Trust Attorneys <br />
                                        Financial Planners <br />
                                        Estate Planners <br />
                                    </span>
                                    <br />
                                    <a href="#" class="outlinefirstbuttons w-button customBtnHomeCarousel2" onClick={this.RedirectToAllProfiles}>Find a Professional</a>
                                </div>
                                <div data-w-id="8b4284eb-d78c-0928-253f-e15d5eb96a21" class="slidebase"></div>
                            </div>
                            <div class="homeslide2 w-slide">
                                {/* <div class="whitetranssquare2">
                                    <h3 class="slidetext-copy _2">Do you know the best points to consider in choosing an advisor?</h3>
                                    <a href="#" class="outlinefirstbuttons w-button" onClick={this.RedirectToAllProfiles}>Find a Professional</a>
                                </div> */}
                                {/* <div className="homeSlide3Div">
                                    <span className="homeSlide3Div2">
                                        Find professionals in your Area!
                                    </span> <br /> <br />
                                    <span className="homeSlide3Div1">
                                        our teams of professionals can assist you in every aspects of your divorce or other legal matters.
                                    </span>
                                    <br /><br />
                                    <span className="homeSlide3Div3">CONNECT WITH TOP</span>
                                    <br /><br />
                                    <span className="homeSlide3Div4">
                                        Family Law Attorneys <br />
                                        Financial Planners <br />
                                        Estate Planners <br />
                                        Marriage Therapist <br />
                                        Trust Lawers <br />
                                        And More <br />
                                    </span>
                                    <br />
                                    <a href="#" class="outlinefirstbuttons w-button customBtnHomeCarousel" onClick={this.RedirectToAllProfiles}>Find a Professional</a>
                                </div> */}
                                <div data-w-id="054a4b9e-7b16-097c-ebba-7f37d966e76e" class="slidebase-2" onClick={this.RedirectToAllProfiles}></div>
                            </div>
                            <div class="homeslide4 w-slide">
                                {/* <div class="whitetranssquar4">
                                    <h3 class="slidetext4">Is your property safe ?<br />well protected ?<br />Are your assets growing?</h3>
                                    <a href="#" class="outlinefirstbuttons w-button" onClick={this.questionclick}>GET ANSWERS</a>
                                </div> */}
                                {/* <div className="homeSlide4Div">
                                    <span className="homeSlide4Div2">
                                        Learn! Videos, Questions / Answers, Articles
                                    </span> <br /> <br />
                                    <span className="homeSlide4Div1">
                                        Our teams of professionals can assist you in every aspects of your divorce or other legal matters.
                                    </span>
                                    <div className="homeSlide4Div3">
                                        <Row gutter={[16, 0]}>
                                            <Col span={8}>
                                                <div className="homeSlide4Capsule">
                                                    <span className="CapsuleHead1">
                                                        Videos From Top Professionals
                                                    </span>
                                                    <div className="CapsuleHead2">
                                                        The videos cover a wide range of topics from divorce, to child custody, marriage counseling, estate planning and much more!
                                                    </div>
                                                    <div className="CapsuleLearnMore">
                                                        LEARN MORE
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col span={8}>
                                                <div className="homeSlide4Capsule">
                                                    <span className="CapsuleHead1">
                                                        Common Questions and Answers
                                                    </span>
                                                    <div className="CapsuleHead2">
                                                        Question asked by you and answered by our team of professionals in your area. Ask your own question or read previously submitted questions.
                                                    </div>
                                                    <div className="CapsuleLearnMore">
                                                        LEARN MORE
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col span={8}>
                                                <div className="homeSlide4Capsule">
                                                    <span className="CapsuleHead1">
                                                        Articles on everything you need to know
                                                    </span>
                                                    <div className="CapsuleHead2">
                                                        The articles cover a wide range of topics from divorce, to child custody, marriage counseling, estate planning and much more!
                                                    </div>
                                                    <div className="CapsuleLearnMore">
                                                        LEARN MORE
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div> */}
                                <div class="slidebase4">
                                    <div className="slidebase4Left">
                                        <div className="slidebase4Text">
                                            <div className="slide4TextHeading">
                                                Learn! Videos, Questions / Answers, Articles
                                            </div>
                                            <div className="slide4TextSubHeading">
                                                Our teams of professionals can assist you in every aspects of your divorce or other legal matters.
                                        </div>
                                        </div>
                                        <div>
                                            <Row gutter={[24, 0]}>
                                                <Col span={8}>
                                                    <img className="Capsule Capimg1" src="/images/Home4-1.jpg" />
                                                </Col>
                                                <Col span={8}>
                                                    <img className="Capsule Capimg2" src="/images/Home4-2.jpg" />
                                                </Col>
                                                <Col span={8}>
                                                    <img className="Capsule Capimg3" src="/images/Home4-3.jpg" />
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="left-arrow w-slider-arrow-left">
                            <div class="w-icon-slider-left"></div>
                        </div>
                        <div class="right-arrow w-slider-arrow-right">
                            <div class="w-icon-slider-right"></div>
                        </div>
                        <div class="slide-nav w-slider-nav w-round"></div>
                    </div>
                </div>
                <div class="headlinesection">
                    {/* <h1 class="peachyheadline margin-left">Professional advice, resources and community all to help you make your life <span className="cardcontainer" style={{ color: "#eb616c" }}>Peachy...</span></h1> */}
                    <h1 class="peachyheadline margin-left">Professional advice, resources and community by <span className="cardcontainer" style={{ color: "#eb616c" }}>MyWye...</span></h1>
                </div>

                <div>
                    <h1 class="peachyheadline">Questions sorted in categories</h1>
                    <div class="article-body popularCategories">
                        <div class="w-layout-grid categoriesselector articlescategory">
                            {this.state.CategoriesList.map((data) => {
                                return <a href="#" class="category w-inline-block" onClick={() => this.getCatQuesList(data.menuName)}>
                                    <div class="text-block-68">{data.category}<br /></div>
                                </a>
                            })}
                        </div>
                    </div>
                </div>
                <div class="headlinesection">
                    <div className="FAQCollapse">
                        <Collapse accordion bordered={false} className="site-collapse-custom-collapse qandaCollapse">
                            {this.state.CatQuestionsList.length !== 0 ? this.state.CatQuestionsList.map((data, index) => {
                                return <Panel header={data.question} key={index}>
                                    <p className="ansCollapse">{data.answer}</p>
                                    <a className="ansCollapse" href={FilesURL + "/#/" + data.userName} target="_blank"> Answered by - {data.expertFirstName} {data.expertLastName}</a>
                                </Panel>
                            }) : <span className="descline">No Q & A Available</span>}
                        </Collapse>
                    </div>
                </div>

                <BoxComponent></BoxComponent>
                <div class="featues-section">
                    {/* <div class="bookomonth">
                        <div class="bookimage"></div>
                        <div class="div-block-35">
                            <h3 class="subtitle">Book of the Month</h3>
                            <p class="product-description">Is it possible to write a sidesplitting novel about the breakup of the perfect marriage? If the writer is Nora Ephron, the answer is a resounding yes. For in this inspired confection of adultery, revenge, group therapy, and pot roast, the creator of Sleepless in Seattle reminds us...<br />‍<br /> <span class="text-span-21">GET THIS</span></p>
                        </div>
                    </div> */}
                    <div class="questionspromo">
                        <div class="section2">
                            <TopQuestions />
                        </div>
                    </div>
                    <div data-delay="4000" data-animation="cross" data-autoplay="1" data-easing="ease-in-quad" data-duration="500" data-infinite="1" class="bottomslider w-slider">
                        <div class="mask-3 w-slider-mask container">
                            <div class="bottomslidemaster w-slide">
                                <h1 class="peachyheadline a">What makes a great marriage ?<br />How do you keep it working ?<br />Get straight talk and real answers...</h1>
                            </div>
                            <div class="bottomslidemaster w-slide">
                                <h1 class="peachyheadline"><br />What to do if you are stuck in a<br />marriage that is not working ?</h1>
                            </div>
                            <div class="bottomslidemaster w-slide">
                                <h1 class="peachyheadline">If you are struggling in a marriage, there are resources, community and professionals to help right here.</h1>
                            </div>
                        </div>
                        <div class="left-arrow-3 w-slider-arrow-left">
                            <div class="w-icon-slider-left"></div>
                        </div>
                        <div class="right-arrow-3 w-slider-arrow-right">
                            <div class="w-icon-slider-right"></div>
                        </div>
                        <div class="slide-nav-2 w-slider-nav w-round"></div>
                    </div>
                </div>
                <Footer></Footer>
            </div >
        )
    }
}

const mapStateToProp = state => {
    return {
        Value: state.Value,
        Category: state.Category
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        PageName: Value => dispatch(PageName(Value)),
        AdviceCategory: Category => dispatch(AdviceCategory(Category))
    }
}

export default compose(withRouter, connect(mapStateToProp, mapDispatchToProps))(Home);