import React, { Component } from 'react';
import { Radio, Input, Button, Modal, Card, Spin } from 'antd';
import MetaTags from 'react-meta-tags';
import { SettingOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
import { connect } from 'react-redux';
import { PageName, AdviceCategory } from '../../Redux/Redux.Action';
import { AddMarriageAnswer, GetMarriageAnswer } from './MarriageService.js';
import { LoginUser } from '../Home/HomeService';
import { RegisterUser } from '../Register/RegisterService';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './MarriageTest.css'
import Footer from '../Footer/Footer'
import ReactGA from 'react-ga';


export class MarriageTest extends Component {
    constructor() {
        super()
        this.state = {
            Tab: "1", fields: {}, answerJson: [], className: "home-head one", Part: "THE MARRIAGE TEST", SpinVisible: false,
            quotes: "Where is your marriage headed?", email: '', password: '', footer: 'You are making great progress.\n Please save your answers'
        }

        this.myDivToFocus = React.createRef();
        this.myDiv2ToFocus = React.createRef();
        this.myDiv3ToFocus = React.createRef();
        this.myDiv4ToFocus = React.createRef();
        this.myDiv5ToFocus = React.createRef();
        this.myDiv6ToFocus = React.createRef();
        this.myDiv7ToFocus = React.createRef();
        this.myDiv8ToFocus = React.createRef();
        this.myDiv9ToFocus = React.createRef();
        this.myDiv10ToFocus = React.createRef();
        this.myDiv11ToFocus = React.createRef();
        AOS.init({ duration: 800 });
        this.ShowReport = this.ShowReport.bind(this);
    }

    async componentDidMount() {
        require('./MarriageTest.css')
        require('../MarriageCss/normalize.css')
        require('../MarriageCss/webflow.css')
        require('../MarriageCss/plmarriagetest3-944b06bbb-3aff3ee893f30.webflow.css')

        ReactGA.initialize('G-JT4HS6ZZSX');
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.gtag('event', 'conversion', { 'send_to': 'AW-10806936548/AfZeCJKY9YQDEOSHk6Eo' });

        window.scrollTo({ top: 0 })
        this.props.PageName("")
    }

    ShowReport() {
        this.props.history.push('/MarriageResult')
    }

    Termsclick = () => {
        this.props.history.push('/Terms')
    }

    HomeClick = () => {
        this.props.history.push('/Home')
    }

    questionclick = () => {
        this.props.history.push('/Questions')
    }

    inputchange = (e) => {
        if (e.target.name === "email")
            this.setState({ email: e.target.value })
        else if (e.target.name === "password")
            this.setState({ password: e.target.value })
    }

    handlechange = (evt) => {
        if (evt.target.name === "p1q1" || evt.target.name === "p2q1" || evt.target.name === "p3q1") {
            if (this.myDiv2ToFocus.current) {
                var x = this.myDiv2ToFocus;
                this.behavior(x);
            }
        }
        else if (evt.target.name === "p1q2" || evt.target.name === "p2q2" || evt.target.name === "p3q2") {
            if (this.myDiv3ToFocus.current) {
                var x = this.myDiv3ToFocus;
                this.behavior(x);
            }
        }
        else if (evt.target.name === "p1q3" || evt.target.name === "p2q3" || evt.target.name === "p3q3") {
            if (this.myDiv4ToFocus.current) {
                var x = this.myDiv4ToFocus;
                this.behavior(x);
            }
        }
        else if (evt.target.name === "p1q4" || evt.target.name === "p2q4" || evt.target.name === "p3q4") {
            if (this.myDiv5ToFocus.current) {
                var x = this.myDiv5ToFocus;
                this.behavior(x);
            }
        }
        else if (evt.target.name === "p1q5" || evt.target.name === "p2q5" || evt.target.name === "p3q5") {
            if (this.myDiv6ToFocus.current) {
                var x = this.myDiv6ToFocus;
                this.behavior(x);
            }
        }
        else if (evt.target.name === "p1q6" || evt.target.name === "p2q6" || evt.target.name === "p3q6") {
            if (this.myDiv7ToFocus.current) {
                var x = this.myDiv7ToFocus;
                this.behavior(x);
            }
        }
        else if (evt.target.name === "p1q7" || evt.target.name === "p2q7" || evt.target.name === "p3q7") {
            if (this.myDiv8ToFocus.current) {
                var x = this.myDiv8ToFocus;
                this.behavior(x);
            }
        }
        else if (evt.target.name === "p1q8" || evt.target.name === "p2q8" || evt.target.name === "p3q8") {
            if (this.myDiv9ToFocus.current) {
                var x = this.myDiv9ToFocus;
                this.behavior(x);
            }
        }
        else if (evt.target.name === "p1q9" || evt.target.name === "p2q9" || evt.target.name === "p3q9") {
            if (this.myDiv10ToFocus.current) {
                var x = this.myDiv10ToFocus;
                this.behavior(x);
            }
        }
        else if (evt.target.name === "p1q10" || evt.target.name === "p2q10" || evt.target.name === "p3q10") {
            if (this.myDiv11ToFocus.current) {
                var x = this.myDiv11ToFocus;
                this.behavior(x);
            }
        }
        var fields = this.state.fields;
        fields[evt.target.name] = evt.target.value;
        this.setState({ fields })
    }

    BeginTest = () => {
        if (this.myDivToFocus.current) {
            var x = this.myDivToFocus;
            this.behavior(x);
        }
    }

    behavior = (x) => {
        x.current.scrollIntoView({
            behavior: "smooth",
            block: "nearest"
        })
    }

    SaveAnswers = async () => {
        debugger;
        this.setState({ SpinVisible: true })
        var Test = document.getElementsByClassName('ant-radio-input');
        var selectedGender = [];
        for (var i = 0; i < Test.length; i++) {
            if (Test[i].checked) {
                selectedGender.push({
                    'name': Test[i].name,
                    'answers': Test[i].labels[0].outerText,
                    'value': Test[i].value,
                })
            }
        }
        if (selectedGender.length < 10) {
            Modal.warning({
                content: 'Please answer all the questions',
            });
        }
        else if (selectedGender.length === 10) {
            var answer = JSON.stringify(selectedGender);
            if (sessionStorage.getItem('Success') != "true") {
                if (this.state.email === "" || this.state.password === "") {
                    Modal.warning({
                        content: 'Please enter your Username and Password',
                    });
                }
                else {
                    var loginData = await RegisterUser(this.state.email, this.state.email, this.state.password);
                    if (loginData.userID != "") {
                        var returnedData = await LoginUser(this.state.email, this.state.password);
                        this.storeSession(returnedData);
                    }
                    else {
                        var returnedData = await LoginUser(this.state.email, this.state.password);
                        this.storeSession(returnedData);
                    }
                    await AddMarriageAnswer(answer)
                    if (this.state.Tab === "1") {
                        await AddMarriageAnswer(answer, this.state.Tab)
                        this.setState({ Tab: "2", className: "home-head _2", Part: "PART TWO", quotes: "The Marriage Test", fields: {} })
                        // this.GetAnswer("2")
                        window.scrollTo({ top: 0 })
                    }
                    else if (this.state.Tab === "2") {
                        await AddMarriageAnswer(answer, this.state.Tab)
                        this.setState({ Tab: "3", className: "home-head _4", Part: "PART THREE", quotes: "The Marriage Test", fields: {}, footer: 'Congratulations on completing The Marriage Test.\n We will compile your results and send you a link' })
                        // this.GetAnswer("3")
                        window.scrollTo({ top: 0 })
                    }
                    else if (this.state.Tab === "3") {
                        await AddMarriageAnswer(answer, this.state.Tab)
                        Modal.success({
                            content: 'Your test answers have been saved successfully',
                            onOk: this.ShowReport
                        });
                    }
                }
            }
            else {
                await AddMarriageAnswer(answer)
                if (this.state.Tab === "1") {
                    await AddMarriageAnswer(answer, this.state.Tab)
                    this.setState({ Tab: "2", className: "home-head _2", Part: "PART TWO", quotes: "The Marriage Test", fields: {} })
                    // this.GetAnswer("2");
                    window.scrollTo({ top: 0 })
                }
                else if (this.state.Tab === "2") {
                    await AddMarriageAnswer(answer, this.state.Tab)
                    this.setState({ Tab: "3", className: "home-head _4", Part: "PART THREE", quotes: "The Marriage Test", fields: {}, footer: 'Congratulations on completing The Marriage Test.\n We will compile your results and send you a link' })
                    // this.GetAnswer("3");
                    window.scrollTo({ top: 0 })
                }
                else if (this.state.Tab === "3") {
                    await AddMarriageAnswer(answer, this.state.Tab);
                    Modal.success({
                        content: 'Your test answers have been saved successfully',
                        onOk: this.ShowReport
                    });
                }
            }
        }
        this.setState({ SpinVisible: false })
    }

    storeSession = (returnedData) => {
        sessionStorage.setItem('UserId', returnedData.userID);
        sessionStorage.setItem('UserName', returnedData.userName);
        sessionStorage.setItem('Success', returnedData.success);
    }

    PageClick = (e) => {
        debugger;
        if (e === "1") {
            if (sessionStorage.getItem('UserId') != null) {
                //this.GetAnswer(e)
            }
            else {
                this.setState({ fields: {} })
            }
            this.setState({ Tab: "1", className: "home-head one", Part: "THE MARRIAGE TEST", quotes: "Where is your marriage headed?", footer: 'You are making great progress.\n Please save your answers' })
        }
        else if (e === "2") {
            if (sessionStorage.getItem('UserId') != null) {
                //this.GetAnswer(e)
            }
            else {
                this.setState({ fields: {} })
            }
            this.setState({ Tab: "2", className: "home-head _2", Part: "PART TWO", quotes: "The Marriage Test", footer: 'You are making great progress.\n Please save your answers' })
        }
        else {
            if (sessionStorage.getItem('UserId') != null) {
                //this.GetAnswer(e)
            }
            else {
                this.setState({ fields: {} })
            }
            this.setState({ Tab: "3", className: "home-head _4", Part: "PART THREE", quotes: "The Marriage Test", footer: 'Congratulations on completing The Marriage Test.\n We will compile your results and send you a link' })
        }
    }

    render() {

        window.onscroll = function () { myFunction() };

        function myFunction() {
            var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
            var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
            var scrolled = (winScroll / height) * 100;
            var x = document.getElementById("myBar");
            if (x != null)
                document.getElementById("myBar").style.width = scrolled + "%";
        }

        const antIcon = <SettingOutlined style={{ fontSize: 45 }} spin />;

        const radioStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
        };
        return (
            <div id="MarriageTest">
                <MetaTags>
                    <title>Marriage Test</title>
                    <meta property="og:url" content="http://www.mywye.com/#/MarriageTest" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="Take the Marriage Test - Take now for fun and to learn more about your relationship" />
                    <meta property="og:description" content="The marriage test is a fun tool for your to assess your marriage and gauge it.  Share your grade, find lawyers or marriage counselors and much more." />
                </MetaTags>

                <Spin indicator={antIcon} tip="Loading..." style={{ position: "fixed", color: "rgb(255, 103, 120)" }} spinning={this.state.SpinVisible}>
                    <div id="TopImageCouple" youngmarriedcouple="Young Married Couple" className={this.state.className}>
                        <div class="inset-2">
                            <div class="blueinset-31">
                                <a target="_blank" class="link-block-2 w-inline-block logodisplay">
                                    <img src={process.env.PUBLIC_URL + '/images/MyWye_Final_Logos_Color-07.png'} onClick={this.HomeClick} loading="eager" alt="PeachyLife Brand Icon" class="peachylife-peach-2" /></a>
                                <div className="marragetesttext-2">{this.state.Part}</div>
                                <p className="white-heading1 whiteheading361">{this.state.quotes}</p>
                                <a id="Next-link" onClick={this.BeginTest} class="blue-button-3 w-button">Begin</a>
                            </div>
                        </div>
                        <div className="pagenav">
                            <a className="button-3 w-button" onClick={() => this.PageClick("1")}>1</a>
                            <a className="button-3 w-button" onClick={() => this.PageClick("2")}>2</a>
                            <a className="button-3 w-button" onClick={() => this.PageClick("3")}>3</a>
                        </div>
                        <a target="_blank" class="talktopro-2 w-inline-block">
                            <div class="text-block-3">Talk to a Professional</div>
                            <img src={process.env.PUBLIC_URL + '/images/Quotes2x.png'} loading="eager" width="197" alt="Consult with a Professional advisor" class="talktoaproicon"></img>
                        </a>
                        {/* <a peachy-life_brand="PeachyLife Brand" class="peachylifebrand w-inline-block"></a> */}
                    </div>
                    {
                        this.state.Tab === "1" &&
                        <section className="survey-section one">
                            <div className="header w-container">
                                <div className="progress-container">
                                    <div className="progress-bar" id="myBar" style={{ width: "0%" }}></div>
                                </div>
                            </div>
                            <div class="surveyintro-2 w-container">
                                <h1 className="heading-3">There are 30 questions, in three sections. You can save your answers at the end of each section and come back. </h1>
                                <h1 id="START-TEST" className="section-heading-4">PART ONE: Communication</h1>
                                <div id="start" ref={this.myDivToFocus} className="w-layout-grid qgrid">
                                    <div data-aos="fade-right" data-aos-anchor-placement="center-bottom" className="code-box-shape qimage _1a" id="w-node-afdff567636b-9ab4466b"></div>
                                    <div id="w-node-afdff567637d-9ab4466b" className="text-block-5">1. When I enter a room, my spouse usually:</div>
                                    <div id="w-node-afdff567636c-9ab4466b" className="questions1">
                                        <Radio.Group name='p1q1' onChange={this.handlechange} value={this.state.fields.p1q1} className="rblist">
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={1}></Radio>
                                                <div className="answertext">Looks at me and smiles</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={3}></Radio>
                                                <div className="answertext">Ignores me</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={4}></Radio>
                                                <div className="answertext">Turns in displeasure</div></div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={2}></Radio>
                                                <div className="answertext">Looks up and at least acknowledges me</div></div>
                                        </Radio.Group>
                                    </div >
                                </div>
                                <div id="Qtwo" ref={this.myDiv2ToFocus} class="w-layout-grid qgrid">
                                    <div data-aos="zoom-in" data-aos-anchor-placement="center-bottom" className="code-box-shape qimage _2a-copy" id="w-node-3fd22b358c6f-9ab4466b"></div>
                                    <div id="w-node-3fd22b358c70-9ab4466b" class="text-block-5">2. After arguments, my spouse and I usually:</div>
                                    <div id="w-node-3fd22b358c72-9ab4466b" class="questions1">
                                        <Radio.Group name='p1q2' onChange={this.handlechange} value={this.state.fields.p1q2} className="rblist">
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} name="Have made up by the next day" value={2}></Radio>
                                                <div className="answertext">Have made up by the next day</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={3}></Radio>
                                                <div className="answertext">Keep the fight going</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={1}></Radio>
                                                <div className="answertext"> Apologize and resolve the argument quickly</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={4}></Radio>
                                                <div className="answertext">Don't speak for days</div>
                                            </div>
                                        </Radio.Group>
                                    </div>
                                </div>
                                <div id="Qthree" ref={this.myDiv3ToFocus} class="w-layout-grid qgrid big">
                                    <div data-aos="fade-down" data-aos-anchor-placement="center-bottom" className="code-box-shape qimage _3a" id="w-node-f8cf20c53a6d-9ab4466b"></div>
                                    <div id="w-node-f8cf20c53a6e-9ab4466b" class="text-block-5">3. In the time we have been married...</div>
                                    <div id="w-node-f8cf20c53a70-9ab4466b" class="questions1">
                                        <Radio.Group name='p1q3' onChange={this.handlechange} value={this.state.fields.p1q3} className="rblist">
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={1}></Radio>
                                                <div className="answertext">We have become more patient with each other</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={4}></Radio>
                                                <div className="answertext">We get into serious fights all the time</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={2}></Radio>
                                                <div className="answertext">We argue from time-to-time but in a respectful manner</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={3}></Radio>
                                                <div className="answertext">We are bickering increasingly</div>
                                            </div>
                                        </Radio.Group>
                                    </div>
                                    {/* <div id="w-node-f8cf20c53a6d-9ab4466b" data-w-id="45848aa6-a660-065d-c65e-f8cf20c53a6d" class="qimage _3a"></div> */}
                                </div>
                                <div id="Qfour" ref={this.myDiv4ToFocus} class="w-layout-grid qgrid">
                                    <div data-aos="fade-down" data-aos-anchor-placement="center-bottom" className="code-box-shape qimage _4a" id="w-node-c01cb90badb3-9ab4466b"></div>
                                    <div id="w-node-c01cb90badb4-9ab4466b" class="text-block-5">4. If one of us did file for divorce...</div>
                                    <div id="w-node-c01cb90badb6-9ab4466b" class="questions1">
                                        <Radio.Group name='p1q4' onChange={this.handlechange} value={this.state.fields.p1q4} className="rblist">
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={2}></Radio>
                                                <div className="answertext">We would have some differences but would try to work through them</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={4}></Radio>
                                                <div className="answertext">It would be a knock-down, drag-out fight</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={3}></Radio>
                                                <div className="answertext">We would likely argue a lot about money</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={1}></Radio>
                                                <div className="answertext">We would be civil with each other</div>
                                            </div>
                                        </Radio.Group>
                                    </div>
                                </div>
                                <div id="Qfive" ref={this.myDiv5ToFocus} class="w-layout-grid qgrid">
                                    <div id="Q" class="qimage _5 w-node-67245dffcd71-9ab4466b"></div>
                                    <div id="w-node-67245dffcd72-9ab4466b" class="text-block-5">5. Have you been to marital counseling?</div>
                                    <div data-aos="fade-left" data-aos-anchor-placement="center-bottom" id="w-node-67245dffcd74-9ab4466b" data-w-id="e9686760-d20d-3e28-c4c8-67245dffcd74" class="questions1 longer">
                                        <Radio.Group name='p1q5' onChange={this.handlechange} value={this.state.fields.p1q5} className="rblist">
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={1}></Radio>
                                                <div className="answertext">No</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={3}></Radio>
                                                <div className="answertext">Yes, but it is not helping</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={4}></Radio>
                                                <div className="answertext">We tried, but gave up</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={2}></Radio>
                                                <div className="answertext">Yes, it is helping our communications</div>
                                            </div>
                                        </Radio.Group>
                                    </div>
                                </div>
                                <div id="Qsix" ref={this.myDiv6ToFocus} class="w-layout-grid qgrid">
                                    <div className="code-box-shape qimage _6a" id="w-node-333aa7c698aa-9ab4466b"></div>
                                    <div id="w-node-333aa7c698ab-9ab4466b" class="text-block-5">6. When you need help from your spouse, they:</div>
                                    <div data-aos="fade-left" data-aos-anchor-placement="center-bottom" id="w-node-333aa7c698ad-9ab4466b" data-w-id="e69af63f-08bb-dfb6-4391-333aa7c698ad" class="questions1">
                                        <Radio.Group name='p1q6' onChange={this.handlechange} value={this.state.fields.p1q6} className="rblist">
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={2}></Radio>
                                                <div className="answertext">Ask me what they can do to help</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={1}></Radio>
                                                <div className="answertext">Immediately try to help and know instinctively what I need</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={3}></Radio>
                                                <div className="answertext">They begrudgingly offer to help</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={4}></Radio>
                                                <div className="answertext">Couldn't care less</div>
                                            </div>
                                        </Radio.Group>
                                    </div>
                                </div>
                                <div id="Qseven" ref={this.myDiv7ToFocus} class="w-layout-grid qgrid">
                                    <div data-aos="zoom-in" data-aos-anchor-placement="center-bottom" className="code-box-shape qimage _7a" id="w-node-89565eea0ca7-9ab4466b"></div>
                                    <div id="w-node-89565eea0ca8-9ab4466b" class="text-block-5">7. Socially, how are you as a couple?</div>
                                    <div id="w-node-89565eea0caa-9ab4466b" class="questions1">
                                        {/* <Radio.Group name='p1q7' onChange={this.handlechange} value={this.state.fields.p1q7} className="rblist">
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={1}></Radio>
                                                <div className="answertext">Not very social in general</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={2}></Radio>
                                                <div className="answertext">Living in our separate social worlds</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={3}></Radio>
                                                <div className="answertext">Very socially connected as a couple</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={4}></Radio>
                                                <div className="answertext">Somewhat socially connected to others</div>
                                            </div>
                                        </Radio.Group> */}
                                        <Radio.Group name='p1q7' onChange={this.handlechange} value={this.state.fields.p1q7} className="rblist">
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={4}></Radio>
                                                <div className="answertext">Not very social in general</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={1}></Radio>
                                                <div className="answertext">Living in our separate social worlds</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={3}></Radio>
                                                <div className="answertext">Very socially connected as a couple</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={2}></Radio>
                                                <div className="answertext">Somewhat socially connected to others</div>
                                            </div>
                                        </Radio.Group>
                                    </div>
                                </div>
                                <div id="Qeight" ref={this.myDiv8ToFocus} class="w-layout-grid qgrid">
                                    <div data-aos="zoom-in" data-aos-anchor-placement="center-bottom" data-w-id="35b81bf3-f967-83c9-d985-d68be0352f21" className="code-box-shape qimage _8a" id="w-node-d68be0352f21-9ab4466b"></div>
                                    <div id="w-node-d68be0352f22-9ab4466b" class="text-block-5">8. With regards to making decisions...</div>
                                    <div id="w-node-d68be0352f24-9ab4466b" class="questions1">
                                        <Radio.Group name='p1q8' onChange={this.handlechange} value={this.state.fields.p1q8} className="rblist">
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={1}></Radio>
                                                <div className="answertext">I make decisions easily</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={3}></Radio>
                                                <div className="answertext">I hardly ever make decisions but instead let things happen</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={4}></Radio>
                                                <div className="answertext">I feel as if I must defend my decisions to my spouse</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={2}></Radio>
                                                <div className="answertext">I can make some independent decisions</div>
                                            </div>
                                        </Radio.Group>
                                    </div>
                                </div>
                                <div id="Qnine" ref={this.myDiv9ToFocus} class="w-layout-grid qgrid">
                                    <div className="code-box-shape qimage _9a" id="w-node-fbd5167b2a7b-9ab4466b"></div>
                                    <div id="w-node-fbd5167b2a7c-9ab4466b" class="text-block-5">9. Do you need your spouse to change how they communicate with you?</div>
                                    <div id="w-node-fbd5167b2a7e-9ab4466b" class="questions1">
                                        <Radio.Group name='p1q9' onChange={this.handlechange} value={this.state.fields.p1q9} className="rblist">
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={4}></Radio>
                                                <div className="answertext">It's too late, they won't / can't change</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={1}></Radio>
                                                <div className="answertext">No, I'm happy with our communication</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={3}></Radio>
                                                <div className="answertext">They need to make some major changes soon</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={2}></Radio>
                                                <div className="answertext">Some small changes would improve the way we communicate</div>
                                            </div>
                                        </Radio.Group>
                                    </div>
                                </div>
                                <div id="Qten" ref={this.myDiv10ToFocus} class="w-layout-grid qgrid">
                                    <div data-aos="zoom-in-up" data-aos-anchor-placement="center-bottom" className="code-box-shape qimage _10a" id="w-node-9372d13f45f7-9ab4466b"></div>
                                    <div id="w-node-9372d13f45f8-9ab4466b" class="text-block-5">10. When communicating with my partner, we are most often:</div>
                                    <div id="w-node-9372d13f45fa-9ab4466b" class="questions1">
                                        <Radio.Group name='p1q10' onChange={this.handlechange} value={this.state.fields.p1q10} className="rblist">
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={4}></Radio>
                                                <div className="answertext">Arguing, yelling, screaming or fighting</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={3}></Radio>
                                                <div className="answertext">Neutral and emotionless</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={2}></Radio>
                                                <div className="answertext">Intelligent and respectful</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={1}></Radio>
                                                <div className="answertext">Complimentary, flirtatious or loving</div>
                                            </div>
                                        </Radio.Group>
                                    </div>
                                </div>
                            </div >
                        </section >
                    }
                    {
                        this.state.Tab === "2" &&
                        <section className="survey-section one">
                            <div className="header w-container">
                                <div className="progress-container">
                                    <div className="progress-bar" id="myBar" style={{ width: "0%" }}></div>
                                </div>
                            </div>
                            <div className="surveyintro-2 w-container" >
                                <h1 className="heading-2">This is Section TWO: about mutual respect. You can save your answers at the end of each section and come back. </h1>
                                <h1 id="StartingTest" className="section-heading-3">PART TWO: Mutual Respect</h1>
                                <div id="start" ref={this.myDivToFocus} className="w-layout-grid qgrid">
                                    <div id="w-node-5ea7a6d5fe0b-8ab4466f" class="text-block-7">1. When my spouse criticizes me, I most often:</div>
                                    <div data-aos="zoom-in" data-aos-anchor-placement="center-bottom" id="w-node-5ea7a6d5fe0a-8ab4466f" data-w-id="d95870e9-fd73-15ce-227f-5ea7a6d5fe0a" class="qimage _1b"></div>
                                    <div id="w-node-5ea7a6d5fe0d-8ab4466f" class="questions1">
                                        <Radio.Group className="rblist" name='p2q1' onChange={this.handlechange} value={this.state.fields.p2q1}>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={3}></Radio>
                                                <div className="answertext">Ignore them and bite my tongue</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={1}></Radio>
                                                <div className="answertext">Listen and try to understand</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={2}></Radio>
                                                <div className="answertext">Defend myself</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={4}></Radio>
                                                <div className="answertext">Throw things at them</div>
                                            </div>
                                        </Radio.Group>
                                    </div >
                                </div>
                                <div id="Qtwo" ref={this.myDiv2ToFocus} class="w-layout-grid qgrid">
                                    <div data-aos="fade-right" data-aos-anchor-placement="center-bottom" id="w-node-5ea7a6d5fe1f-8ab4466f" data-w-id="d95870e9-fd73-15ce-227f-5ea7a6d5fe1f" class="qimage _2c"></div>
                                    <div id="w-node-5ea7a6d5fe20-8ab4466f" class="text-block-7">2. When your spouse makes jokes in a social setting, they are usually:</div>
                                    <div id="w-node-5ea7a6d5fe22-8ab4466f" class="questions1">
                                        <Radio.Group className="rblist" name='p2q2' onChange={this.handlechange} value={this.state.fields.p2q2}>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={2}></Radio>
                                                <div className="answertext">Some jokes about your marriage that are harmless</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={3}></Radio>
                                                <div className="answertext"> Mostly jokes about your marriage that are offensive</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={4}></Radio>
                                                <div className="answertext">All jokes about you in a very negative light and it feels like a personal attack</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={1}></Radio>
                                                <div className="answertext">Funny jokes on subject matter that everyone can laugh at</div>
                                            </div>
                                        </Radio.Group>
                                    </div>
                                </div>
                                <div id="Qthree" ref={this.myDiv3ToFocus} class="w-layout-grid qgrid big">
                                    <div id="w-node-5ea7a6d5fe34-8ab4466f" class="text-block-7">3.When it comes to religion:</div>
                                    <div id="w-node-5ea7a6d5fe36-8ab4466f" class="questions1">
                                        <Radio.Group className="rblist" name='p2q3' onChange={this.handlechange} value={this.state.fields.p2q3}>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={1}></Radio>
                                                <div className="answertext">My spouse and I share the same religion or spiritual beliefs</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={2}></Radio>
                                                <div className="answertext">My spouse and I have different religions/beliefs but are very respectful of each other's differences</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={3}></Radio>
                                                <div className="answertext">My spouse and I have very different religions/beliefs and it sometimes causes conflict</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={4}></Radio>
                                                <div className="answertext">Our religions/beliefs are fundamentally different and we can never see eye to eye</div>
                                            </div>
                                        </Radio.Group>
                                    </div>
                                    <div data-aos="fade-right" data-aos-anchor-placement="center-bottom" id="w-node-5ea7a6d5fe49-8ab4466f" data-w-id="d95870e9-fd73-15ce-227f-5ea7a6d5fe49" class="qimage _4c"></div>
                                </div>
                                <div id="Qfour" ref={this.myDiv4ToFocus} class="w-layout-grid qgrid">
                                    <div data-aos="zoom-out-down" data-aos-anchor-placement="center-bottom" id="w-node-5ea7a6d5fe47-8ab4466f" data-w-id="d95870e9-fd73-15ce-227f-5ea7a6d5fe47" class="qimage _3c"></div>
                                    <div id="w-node-5ea7a6d5fe4a-8ab4466f" class="text-block-7">4. When it comes to politics:</div>
                                    <div id="w-node-5ea7a6d5fe4c-8ab4466f" class="questions1">
                                        <Radio.Group className="rblist" name='p2q4' onChange={this.handlechange} value={this.state.fields.p2q4}>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={2}></Radio>
                                                <div className="answertext">We have different political views but respect the differences</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={3}></Radio>
                                                <div className="answertext">We have very different political views and it sometimes causes conflict</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={4}></Radio>
                                                <div className="answertext">We have extremely different political views and argue whenever politics come up</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={1}></Radio>
                                                <div className="answertext">We share the same political views</div>
                                            </div>
                                        </Radio.Group>
                                    </div>
                                </div>
                                <div id="Qfive" ref={this.myDiv5ToFocus} class="w-layout-grid qgrid">
                                    <div id="Q" class="qimage _5 w-node-67245dffcd71-9ab4466b"></div>
                                    <div id="w-node-5ea7a6d5fe5f-8ab4466f" class="text-block-7">5. When it comes to diet and exercise</div>
                                    <div id="w-node-5ea7a6d5fe61-8ab4466f" class="questions1">
                                        <Radio.Group className="rblist" name='p2q5' onChange={this.handlechange} value={this.state.fields.p2q5}>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={4}></Radio>
                                                <div className="answertext">My spouse and/or I will not change our habits</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={1}></Radio>
                                                <div className="answertext">My spouse and I have a mutual interest in healthy exercise</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={3}></Radio>
                                                <div className="answertext">Our differences about diet and exercise are causing problems</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={2}></Radio>
                                                <div className="answertext">One of us has more interest than the other in health/diet and exercise</div>
                                            </div>
                                        </Radio.Group>
                                    </div>
                                </div>
                                <div id="Qsix" ref={this.myDiv6ToFocus} class="w-layout-grid qgrid">
                                    <div data-aos="zoom-out-down" data-aos-anchor-placement="center-bottom" id="w-node-5ea7a6d5fe73-8ab4466f" data-w-id="d95870e9-fd73-15ce-227f-5ea7a6d5fe73" class="qimage _6c"></div>
                                    <div id="w-node-5ea7a6d5fe74-8ab4466f" class="text-block-7 a">6. It's your birthday, anniversary, or another holiday or special date. Your spouse:</div>
                                    <div id="w-node-5ea7a6d5fe76-8ab4466f" class="questions1">
                                        <Radio.Group className="rblist" name='p2q6' onChange={this.handlechange} value={this.state.fields.p2q6}>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={4}></Radio>
                                                <div className="answertext">Forgot as they often do</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={2}></Radio>
                                                <div className="answertext">Has a gift/plans for you that they put genuine thought into</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={1}></Radio>
                                                <div className="answertext">Found something cheap at a garage sale</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={3}></Radio>
                                                <div className="answertext">Has a gift/plans for you but it feels like an afterthought.</div>
                                            </div>
                                        </Radio.Group>
                                    </div>
                                </div>
                                <div id="Qseven" ref={this.myDiv7ToFocus} class="w-layout-grid qgrid">
                                    <div data-aos="zoom-in" data-aos-anchor-placement="center-bottom" id="w-node-5ea7a6d5fe88-8ab4466f" data-w-id="d95870e9-fd73-15ce-227f-5ea7a6d5fe88" class="qimage _7c"></div>
                                    <div id="w-node-5ea7a6d5fe89-8ab4466f" class="text-block-7">7. When it comes to co-parenting:</div>
                                    <div id="w-node-5ea7a6d5fe8b-8ab4466f" class="questions1">
                                        <Radio.Group className="rblist" name='p2q7' onChange={this.handlechange} value={this.state.fields.p2q7}>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={4}></Radio>
                                                <div className="answertext">I have serious concerns about my spouse's ability to parent</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={2}></Radio>
                                                <div className="answertext">My spouse and I have very different parenting styles</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={1}></Radio>
                                                <div className="answertext">My spouse and I parent well together</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={3}></Radio>
                                                <div className="answertext">We don't have children and/or do not intend to have children</div>
                                            </div>
                                        </Radio.Group>
                                    </div>
                                </div>
                                <div id="Qeight" ref={this.myDiv8ToFocus} class="w-layout-grid qgrid">
                                    <div data-aos="zoom-out" data-aos-anchor-placement="center-bottom" id="w-node-5ea7a6d5fe9d-8ab4466f" data-w-id="d95870e9-fd73-15ce-227f-5ea7a6d5fe9d" class="qimage _8c"></div>
                                    <div id="w-node-5ea7a6d5fe9e-8ab4466f" class="text-block-7">8. Have you ever thought about having an affair?</div>
                                    <div id="w-node-5ea7a6d5fea0-8ab4466f" class="questions1">
                                        <Radio.Group className="rblist" name='p2q8' onChange={this.handlechange} value={this.state.fields.p2q8} >
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={1}></Radio>
                                                <div className="answertext">Never, even on the worst day I wouldn't entertain the idea of an affair</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={4}></Radio>
                                                <div className="answertext">I have engaged/am currently engaged in an affair</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={2}></Radio>
                                                <div className="answertext">Sometimes, but I would never act on it</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={3}></Radio>
                                                <div className="answertext">I think about having an affair often</div>
                                            </div>
                                        </Radio.Group>
                                    </div>
                                </div>
                                <div id="Qnine" ref={this.myDiv9ToFocus} class="w-layout-grid qgrid">
                                    <div data-aos="fade-down" data-aos-easing="linear" data-aos-anchor-placement="center-bottom" id="w-node-5ea7a6d5feb2-8ab4466f" data-w-id="d95870e9-fd73-15ce-227f-5ea7a6d5feb2" class="qimage _9c"></div>
                                    <div id="w-node-5ea7a6d5feb3-8ab4466f" class="text-block-7">9. In regards to drugs/alcohol use:</div>
                                    <div id="w-node-5ea7a6d5feb5-8ab4466f" class="questions1">
                                        <Radio.Group className="rblist" name='p2q9' onChange={this.handlechange} value={this.state.fields.p2q9}>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={2}></Radio>
                                                <div className="answertext">One or both of us uses tobacco/drugs/alcohol recreationally but it's not a concern</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={1}></Radio>
                                                <div className="answertext">Neither myself or my spouse consume any drugs (other than alcohol in social settings or an occasional cigarette)</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={3}></Radio>
                                                <div className="answertext">Only one of us uses tobacco/drugs/alcohol and we do not see eye to eye on this</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={4}></Radio>
                                                <div className="answertext">I believe myself or my partner has a problem with drug/alcohol abuse</div>
                                            </div>
                                        </Radio.Group>
                                    </div>
                                </div>
                                <div id="Qten" ref={this.myDiv10ToFocus} class="w-layout-grid qgrid">
                                    <div data-aos="zoom-in" data-aos-anchor-placement="center-bottom" id="w-node-5ea7a6d5fec7-8ab4466f" data-w-id="d95870e9-fd73-15ce-227f-5ea7a6d5fec7" class="qimage _10c"></div>
                                    <div id="w-node-5ea7a6d5fec8-8ab4466f" class="text-block-7">10. In the time that we have been in a relationship I feel that:</div>
                                    <div id="w-node-5ea7a6d5feca-8ab4466f" class="questions1">
                                        <Radio.Group className="rblist" name='p2q10' onChange={this.handlechange} value={this.state.fields.p2q10}>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={1}></Radio>
                                                <div className="answertext">Our mutual respect and admiration has increased</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={3}></Radio>
                                                <div className="answertext">I respect my partner less</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={4}></Radio>
                                                <div className="answertext">There is no respect left in our relationship</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={2}></Radio>
                                                <div className="answertext">Our mutual respect has remained stable</div>
                                            </div>
                                        </Radio.Group>
                                    </div>
                                </div>
                            </div >
                        </section >
                    }
                    {
                        this.state.Tab === "3" &&
                        <section className="survey-section one">
                            <div className="header w-container">
                                <div className="progress-container">
                                    <div className="progress-bar" id="myBar" style={{ width: "0%" }}></div>
                                </div>
                            </div>
                            <div className="surveyintro-2 w-container">
                                <h1 className="heading-2">This is Part THREE: about trust and satisfaction. You can save your answers at the end of each section and come back. </h1>
                                <h1 id="StartingTest" className="section-heading-3">PART THREE: Trust and Satisfaction</h1>
                                <div id="start" ref={this.myDivToFocus} class="w-layout-grid qgrid twoc">
                                    <div id="w-node-c40637d158cd-e1b4466e" class="text-block-7">1. How many years have you been married?</div>
                                    <div id="w-node-c40637d158d0-e1b4466e" class="questions1 nopicl">
                                        <Radio.Group className="rblist" name='p3q1' onChange={this.handlechange} value={this.state.fields.p3q1}>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={4}></Radio>
                                                <div className="answertext">1-5 years</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={3}></Radio>
                                                <div className="answertext">5-7 years</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={2}></Radio>
                                                <div className="answertext">7-10 years</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={1}></Radio>
                                                <div className="answertext">10-20 years +</div>
                                            </div>
                                        </Radio.Group>
                                    </div >
                                </div>
                                <div id="Qtwo" ref={this.myDiv2ToFocus} class="w-layout-grid qgrid shorter">
                                    <div data-aos="zoom-in" data-aos-anchor-placement="center-bottom" id="w-node-c40637d158e3-e1b4466e" class="qimage _2"></div>
                                    <div id="w-node-c40637d158e4-e1b4466e" class="text-block-7">2. Before I got married...</div>
                                    <div id="w-node-c40637d158e6-e1b4466e" class="questions1">
                                        <Radio.Group className="rblist" name='p3q2' onChange={this.handlechange} value={this.state.fields.p3q2}>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={2}></Radio>
                                                <div className="answertext">Thought I would get married and see how it goes</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={3}></Radio>
                                                <div className="answertext">Had a good understanding of my future spouse's flaws</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={1}></Radio>
                                                <div className="answertext">Thought the marriage would last forever</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={4}></Radio>
                                                <div className="answertext">Knew I was settling but I didn't want to be alone</div>
                                            </div>
                                        </Radio.Group>
                                    </div>
                                </div>
                                <div id="Qthree" ref={this.myDiv3ToFocus} class="w-layout-grid qgrid big">
                                    <div id="w-node-c40637d158f8-e1b4466e" class="text-block-7">3. Since getting married...</div>
                                    <div id="w-node-c40637d158fa-e1b4466e" class="questions1">
                                        <Radio.Group className="rblist" name='p3q3' onChange={this.handlechange} value={this.state.fields.p3q3}>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={1}></Radio>
                                                <div className="answertext">I have changed in a positive way</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={2}></Radio>
                                                <div className="answertext">I am the same person I was before I got married</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={4}></Radio>
                                                <div className="answertext">I hate who I've become</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={3}></Radio>
                                                <div className="answertext">My spouse has changed me, but not in a good way</div>
                                            </div>
                                        </Radio.Group>
                                    </div>
                                    <div data-aos="fade-right" data-aos-anchor-placement="center-bottom" id="w-node-c40637d1590b-e1b4466e" data-w-id="6633cfc9-4332-aaa8-23b2-c40637d1590b" class="qimage _3"></div>
                                </div>
                                <div id="Qfour" ref={this.myDiv4ToFocus} class="w-layout-grid qgrid">
                                    <div data-aos="zoom-in" data-aos-anchor-placement="center-bottom" id="w-node-c40637d1590e-e1b4466e" data-w-id="6633cfc9-4332-aaa8-23b2-c40637d1590e" class="qimage _4"></div>
                                    <div id="w-node-c40637d1590f-e1b4466e" class="text-block-7">
                                        4. With regards to my marriage, if I could snap my fingers,<br />
                        I would...</div>
                                    <div id="w-node-c40637d15911-e1b4466e" class="questions1">
                                        <Radio.Group className="rblist" name='p3q4' onChange={this.handlechange} value={this.state.fields.p3q4}>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={1}></Radio>
                                                <div className="answertext">Stay exactly where I am</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={2}></Radio>
                                                <div className="answertext">Make minor changes</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={4}></Radio>
                                                <div className="answertext">Absolutely get a divorce if I could</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={3}></Radio>
                                                <div className="answertext">Make major changes</div>
                                            </div>
                                        </Radio.Group>
                                    </div>
                                </div>
                                <div id="Qfive" ref={this.myDiv5ToFocus} class="w-layout-grid qgrid">
                                    <div id="Q" class="qimage _5 w-node-c40637d15923-e1b4466e"></div>
                                    <div id="w-node-c40637d15924-e1b4466e" class="text-block-7">5. Overall, do you feel satisfied with your life?</div>
                                    <div id="w-node-c40637d15926-e1b4466e" class="questions1 _5">
                                        <Radio.Group className="rblist" name='p3q5' onChange={this.handlechange} value={this.state.fields.p3q5}>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={4}></Radio>
                                                <div className="answertext">I hate my life</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={2}></Radio>
                                                <div className="answertext">It could be better</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={3}></Radio>
                                                <div className="answertext">I need to make major changes before I could feel that way</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={1}></Radio>
                                                <div className="answertext">I love my life</div>
                                            </div>
                                        </Radio.Group>
                                    </div>
                                </div>
                                <div id="Qsix" ref={this.myDiv6ToFocus} class="w-layout-grid qgrid">
                                    <div data-aos="fade-right" data-aos-anchor-placement="center-bottom" id="w-node-c40637d15939-e1b4466e" data-w-id="6633cfc9-4332-aaa8-23b2-c40637d15939" class="qimage _6"></div>
                                    <div id="w-node-c40637d1593a-e1b4466e" class="text-block-7">6. In regard to mutual trust:</div>
                                    <div id="w-node-c40637d1593c-e1b4466e" class="questions1">
                                        <Radio.Group className="rblist" name='p3q6' onChange={this.handlechange} value={this.state.fields.p3q6}>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={2}></Radio>
                                                <div className="answertext">My spouse and I trust each other just as much as we did when we got married</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={4}></Radio>
                                                <div className="answertext">There is little to no trust left in our marriage</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={3}></Radio>
                                                <div className="answertext">One or both of us have lost some trust in each other</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={1}></Radio>
                                                <div className="answertext">My spouse and I trust each other more now than when we got married</div>
                                            </div>
                                        </Radio.Group>
                                    </div>
                                </div>
                                <div id="Qseven" ref={this.myDiv7ToFocus} class="w-layout-grid qgrid">
                                    <div data-aos="fade-down" data-aos-anchor-placement="center-bottom" id="w-node-c40637d1594e-e1b4466e" data-w-id="6633cfc9-4332-aaa8-23b2-c40637d1594e" class="qimage _7"></div>
                                    <div id="w-node-c40637d1594f-e1b4466e" class="text-block-7">7. When I think about growing old with my spouse I feel:</div>
                                    <div id="w-node-c40637d15951-e1b4466e" class="questions1">
                                        <Radio.Group className="rblist" name='p3q7' onChange={this.handlechange} value={this.state.fields.p3q7}>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={1}></Radio>
                                                <div className="answertext">Happy to have many years together ahead of us</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={3}></Radio>
                                                <div className="answertext">Worry sometimes that we are growing apart and that we will have nothing in common in our later years together</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={2}></Radio>
                                                <div className="answertext">Mostly happy but also hope that we will grow together and not apart</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={4}></Radio>
                                                <div className="answertext">Deeply unhappy about what lies ahead</div>
                                            </div>
                                        </Radio.Group>
                                    </div>
                                </div>
                                <div id="Qeight" ref={this.myDiv8ToFocus} class="w-layout-grid qgrid">
                                    <div data-aos="zoom-in" data-aos-anchor-placement="center-bottom" id="w-node-c40637d15963-e1b4466e" data-w-id="6633cfc9-4332-aaa8-23b2-c40637d15963" class="qimage _8d"></div>
                                    <div id="w-node-c40637d15964-e1b4466e" class="text-block-7">8.How often do you have sex with your spouse per week?</div>
                                    <div id="w-node-c40637d15966-e1b4466e" class="questions1">
                                        <Radio.Group className="rblist" name='p3q8' onChange={this.handlechange} value={this.state.fields.p3q8}>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={1}></Radio>
                                                <div className="answertext">Whenever possible</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={2}></Radio>
                                                <div className="answertext">Ocassionally</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={3}></Radio>
                                                <div className="answertext">Rarely</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={4}></Radio>
                                                <div className="answertext">Not happening</div>
                                            </div>
                                        </Radio.Group>
                                    </div>
                                </div>
                                <div id="Qnine" ref={this.myDiv9ToFocus} class="w-layout-grid qgrid">
                                    <div data-aos="fade-down" data-aos-anchor-placement="center-bottom" id="w-node-c40637d15978-e1b4466e" data-w-id="6633cfc9-4332-aaa8-23b2-c40637d15978" class="qimage _9"></div>
                                    <div id="w-node-c40637d15979-e1b4466e" class="text-block-7">9. Do you sometimes wish you never got married?</div>
                                    <div id="w-node-c40637d1597b-e1b4466e" class="questions1">
                                        <Radio.Group className="rblist" name='p3q9' onChange={this.handlechange} value={this.state.fields.p3q9}>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={2}></Radio>
                                                <div className="answertext">Sometimes, but the feeling goes away quickly</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={3}></Radio>
                                                <div className="answertext">Yes, but who doesn't?</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={4}></Radio>
                                                <div className="answertext">I very much regret getting married</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={1}></Radio>
                                                <div className="answertext">No, I wouldn't change a thing</div>
                                            </div>
                                        </Radio.Group>
                                    </div>
                                </div>
                                <div id="Qten" ref={this.myDiv10ToFocus} class="w-layout-grid qgrid">
                                    <div data-aos="fade-right" data-aos-anchor-placement="center-bottom" id="w-node-c40637d1598d-e1b4466e" data-w-id="6633cfc9-4332-aaa8-23b2-c40637d1598d" class="qimage _10d"></div>
                                    <div id="w-node-c40637d1598e-e1b4466e" class="text-block-7">10. How attractive do you find your spouse?</div>
                                    <div id="w-node-c40637d15990-e1b4466e" class="questions1">
                                        <Radio.Group className="rblist" name='p3q10' onChange={this.handlechange} value={this.state.fields.p3q10}>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={2}></Radio>
                                                <div className="answertext">My attraction is about the same as when we got married</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={1}></Radio>
                                                <div className="answertext">My attraction has increased since getting married</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={3}></Radio>
                                                <div className="answertext">My spouse is less attractive now that we are married</div>
                                            </div>
                                            <div className="questionblock w-inline-block">
                                                <Radio style={radioStyle} value={4}></Radio>
                                                <div className="answertext">I'm not at all attracted to my spouse</div>
                                            </div>
                                        </Radio.Group>
                                    </div>
                                </div>
                            </div>
                        </section>
                    }
                    <div id="End" ref={this.myDiv11ToFocus} class="end1">
                        <div id="endtop" class="div-block-6">
                            <div class="div-block-6 a">
                                <h1 class="heading-4 new-line">{this.state.footer}<br />
                                    <span class="smallpinktext">(only you control who sees your answers)</span></h1>
                                {sessionStorage.getItem('Success') != "true" &&
                                    <div style={{ width: "100%" }} id="divLogin">
                                        <div class="div-block-7">
                                            <Input ID="txtEmailAddress" name='email' value={this.state.email} onChange={this.inputchange} placeholder="your email address" style={{ width: "100%", height: "50px", outline: "none", border: "0", padding: "10px" }}></Input>
                                        </div>
                                        <div class="div-block-7">
                                            <Input ID="txtPassword" type='password' name='password' value={this.state.password} placeholder="password" onChange={this.inputchange} TextMode="Password" style={{ width: "85%", height: "50px", outline: "none", border: "0", padding: "10px", }}></Input>
                                            <a class="nonblock nontext transition rounded-corners clearfix grpelem shared_content" id="u26275-4">
                                                <Button ID="btnSubmit" className="button-4" type="primary" onClick={() => this.SaveAnswers()} >Save</Button>
                                            </a>
                                        </div>
                                    </div>}
                                {sessionStorage.getItem('Success') === "true" &&
                                    <div style={{ width: "50%" }} id="divSaveonly">
                                        <a class="nonblock nontext transition rounded-corners clearfix grpelem shared_content" id="u26275-5" style={{ textAlign: "center", width: "100%" }}>
                                            <Button ID="btnSave" className="btn btn-primary button-4" onClick={() => this.SaveAnswers()} style={{ width: "40%", marginTop: "10%", marginLeft: "-44%" }}>Save</Button>
                                        </a>
                                    </div>
                                }
                                <img src={process.env.PUBLIC_URL + '/images/MyWye_Final_Logos_Color-07.png'} alt="MyWye" className="image-7" />
                            </div>
                        </div>
                    </div >
                    <Footer />
                </Spin >
            </div >
        )
    }
}

const mapStateToProp = state => {
    return {
        Value: state.Value,
        Category: state.Category
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        PageName: Value => dispatch(PageName(Value)),
        AdviceCategory: Category => dispatch(AdviceCategory(Category))
    }
}

export default connect(mapStateToProp, mapDispatchToProps)(MarriageTest);