import React from 'react';
import { Modal, Input } from 'antd';
import { ForgetPwdEmail } from '../../Redux/Redux.Action';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { connect } from 'react-redux';
import 'antd/dist/antd.css';
import '../Css/Peachy.css';
import { ResetPassword } from '../MyAccount/MyAccountService'
import ReactGA from 'react-ga';

class ForgetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Password: '', RepeatPassword: ''
        }
    }

    componentWillMount() {
        import('../Css/components.css')
        import('../Css/normalize.css')
        import('../Css/plmarriagetest.css')

        ReactGA.initialize('G-JT4HS6ZZSX');
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.gtag('event', 'conversion', { 'send_to': 'AW-10806936548/AfZeCJKY9YQDEOSHk6Eo' });
        
    }

    HomeClick = () => {

    }

    ChangePassword = async () => {
        debugger;
        const query = new URLSearchParams(this.props.location.search);
        const token = query.get('Userid')
        var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        if (this.state.Password === "" != this.state.RepeatPassword === "") {
            Modal.warning({
                content: 'Password and Re-enter password does not match',
            });
        }
        else if (!(format.test(this.state.Password) && this.state.Password.length >= 6)) {
            Modal.warning({
                title: 'Password does not match the Criteria',
                content: 'Please enter more than 6 characters & 1 symbol',
            });
        }
        else {
            var returnedData = await ResetPassword(this.state.Password, token);
            if (returnedData.success !== true) {
                Modal.error({ content: "Invalid Credentials" });
            }
            else {
                Modal.success({ title: 'Reset Password', content: 'Password changed successfully' })
                this.props.history.push('/')
            }
            if (returnedData === "") {
                Modal.error({ content: 'Server Error Occured' })
            }
            this.setState({ Password: '', RepeatPassword: '' });
        }
    }
    componentDidMount() {
    }

    render() {
        return (
            <div>
                <div class="w-embed w-iframe">
                    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WQNXXP9" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
                </div>
                <div class="header">
                    <div data-collapse="medium" data-animation="default" data-duration="400" role="banner" class="navigation w-nav">
                        <div class="navigation-full">
                            <div class="navigation-container">
                                <a href="#" aria-current="page" onClick={this.HomeClick} class="brand w-nav-brand w--current logo"><img src={process.env.PUBLIC_URL + '/images/MyWye_Final_Logos_Color-07.png'} data-w-id="a3f690b4-743a-6aa0-cbe9-ad91eab37478" alt="PeachyLife Brand" class="plbrand" /></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w-layout-grid formgrid1">
                    <div id="w-node-78735125c002-933ed30c" class="formleft">
                        <h1 class="subheadline a">Change Password</h1>
                    </div>
                    <div id="w-node-535f9078c299-933ed30c" class="accountssectiondiv"></div>
                    <div id="w-node-efe23b320833-933ed30c" class="accountsection">
                        <div class="bluetitle">Change Password</div>
                        <div className="formboxtextentry w-inline-block">
                            <Input type="password" required className="formfieldbox short" placeholder="New Password  (6 characters / 2 symbols)" onChange={(e) => { this.setState({ Password: e.target.value }) }} />
                        </div>
                        <div className="formboxtextentry w-inline-block">
                            <Input type="password" required placeholder="Repeat Password" className="formfieldbox short" onChange={(e) => { this.setState({ RepeatPassword: e.target.value }) }} />
                        </div>
                        <div class="savecaptcha">
                            <a class="MyAccountsavebutton w-button" onClick={this.ChangePassword}>Save</a>
                        </div>
                    </div>
                </div>
                
                <footer class="footer2-2">
                    <div class="footercontainter w-container">
                        <div class="w-layout-grid grid">
                            <div id="w-node-19844c8798ba-4c8798b7" class="div-block-3">
                                <a data-w-id="bb1b0c96-cd83-4f5c-3913-19844c8798bb" href="#" onClick={this.HomeClick} class="link-block w-inline-block logo"></a>
                                <div class="div-block-9">
                                    <a href="#" class="socialmediaicon-2">Privacy Policy</a>
                                    <a href="#" class="socialmediaicon-2">Terms and Conditions</a>
                                    <a href="#" target="_blank" class="socialmediaicon-2">Marriage Test</a>
                                    <a href="#" class="socialmediaicon-2">Articles</a>
                                    <a href="#" class="socialmediaicon-2 space">Ask A Professional</a>
                                </div>
                                <div class="social-icons">
                                    <h1 class="yelloheader17-2">JOIN THE CONVERSATION</h1>
                                    <a href="https://twitter.com/PeachyLifeUS" target="_blank" class="socailmedialink w-inline-block"><img src="images/TwitterICON.svg" loading="lazy" alt="Twitter PeachyLife ICon" /></a>
                                    <a href="https://www.facebook.com/peachysociallife/" target="_blank" class="socailmedialink w-inline-block"><img src="images/FacebookIcon.svg" loading="lazy" alt="FaceBook PeachyLife Icon" /></a>
                                    <a href="#" target="_blank" class="socailmedialink w-inline-block"><img src="images/YouTubeICON.svg" loading="lazy" alt="FaceBook PeachyLife Icon" /></a>
                                </div>
                            </div>
                            <div id="w-node-19844c8798d2-4c8798b7" class="div-block-5">
                                <h1 class="whitesubhead-2">OUR MISSION:<br />IS TO CONNECT YOU WITH TOP PROFESSIONALS AND RESOURCES FOR WHATEVER SERVICES YOU NEED TO LIVE YOUR BEST LIFE.</h1>
                                <div class="whiteubuntu17-2">What is the state of your relationship? Are you happy? Do you see a bright future and feel connected to your partner? <br /><br />Get started by taking the marriage test to assess the state of your relationship.  Whether you’re looking to improve your relationship or contemplating a separation, we have professionals who can help. <br /><br />At Peachy Life you can connect with others who are going through similar challenges. We have a community that you can access anonymously, from resources to help you to people that you can really talk to.<br /></div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        )
    }
}


export default withRouter(ForgetPassword);