import { ServiceUrl } from '../ServiceURL';

export async function GetAllExpertVideos() {
    let Success = '';
    const data = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        // body: JSON.stringify({
        //     ClientId: clientId, QuestionCategory: questionCategory, Question: question,
        //     ClientName: clientName, ClientEmail: clientEmail, ClientState: clientState, VirtualConsultations: Virtual
        // })
    };
    await fetch(ServiceUrl + '/api/GetAllExpertVideos', data) //https://localhost:5001/api/GetAllExpertVideos
        .then((response) => response.json()) //https://peachylife.com/peachylifeapi/api/GetAllExpertVideos
        .then((responseJson) => {
            Success = responseJson;
        })
    return Success;
}

export async function GetArticlesByCategory(Category) {
    let Success = '';
    const data = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ Category })
    };
    await fetch(ServiceUrl + '/api/GetArticlesByCategory', data)
        .then((response) => response.json()) //https://peachylife.com/peachylifeapi/api/GetArticlesByCategory
        .then((responseJson) => {
            Success = responseJson;
        })
    return Success;
}