import React from 'react';
import { Button, Modal, Input, Popover, Space, Carousel, Menu, Dropdown, Row, Col, Card, Divider, Form } from 'antd';
import MetaTags from 'react-meta-tags';
import 'antd/dist/antd.css';
import { Player, ControlBar, ClosedCaptionButton, BigPlayButton } from 'video-react'; //Only for WINDOWS AND ANDROID!
import VideoPlayer from 'react-video-js-player'; // VIDEO JS PLAYER (IOS and others)
import { LoginUser, GetMenuDetails, CheckMarriageTest, ForgotLogin } from '../Home/HomeService'
import '../Css/Peachy.css';
import BoxComponent from '../BoxComponent/BoxComponent';
import { connect } from 'react-redux';
import { PageName, AdviceCategory } from '../../Redux/Redux.Action';
import Footer from '../Footer/Footer';
import Header from '../Dashboard/Dashboard';
import TopQuestions from '../TopQuestions/TopQuestions';
import { GetAllExpertVideos } from './ResourceService';
import { ServiceUrl, FilesURL } from '../ServiceURL';
import ReactGA from 'react-ga';

class Resource extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Email: '', Password: '', loginShow: false, ArticlesMenu: [], tempArticles: [],
            VideoList: [], tempVideoList: []
        }
    }

    componentWillMount() {
        import('../Css/components.css')
        import('../Css/normalize.css')
        import('../Css/plmarriagetest.css')
    }

    async componentDidMount() {
        debugger;
        const script = document.createElement("script");
        //script.src = "http://192.227.67.200/Peachylife_/static/js/plmarriagetest3-944b06bbb-864f41afbdea5.js";
        script.src = FilesURL + "/Peachylife_/static/js/plmarriagetest3-944b06bbb-864f41afbdea5.js";
        script.async = true;
        document.body.appendChild(script);

        ReactGA.initialize('G-JT4HS6ZZSX');
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.gtag('event', 'conversion', { 'send_to': 'AW-10806936548/AfZeCJKY9YQDEOSHk6Eo' });

        this.props.PageName("");
        this.props.AdviceCategory("");

        let videoList = await GetAllExpertVideos();
        let MenuList = await GetMenuDetails();

        /* FILTERATION OF CATEGORY DATA */
        var tempList = videoList.specialistVideos.filter((item) => "Family Law" === item.speciality)
        var tempVideoList = [];
        tempList.forEach(element => {
            tempVideoList.push(element)
        });
        this.setState({ tempVideoList: tempVideoList })
        /* END */

        this.setState({ ArticlesMenu: MenuList.articleMenus, tempArticles: MenuList.articleMenus, VideoList: videoList.specialistVideos })
    }

    Questionclick = () => {
        this.props.history.push('Questions');
    }

    ArticlesClick = () => {
        this.props.history.push('Articles');
    }

    FamilyLaw = (category, e) => {
        this.props.PageName('FamilyLaw');
        this.props.AdviceCategory(category);
    }

    // CategoryMap = (x, a) => {
    //     debugger;
    //     if (x !== "ALL") {
    //         var tempList = this.state.tempArticles.filter((item) => x === item.category)
    //         var tempArticlesMenu = [];
    //         tempList.forEach(element => {
    //             tempArticlesMenu.push(element)
    //         });
    //         this.setState({ ArticlesMenu: tempArticlesMenu })
    //     }
    //     else {
    //         this.setState({ ArticlesMenu: this.state.tempArticles })
    //     }
    // }

    CategoryMap = (x, a) => {
        debugger;
        if (x !== "ALL") {
            var tempList = this.state.VideoList.filter((item) => x === item.speciality)
            var tempVideoList = [];
            tempList.forEach(element => {
                tempVideoList.push(element)
            });
            this.setState({ tempVideoList: tempVideoList })

            //     if (x === "Family Law") {
            //         var meta = document.createElement('meta');
            //         meta.title = "Find a Top Divorce Lawyer in your area - Free Consultations - MyWye";
            //         meta.content = "Our top rated divorce lawyers have all been vetted and are well known in their area.  You will find the top divorce Attorneys in your area.";
            //         document.getElementById("metaDatas").appendChild(meta);
            //     }
            //     else if (x === "Therapists/Counsleors") {
            //         var meta = document.createElement('meta');
            //         meta.title = "Marriage Counseling Videos from Top Therapists - Questions and Answers";
            //         meta.content = "Watch numerous videos from Top marriage counselors in your area covering all topics related to marriage counseling and also answering your common questions and concerns";
            //         document.getElementById("metaDatas").appendChild(meta);
            //     }
            //     else if (x === "Financial Experts") {
            //         var meta = document.createElement('meta');
            //         meta.title = "Financial Planning Videos from Top Financial Advisors - Questions and Answers";
            //         meta.content = "Watch numerous videos from Top financial advisors in your area covering all topics related to wealth management, estate management, financial planning  and also answering your common questions and concerns";
            //         document.getElementById("metaDatas").appendChild(meta);
            //     }
            // }
            // else {
            //     var meta = document.createElement('meta');
            //     meta.title = "Wide Array of Videos covering Divorce, Marriage Counseling, Child Custody, financial planning and more";
            //     meta.content = "Watch our videos made for you by top professionals in your area ranging from divorce, to marriage counseling to financial planning and wealth management";
            //     document.head.appendChild(meta);
            // }
        }
    }

    render() {
        return (
            <div>
                <div class="w-embed w-iframe">
                    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WQNXXP9" height="0" width="0" style={{ display: "none", visibility: "hidden" }}></iframe></noscript>
                </div>
                <Header></Header>
                <div id="metaDatas">
                    <MetaTags>
                        <title>Videos</title>
                        <meta name="keywords" content="professional online video conferences, virtual consults, guide to setting up video consults, how to do virtual consults"></meta>
                        <meta property="og:url" content="http://www.mywye.com/#/Videos" />
                        <meta property="og:type" content="website" />
                        <meta property="og:title" content="Videos on Divorce, Marriage Counseling, Child Custody, financial planning and more" />
                        <meta property="og:description" content="Watch our videos made for you by top professionals in your area ranging from divorce, to marriage counseling to financial planning and wealth management" />
                    </MetaTags>
                </div>
                <div id="TopImageCouple" youngmarriedcouple="Young Married Couple" class="page-section">
                    <div class="articleheader">
                        <div data-w-id="5746be0a-04be-61db-8461-b60eb1089db0" id="5fdfc34e7a4460f2e73bc65f" class="socialmediablock">
                            <a href="https://twitter.com/mywye1" data-w-id="7f87cc1d-3e6a-65ac-c733-e94b4f15809d" id="5fdfc34e7a4460f2e73bc65f" class="twittericon ResourceIcon"></a>
                            <a href="https://www.facebook.com/mywye1/" data-w-id="9900b81f-7cc0-ef9c-21ad-2d1af8a99ed9" class="fbicon ResourceIcon"></a>
                            <a data-w-id="60ff5a3e-4dc3-1153-3853-a56e0921d185" class="linkedinicon ResourceIcon"></a>
                            <a data-w-id="8ed028d5-a6f2-6cfc-ffdf-2152bbb65574" class="emailshare ResourceIcon"></a>
                        </div>
                        <div class="headingtitleblock">
                            <h1 class="headline-copy">We’ve gathered valuable information from experts spanning marriage, divorce and changing relationships </h1>
                            <div class="w-layout-grid categoriesselector">
                                <a id="w-node-bd1bbb4818d0-89cdb2e6" href="#" class="category w-inline-block" onClick={this.CategoryMap.bind(this, "Family Law")}>
                                    <div class="text-block-68">Family law<br /></div>
                                </a>
                                <a href="#" class="category w-inline-block" onClick={this.CategoryMap.bind(this, "Financial Experts")}>
                                    <div class="text-block-68">Financial<br /></div>
                                </a>
                                {/* <a href="#" class="category w-inline-block" onClick={this.CategoryMap.bind(this, "Mediation")}>
                                    <div class="text-block-68">Mediation<br /></div>
                                </a> 
                                <a href="#" class="category w-inline-block" onClick={this.CategoryMap.bind(this, "Child Counselor")}>
                                    <div class="text-block-68">Child Counselor<br /></div>
                                </a>*/}
                                <a href="#" class="category w-inline-block" onClick={this.CategoryMap.bind(this, "Therapist / Counselors")}>
                                    <div class="text-block-68">Therapist / Counselors<br /></div>
                                </a>
                                <a href="#" class="category w-inline-block" onClick={this.CategoryMap.bind(this, "Real Estate Agent")}>
                                    <div class="text-block-68">Real Estate Agent<br /></div>
                                </a>
                                {/* <a href="#" class="category w-inline-block" onClick={this.CategoryMap.bind(this, "Life Coach")}>
                                    <div class="text-block-68">Life Coach<br /></div>
                                </a> */}
                                <a href="#" class="category w-inline-block" onClick={this.CategoryMap.bind(this, "Estate Planners")}>
                                    <div class="text-block-68">Estate Planners<br /></div>
                                </a>
                                {/* <a href="#" class="category w-inline-block" onClick={this.CategoryMap.bind(this, "Insurance Experts")}>
                                    <div class="text-block-68">Insurance Experts<br /></div>
                                </a> */}
                                <a href="#" class="category w-inline-block" onClick={this.CategoryMap.bind(this, "Accountants / Tax Planners")}>
                                    <div class="text-block-68">Accountants / Tax Planners<br /></div>
                                </a>
                                {/* <a href="#" class="category w-inline-block" onClick={this.CategoryMap.bind(this, "Mortage Broker")}>
                                    <div class="text-block-68">Mortage Broker<br /></div>
                                </a> */}
                            </div>
                            {/* <div class="w-layout-grid articlesfinance">
                                {this.state.ArticlesMenu.length !== 0 ? this.state.ArticlesMenu.map((articles) => {
                                    return <div id="w-node-85187a6be990-89cdb2e6" data-w-id="e2965df9-4057-bd5a-ee8b-85187a6be990" class="articlepromo">
                                        <div class="articlestoryimage" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + `${articles.imageUrl}`})` }}></div>
                                        <div class="titletag">{articles.description}<br /><span class="text-span-23">{`${articles.category}`}</span></div>
                                        <a style={{ opacity: 0 }} href="#" class="overlay w-inline-block">
                                            <div class="text-block-69">Read the complete article Ask the author a question</div>
                                            <div class="qbutton-45" onClick={this.Questionclick}></div>
                                        </a>
                                    </div>
                                }) : null}
                            </div> */}
                            <div class="w-layout-grid articlesfinance">
                                {this.state.tempVideoList.length !== 0 ? this.state.tempVideoList.map((videos) => {
                                    return <div style={{ display: 'contents' }}>
                                        {videos.video1 !== "" ? videos.video1.includes('mywye') ?
                                            <div id="w-node-85187a6be990-89cdb2e6" data-w-id="e2965df9-4057-bd5a-ee8b-85187a6be990" class="articlepromo">
                                                <div class="articlestoryimage">
                                                    <Player id="VideoPlayer" class="Videoo-Js-Player" videoId="video-1" preload="metadata" muted playsInline={true} style={{ width: 100 }}>
                                                        <source
                                                            src={videos.video1}
                                                            type="video/mp4"
                                                        />
                                                        <BigPlayButton position="center" />
                                                    </Player>
                                                </div>
                                                <span>{videos.video1Caption}</span>
                                            </div> : <div class="articlepromo">
                                                <iframe class="articlestoryimage" src={videos.video1} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>

                                                </iframe>
                                            </div> : ""}

                                        {videos.video2 !== "" ? videos.video2.includes('mywye') ?
                                            <div id="w-node-85187a6be990-89cdb2e6" data-w-id="e2965df9-4057-bd5a-ee8b-85187a6be990" class="articlepromo">
                                                <div class="articlestoryimage">
                                                    <Player class="Videoo-JS-Player" id="VideoPlayer" videoId="video-1" muted playsInline={true} preload="metadata">
                                                        <source
                                                            src={videos.video2}
                                                            type="video/mp4"
                                                        />
                                                        <BigPlayButton position="center" />
                                                    </Player>
                                                </div>
                                                <span>{videos.video2Caption}</span>
                                            </div>
                                            : <div class="articlepromo">
                                                <iframe class="articlestoryimage" src={videos.video2} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>

                                                </iframe>
                                            </div> : ""}
                                        {videos.video3 !== "" ? videos.video3.includes('mywye') ?
                                            <div id="w-node-85187a6be990-89cdb2e6" data-w-id="e2965df9-4057-bd5a-ee8b-85187a6be990" class="articlepromo">
                                                <div class="articlestoryimage">
                                                    <Player id="VideoPlayer" videoId="video-1" muted playsInline={true} preload="metadata">
                                                        <source
                                                            src={videos.video3}
                                                            type="video/mp4"
                                                        />
                                                        <BigPlayButton position="center" />
                                                    </Player>
                                                </div>
                                                <span>{videos.video3Caption}</span>
                                            </div>
                                            : <div class="articlepromo">
                                                <iframe class="articlestoryimage" src={videos.video3} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>

                                                </iframe>
                                            </div> : ""}
                                        {videos.video4 !== "" ? videos.video4.includes('mywye') ?
                                            <div id="w-node-85187a6be990-89cdb2e6" data-w-id="e2965df9-4057-bd5a-ee8b-85187a6be990" class="articlepromo">
                                                <div class="articlestoryimage">
                                                    <Player id="VideoPlayer" videoId="video-1" muted playsInline={true} preload="metadata">
                                                        <source
                                                            src={videos.video4}
                                                            type="video/mp4"
                                                        />
                                                        <BigPlayButton position="center" />
                                                    </Player>
                                                    {/* <VideoPlayer
                                                    className="video-Js-Player"
                                                    controls={true}
                                                    src={videos.video4}
                                                    preload="metadata"
                                                    playsInline
                                                /> */}
                                                </div>
                                                <span>{videos.video4Caption}</span>
                                            </div>
                                            : <div class="articlepromo">
                                                <iframe class="articlestoryimage" src={videos.video4} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>

                                                </iframe>
                                            </div> : ""}
                                    </div>
                                }) : null}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="featues-section">
                    {/* <div class="bookomonth">
                        <div class="div-block-35-copy">
                            <div class="bookimage"></div>
                            <h3 class="subtitle">Book of the Month</h3>
                            <p class="product-description">Is it possible to write a sidesplitting novel about the breakup of the perfect marriage? If the writer is Nora Ephron, the answer is a resounding yes. For in this inspired confection of adultery, revenge, group therapy, and pot roast, the creator of Sleepless in Seattle reminds us...<br />‍<br /> <span class="text-span-21">GET THIS</span></p>
                        </div>
                    </div> */}
                    <div class="questionspromo">
                        <TopQuestions />
                    </div>
                    <div data-delay="4000" data-animation="cross" data-autoplay="1" data-easing="ease-in-quad" data-duration="500" data-infinite="1" class="bottomslider w-slider">
                        <div class="mask-3 w-slider-mask container">
                            <div class="bottomslidemaster w-slide">
                                <h1 class="peachyheadline a">What makes a great marriage ?<br />How do you keep it working ?<br />Get straight talk and real answers...</h1>
                            </div>
                            <div class="bottomslidemaster w-slide">
                                <h1 class="peachyheadline">What should you do if you are stuck in a marriage that is not working ?</h1>
                            </div>
                            <div class="bottomslidemaster w-slide">
                                <h1 class="peachyheadline">If your relationship is changing, there are resources, and a community of professionals to help right here.</h1>
                            </div>
                        </div>
                        <div class="left-arrow-3 w-slider-arrow-left">
                            <div class="w-icon-slider-left"></div>
                        </div>
                        <div class="right-arrow-3 w-slider-arrow-right">
                            <div class="w-icon-slider-right"></div>
                        </div>
                        <div class="slide-nav-2 w-slider-nav w-round"></div>
                    </div>
                </div>
                <BoxComponent></BoxComponent>
                <Footer></Footer>
            </div>
        )
    }
}

const mapStateToProp = state => {
    return {
        Value: state.Value,
        Category: state.Category
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        PageName: Value => dispatch(PageName(Value)),
        AdviceCategory: Category => dispatch(AdviceCategory(Category))
    }
}

export default connect(mapStateToProp, mapDispatchToProps)(Resource);