import { ServiceUrl } from '../ServiceURL';

export async function CheckMarriageTest() {
    let GetUserField = [];
    var tempUserId = sessionStorage.getItem('UserId');
    const userID = parseInt(tempUserId);
    const data = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ UserId: userID })
    };
    await fetch(ServiceUrl + '/api/CheckMarriageTest', data) //http://192.227.67.200/peachylifeapi/api/LoginUser
        .then((response) => response.json()) //https://peachylife.com/
        .then((responseJson) => {
            GetUserField = responseJson;
        })
    return GetUserField;
}