import { ServiceUrl } from '../ServiceURL';

export async function AddQuestion(clientId, questionCategory, question, clientName, clientEmail, clientState, Virtual) {
    let Success = '';
    const data = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            ClientId: clientId, QuestionCategory: questionCategory, Question: question,
            ClientName: clientName, ClientEmail: clientEmail, ClientState: clientState, VirtualConsultations: Virtual
        })
    };
    await fetch(ServiceUrl + '/api/AddQuestion', data) //http://192.227.67.200/peachylifeapi/api/AddQuestion //https://localhost:5001/api/AddQuestion
        .then((response) => response.json()) //https://peachylife.com/peachylifeapi/api/
        .then((responseJson) => {
            Success = responseJson;
        })
    return Success;
}

export async function GetAllFAQ() {
    let Success = '';
    const data = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };
    await fetch(ServiceUrl + '/api/GetAllFAQ', data) //https://localhost:5001/api/GetAllFAQ
        .then((response) => response.json()) //https://peachylife.com/peachylifeapi/api/GetAllFAQ
        .then((responseJson) => {
            Success = responseJson;
        })
    return Success;
}

export async function GetAnswered(Category) {
    let GetAnswered = [];
    const data = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ QuestionCategory: Category })
    };
    await fetch(ServiceUrl + '/api/GetAnswered', data) //http://192.227.67.200/peachylifeapi/api/LoginUser
        .then((response) => response.json()) //https://peachylife.com/PeachyLifeApi/api/
        .then((responseJson) => {
            GetAnswered = responseJson;
        })
    return GetAnswered;
}