import React from 'react';
import { withRouter } from 'react-router';
import { CheckMarriageTest } from './BoxComponentService';
import { Modal } from 'antd';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { PageName, AdviceCategory } from '../../Redux/Redux.Action';
import { ServiceURL, FilesURL } from '../ServiceURL';

class BoxComponent extends React.Component {
    constructor(props) {
        super(props);
    }

    showtest = async () => {
        if ((sessionStorage.getItem('UserId') !== "") || (sessionStorage.getItem('UserId') !== null)) {
            let exist = await CheckMarriageTest()
            if (exist === true) {
                Modal.confirm({
                    content: (<div><p>You have already taken a Test.</p><p style={{ marginLeft: 40 }}> Do you wish to take a Re-Test?</p></div>),
                    okText: "Take Test",
                    cancelText: "View Results",
                    onOk: this.RedirectToTest,
                    onCancel: this.showReport
                });
            }
            else {
                this.RedirectToTest();
            }
        }
        else { }
    }
    showReport = () => {
        this.props.history.push('/MarriageResult');
    }
    RedirectToTest = () => { this.props.history.push('/MarriageTest') }
    RedirectToArticle = () => {
        this.props.history.push({
            pathname: '/Articles',
            // search: '?query=abc',
            state: { category: "All" }
        })
    }
    RedirectToAllProfiles = () => {
        this.props.history.push('/AllProfiles')
    }
    RedirectToQuestions = () => {
        this.props.history.push('/Questions')
    }
    RedirectToPopularQnA = () => {
        this.props.history.push('/PopularQnA')
    }
    RedirectToFamilyLawArticle = () => {
        this.props.history.push({
            pathname: '/Articles',
            // search: '?query=abc',
            state: { category: "Family Law" }
        })
    }

    componentDidMount() {
        const script = document.createElement("script");
        script.src = FilesURL + "/Peachylife_/static/js/plmarriagetest3-944b06bbb-864f41afbdea5.js";
        script.async = true;

        document.body.appendChild(script);

        this.props.PageName("")
    }

    render() {
        return (
            <div>
                <div class="promo-section">
                    <div class="w-layout-grid grid-2">
                        <div class="hoverboard">
                            <a data-w-id="469109ed-4115-aa79-9031-733e44eda3ce" href="#" class="card_base w-inline-block gridimgeffect">
                                <div class="card_title_wrap">
                                    <div class="text_features">How strong is your marriage?</div>
                                    <h1 onClick={this.showtest} class="h1_features">Take the Test<br /></h1>
                                </div>
                            </a>
                        </div>
                        <div id="w-node-c313fa8338ec-6c84a99a" class="hoverboard">
                            <a data-w-id="0c705982-d2e4-8b06-b2b8-c313fa8338ed" href="#" class="card_base b w-inline-block gridimgeffect">
                                <div class="card_title_wrap">
                                    <div class="text_features b" onClick={this.RedirectToAllProfiles}>What does your financial future look like</div>
                                    <div data-w-id="571d2dab-aa3f-8499-fb71-4879350afb2e" class="text_features-q">?</div>
                                </div>
                            </a>
                        </div>
                        <div class="hoverboard">
                            <a data-w-id="29f7f042-4204-8185-4eb0-d3df328f121f" href="#" class="card_base c w-inline-block gridimageScale">
                                <div class="card_title_wrap" onClick={this.RedirectToAllProfiles}>
                                    <div data-w-id="29f7f042-4204-8185-4eb0-d3df328f1221" class="text_features c">Get help to<br />save your<br />marriage</div>
                                </div>
                            </a>
                        </div>
                        <div class="hoverboard">
                            <a data-w-id="385aa8d4-8dad-5a0b-a02f-10e23d085d50" href="#" class="card_base d w-inline-block gridimgeffect">
                                <div class="card_title_wrap" onClick={this.RedirectToQuestions}>
                                    <div class="text_features b">Virtual consults are<br />available</div>
                                    <h1 class="h1_features b">more<br /></h1>
                                </div>
                            </a>
                        </div>
                        <div class="hoverboard">
                            <a data-w-id="426535be-2acf-ac8f-b5c9-d3804fc32c47" href="#" class="card_base e w-inline-block gridimgeffect">
                                <div class="card_title_wrap" onClick={this.RedirectToAllProfiles}>
                                    <div class="text_features d">When it's time to move on...</div>
                                    <h1 class="h1_features d">Are you ready for divorce?<br /></h1>
                                </div>
                            </a>
                        </div>
                        <div id="w-node-a05ccb552283-6c84a99a" class="hoverboard">
                            <a data-w-id="d92df393-ae5e-8394-8c67-a05ccb552284" href="#" onClick={this.RedirectToPopularQnA} class="card_base f w-inline-block gridimageScale">
                                <div class="card_title_wrap">
                                    {/* <div class="text_features f">Strengthen<br />your <br />relationship</div> */}
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProp = state => {
    return {
        Value: state.Value,
        Category: state.Category
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        PageName: Value => dispatch(PageName(Value)),
        AdviceCategory: Category => dispatch(AdviceCategory(Category))
    }
}

export default compose(withRouter, connect(mapStateToProp, mapDispatchToProps))(BoxComponent);