import React from 'react'
import { connect } from 'react-redux';
import { PageName, AdviceCategory } from '../../Redux/Redux.Action';
import BoxComponent from '../BoxComponent/BoxComponent'
import { GetPageInformation } from './ProfileService'
import { Row, Col, Rate } from 'antd';
import MetaTags from 'react-meta-tags';
import Footer from '../Footer/Footer';
import Header from '../Dashboard/Dashboard';
import ReactGA from 'react-ga';

class AllProfiles extends React.Component {
    constructor(props) {
        super(props);
        this.state = { ProfessionalCategory: [], Experts: [], PageDetails: [], tempExperts: [] }
        this.myDivToFocus = React.createRef();
    }

    async componentDidMount() {
        ReactGA.initialize('G-JT4HS6ZZSX');
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.gtag('event', 'conversion', { 'send_to': 'AW-10806936548/AfZeCJKY9YQDEOSHk6Eo' });

        var ProfessionalProfiles = await GetPageInformation("all");
        this.setState({ Experts: ProfessionalProfiles.getAdviceList, tempExperts: ProfessionalProfiles.getAdviceList })
    }

    orderbyrating = async () => {
        debugger;
        var ProfessionalProfiles = this.state.tempExperts.sort((a, b) => (a.averageRating > b.averageRating ? -1 : 1))
        this.setState({
            Experts: ProfessionalProfiles
        })
    }

    orderbyexperience = async () => {
        debugger;
        var EXPERIENCE = this.state.tempExperts.sort((a, b) => (a.experience > b.experience ? -1 : 1))
        this.setState({
            Experts: EXPERIENCE
        })
    }

    ProfileClick = (data) => {
        debugger;
        parseInt(data.expertId)
        sessionStorage.setItem("ExpertId", data.expertId)
        this.props.history.push('/Profile/' + data.username)
    }

    QuestionClick = () => {
        this.props.history.push('/Questions')
    }

    render() {
        return (
            <div>
                <Header />
                <MetaTags>
                    <title>All Profiles</title>
                    <meta property="og:url" content="http://www.mywye.com/#/Advice" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="Find a Top Divorce Lawyer in your area - Free Consultations - MyWye" />
                </MetaTags>
                <div className="page-section">
                    <div class="searchheader">
                        <div class="div-block-55">
                            <div class="headline">Trusted Professional {this.state.ProfessionalCategory.length > 0 ? this.state.ProfessionalCategory[0].pageTitle : null} Practitioners</div>
                            {/* <div class="searchcriteriabuttons">
                                <div class="searchbuttonoutlne-copy" style={{cursor:"pointer"}}>
                                    <div class="buttontextpink" onClick={this.orderbyrating}>BY RATING</div>
                                </div>
                                <div class="searchbuttonoutlne-copy" style={{cursor:"pointer"}}>
                                    <div class="buttontextpink">WITH A FIRM</div>
                                </div>
                                <div class="searchbuttonoutlne-copy" style={{cursor:"pointer"}}>
                                    <div class="buttontextpink">MATCH STYLE</div>
                                </div>
                                <div class="searchbuttonoutlne-copy" style={{cursor:"pointer"}}>
                                    <div class="buttontextpink">BY LOCATION</div>
                                </div>
                                <div class="searchbuttonoutlne-copy" style={{cursor:"pointer"}}>
                                    <div class="buttontextpink" onClick={this.orderbyexperience}>EXPERIENCE</div>
                                </div>
                            </div> */}
                        </div>

                        <Row ref={this.myDivToFocus}>
                            <div class="w-layout-grid search-professionals-profile">
                                {this.state.Experts.map((ExpertList, index) => {
                                    return <Col md={8} sm={20} xs={24}>
                                        <div id="w-node-0be2bd9d11ca-cca2022f" class="search-profiles-roundv" onClick={() => this.ProfileClick(ExpertList)}>
                                            <div class="searchprofilepic a"><img src={`${ExpertList.imageUrl}`} loading="lazy" alt="" class="ExpertImage" /></div>
                                            <div class="searchtitletag-round">{`${ExpertList.firstName}`} {`${ExpertList.lastName}`}<br /><span class="searchsubtitle">{ExpertList.category}</span></div>
                                            <div class="reviewssearch">{ExpertList.reviewsCount} reviews<br /><span class="text-span-27"><Rate disabled value={ExpertList.averageRating}></Rate></span><span class="text-span-26"><br />9 Contacts<br />1 Article<br />{ExpertList.city} {ExpertList.state}<br />{ExpertList.experience <= 10 ? ExpertList.experience + `${" yrs"}` : "10+ yrs"}</span><br /></div>
                                            <div class="searchprofilebutton">
                                                <div class="buttontextwhite-copy">VIEW PROFILE</div> {/* .bind(this,ExpertList.expertId) */}
                                            </div>
                                        </div>
                                        <div class="ask-a-q" onClick={this.QuestionClick}></div>
                                        {/*<img src={process.env.PUBLIC_URL + `${ExpertList.imageUrl}`} loading="lazy" alt="" class="ExpertImage" /> */}
                                    </Col>
                                })}
                            </div>
                        </Row>
                    </div>
                </div>
                <div className="specialty-subjectbackground"></div>
                <Footer />
            </div>
        )
    }
}
export default AllProfiles;