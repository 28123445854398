import { ServiceUrl } from '../ServiceURL';

export async function GetMetaTags(ExpertId, Category) {
    let Success = '';
    const data = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            ExpertId: ExpertId, Category: Category
        })
    };
    await fetch(ServiceUrl + '/api/GetMetaTags', data) 
        .then((response) => response.json()) 
        .then((responseJson) => {
            Success = responseJson;
        })
    return Success;
}