import React from 'react';
import { FacebookShareButton, TwitterShareButton } from "react-share";
import {ServiceUrl, FilesURL} from '../ServiceURL';

class Social extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div> {/* script.src = "https://mywye.com/Peachylife_/static/js/plmarriagetest3-944b06bbb-864f41afbdea5.js"; */}
                <div class="w-layout-grid gridinvite">
                    <div id="w-node-5716af9326d7-933ed30c" class="div-block-12"></div>
                    <div facebookpost="Peachy Life post on Facebook" id="w-node-5716af9326d5-933ed30c" class="fb-share"></div>
                    <div id="w-node-5716af9326d8-933ed30c" class="resultsone form" style={{textAlign:'left'}}>
                        <h1 class="headline">Connect with the World</h1>
                        <FacebookShareButton
                            url="https://mywye.com/#/"
                            quote="Hi"
                            hashtag="#programing joke"
                            style={{outline:"none"}}>
                            <div class="social-media-buttons a"></div>
                        </FacebookShareButton>
                        <a class="social-media-buttons b" href="https://instagram.com/api/v1/media/upload/"></a>
                        <TwitterShareButton
                            url="https://mywye.com/#/"
                            quote="Hi"
                            hashtag="#programing joke"
                            style={{outline:"none"}}>
                            <div class="social-media-buttons t"></div>
                        </TwitterShareButton>
                    </div>
                </div>
            </div>
        )
    }
}
export default Social