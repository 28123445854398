import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Modal, Spin, Collapse, Row, Col, Carousel } from 'antd';
import MetaTags from 'react-meta-tags';
import ReactGA from 'react-ga';
import { PageName, AdviceCategory, PageLoader } from '../../Redux/Redux.Action';
import 'antd/dist/antd.css';
import BoxComponent from '../BoxComponent/BoxComponent';
import { CheckMarriageTest } from '../BoxComponent/BoxComponentService';
import Footer from '../Footer/Footer';
import Header from '../Dashboard/Dashboard';
import TopQuestions from '../TopQuestions/TopQuestions';
import { ServiceUrl, FilesURL } from '../ServiceURL';
import { GetMenuDetails } from '../Home/HomeService';
import { GetAnswered } from '../Question/questionService';


class NewHome extends React.Component {
    constructor(props) {
        super(props);
        this.state = { componentvalue: [{ component: '' }], CategoriesList: [], CatQuestionsList: [], }
    }

    async componentDidMount() {
        import('../Css/components.css')
        import('../Css/normalize.css')
        import('../Css/plmarriagetest.css')

        ReactGA.initialize('G-JT4HS6ZZSX');
        ReactGA.pageview(window.location.pathname + window.location.search);

        window.gtag('event', 'conversion', { 'send_to': 'AW-10806936548/AfZeCJKY9YQDEOSHk6Eo' });

        const script = document.createElement("script");
        //script.src = "http://192.227.67.200/Peachylife_/static/js/plmarriagetest3-944b06bbb-864f41afbdea5.js";
        script.src = FilesURL + "/Peachylife_/static/js/plmarriagetest3-944b06bbb-864f41afbdea5.js";
        script.async = true;
        document.body.appendChild(script);
        window.scrollTo({ top: 0 })

        let categories = await GetMenuDetails();
        let catQuestionsList = await GetAnswered("Family Law");
        this.setState({ CategoriesList: categories.adviceMenus, CatQuestionsList: catQuestionsList.question })

        if ((parseInt(sessionStorage.getItem("LoadPage")) + 1) === 1) {
            sessionStorage.setItem("LoadPage", 1)
            window.location.reload(true)
        }
    }

    gtag_report_conversion = (url) => {
        var callback = function () {
            if (typeof (url) != 'undefined') {
                window.location = url;
            }
        };
        window.gtag('event', 'conversion', { 'send_to': 'AW-10806936548/AfZeCJKY9YQDEOSHk6Eo', 'event_callback': callback });
    }

    questionclick = () => {
        this.props.history.push('/Questions');
    }

    RedirectToAllProfiles = () => {
        this.props.history.push('AllProfiles');
    }

    ContentLoad(data) {
        let comvalue = this.state.componentvalue
        comvalue[0].component = data.component;
        this.setState({
            componentvalue: comvalue,
        })
    }

    //Function to fetch all the questions and answers for specific category!
    getCatQuesList = async (category) => {
        let catQuestionsList = await GetAnswered(category);
        this.setState({ CatQuestionsList: catQuestionsList.question })
    }

    showtest = async () => {
        if ((sessionStorage.getItem('UserId') !== "") && (sessionStorage.getItem('UserId') !== null) && (sessionStorage.getItem('UserId') !== "null")) {
            let exist = await CheckMarriageTest()
            if (exist === true) {
                Modal.confirm({
                    content: (<div><p>You have already taken a Test.</p><p style={{ marginLeft: 40 }}> Do you wish to take a Re-Test?</p></div>),
                    okText: "Take Test",
                    cancelText: "View Results",
                    onOk: this.RedirectToTest,
                    onCancel: this.showReport
                });
            }
            else {
                this.RedirectToTest();
            }
        }
        else {
            this.props.history.push('/MarriageTest')
        }
    }
    showReport = () => {
        this.props.history.push('/MarriageResult')
    }
    RedirectToTest = () => {
        this.props.history.push('/MarriageTest')
    }

    render() {

        const SEOJson = {
            "@context": "https://schema.org",
            "@type": "Informative",
            "Title": "Professional Advice, Resources and Community for Couples",
            "Publisher": "Peachy Life",
            "Genre": "instructional",
            "description": "Connect with top peer-reviewed specialists through video profiles. Content and resources for couples; legal, financial, counselling, therapy and guidance. Get the answers you need.",
            "interactionStatistic": {
                "@type": "InteractionCounter",
                "interactionType": { "@type": "http://schema.org/WatchAction" },
                "userInteractionCount": 5647018
            },
        }

        const { Panel } = Collapse;

        return (
            <div>
                <MetaTags>
                    <title>Home</title>
                    <meta property="og:url" content="http://www.mywye.com/#/" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="MyWye - Divorce, Child Custody Lawyers in your area, Questions and Answers, Marriage Counseling and More" />
                    <meta property="og:description" content="MyWye is a resource for anyone contemplating a divorce or going through a divorce.  Our network connects with you with all the resources you need in order to protect yourself in the short and long term - including divorce lawyers, marriage counseling, financial experts, mediation and much more" />
                    {/* <meta property="og:image" content="http://mywye.com/images/PeachyLife-HomepageScreen1.png" /> */}
                </MetaTags>
                <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(SEOJson) }} />

                <div class="w-embed w-iframe">
                    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WQNXXP9" height="0" width="0" style={{ display: "none", visibility: "hidden" }}></iframe></noscript>
                    <script src={FilesURL + "/Peachylife_/static/js/plmarriagetest3-944b06bbb-864f41afbdea5.js"} crossOrigin="anonymous"></script>
                </div>
                <Header></Header>
                <div class="introsection">
                    <div class="homeslide w-slider newHomeSLide"> {/* data-delay="3000" data-animation="cross" data-autoplay="1" data-duration="500" data-infinite="1"  */}
                        <Carousel dotPosition="top" autoplay> {/* autoplay */}
                            <div class="homeslide3 w-slide" onClick={this.RedirectToTest}>
                                <img className="CarouselImgs" src={process.env.PUBLIC_URL + "/images/Home1.jpg"} />
                            </div>
                            <div className="home-slide1 w-slide" onClick={this.RedirectToAllProfiles}>
                                <img className="CarouselImgs" src={process.env.PUBLIC_URL + "/images/Home2.jpg"} />
                            </div>
                            <div class="homeslide2 w-slide" onClick={this.RedirectToAllProfiles}>
                                <img className="CarouselImgs" src={process.env.PUBLIC_URL + "/images/Home3.jpg"} />
                            </div>
                            <div class="homeslide4 w-slide" onClick={this.questionclick}>
                                <img className="CarouselImgs" src={process.env.PUBLIC_URL + "/images/Home4.jpg"} />
                            </div>
                        </Carousel>
                    </div>
                </div>
                <div class="headlinesection marTophead">
                    {/* <h1 class="peachyheadline margin-left">Professional advice, resources and community all to help you make your life <span className="cardcontainer" style={{ color: "#eb616c" }}>Peachy...</span></h1> */}
                    <h1 class="peachyheadline margin-left">Professional advice, resources and community by <span className="cardcontainer" style={{ color: "#eb616c" }}>MyWye...</span></h1>
                </div>

                <div>
                    <h1 class="peachyheadline">Questions sorted in categories</h1>
                    <div class="article-body popularCategories">
                        <div class="w-layout-grid categoriesselector articlescategory">
                            {this.state.CategoriesList.map((data) => {
                                return <a href="#" class="category w-inline-block" onClick={() => this.getCatQuesList(data.menuName)}>
                                    <div class="text-block-68">{data.category}<br /></div>
                                </a>
                            })}
                        </div>
                    </div>
                </div>
                <div class="headlinesection">
                    <div className="FAQCollapse">
                        <Collapse accordion bordered={false} className="site-collapse-custom-collapse qandaCollapse">
                            {this.state.CatQuestionsList.length !== 0 ? this.state.CatQuestionsList.map((data, index) => {
                                return <Panel header={data.question} key={index}>
                                    <p className="ansCollapse">{data.answer}</p>
                                    <a className="ansCollapse" href={FilesURL + "/#/" + data.userName} target="_blank"> Answered by - {data.expertFirstName} {data.expertLastName}</a>
                                </Panel>
                            }) : <span className="descline">No Q & A Available</span>}
                        </Collapse>
                    </div>
                </div>

                <BoxComponent></BoxComponent>
                <div class="featues-section">
                    {/* <div class="bookomonth">
                        <div class="bookimage"></div>
                        <div class="div-block-35">
                            <h3 class="subtitle">Book of the Month</h3>
                            <p class="product-description">Is it possible to write a sidesplitting novel about the breakup of the perfect marriage? If the writer is Nora Ephron, the answer is a resounding yes. For in this inspired confection of adultery, revenge, group therapy, and pot roast, the creator of Sleepless in Seattle reminds us...<br />‍<br /> <span class="text-span-21">GET THIS</span></p>
                        </div>
                    </div> */}
                    <div class="questionspromo">
                        <div class="section2">
                            <TopQuestions />
                        </div>
                    </div>
                    <div data-delay="4000" data-animation="cross" data-autoplay="1" data-easing="ease-in-quad" data-duration="500" data-infinite="1" class="bottomslider w-slider">
                        <div class="mask-3 w-slider-mask container">
                            <div class="bottomslidemaster w-slide">
                                <h1 class="peachyheadline a">What makes a great marriage ?<br />How do you keep it working ?<br />Get straight talk and real answers...</h1>
                            </div>
                            <div class="bottomslidemaster w-slide">
                                <h1 class="peachyheadline"><br />What to do if you are stuck in a<br />marriage that is not working ?</h1>
                            </div>
                            <div class="bottomslidemaster w-slide">
                                <h1 class="peachyheadline">If you are struggling in a marriage, there are resources, community and professionals to help right here.</h1>
                            </div>
                        </div>
                        <div class="left-arrow-3 w-slider-arrow-left">
                            <div class="w-icon-slider-left"></div>
                        </div>
                        <div class="right-arrow-3 w-slider-arrow-right">
                            <div class="w-icon-slider-right"></div>
                        </div>
                        <div class="slide-nav-2 w-slider-nav w-round"></div>
                    </div>
                </div>
                <Footer></Footer>
            </div>
        )
    }
}

export default NewHome;