import { ServiceUrl } from '../ServiceURL';

export async function GetAllExpertVideos(Speciality) {
    let Success = '';
    const data = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ Speciality })
    };
    await fetch(ServiceUrl + '/api/GetAllExpertVideos', data)
        .then((response) => response.json()) //https://peachylife.com/peachylifeapi/api/
        .then((responseJson) => { //https://localhost:5001/api/GetAllExpertVideos
            Success = responseJson;
        })
    return Success;
}