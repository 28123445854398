import { ServiceUrl } from '../ServiceURL';

export async function GetUserProfileId(eMail) {
    let GetUserField = [];
    const data = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ Email: eMail})
    };
    await fetch(ServiceUrl + '/api/GetUserProfileId', data) //https://localhost:5001/api/GetUserProfileId //http://192.227.67.200/peachylifeapi/api/GetUserProfileId
        .then((response) => response.json()) //https://peachylife.com/PeachyLifeApi/api/
        .then((responseJson) => {
            GetUserField = responseJson;
        })
    return GetUserField;
}