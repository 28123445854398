import { ServiceUrl } from '../ServiceURL';

export async function RegisterUser(name, email, Password) {
    debugger;
    let GetUserField = [];
    const data = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ UserName: name, Email: email, Password: Password, PageType: 'PeachyLife' })
    };
    await fetch(ServiceUrl + '/api/RegisterUser', data) //http://192.227.67.200/peachylifeapi/api/RegisterUser //https://localhost:5001/api/RegisterUser
        .then((response) => response.json()) //https://peachylife.com/peachylifeapi/api/
        .then((responseJson) => {
            GetUserField = responseJson;
        })
    return GetUserField;
}

export async function GetUserInformation() {
    let Success = '';
    var tempUserId = sessionStorage.getItem('UserId');
    const UserId = parseInt(tempUserId);
    const data = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ UserId: UserId })
    };
    await fetch(ServiceUrl + '/api/GetUserInfo', data) //http://192.227.67.200/peachylifeapi/api/GetUserInfo
        .then((response) => response.json()) //https://peachylife.com/peachylifeapi/api/
        .then((responseJson) => {
            Success = responseJson;
        })
    return Success;
}

export async function SaveUserInformation(SaveData) {
    debugger;
    let Success = '';
    let UserId = sessionStorage.getItem('UserId');
    const data = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            UserId: parseInt(UserId), Age: parseInt(SaveData[0]), City: SaveData[1], Comments: SaveData[2], ContactPreference: SaveData[3], Specialist: SaveData[4],
            MaritalStatus: SaveData[5], Gender: SaveData[6], State: SaveData[7], StyleMatch: SaveData[8], VideoConference: SaveData[9]
        })
    };
    await fetch(ServiceUrl + '/api/SaveUserDetails', data) //http://192.227.67.200/peachylifeapi/api/SaveUserDetails
        .then((response) => response.json()) //https://peachylife.com/peachylifeapi/api/
        .then((responseJson) => {
            Success = responseJson;
        })
    return Success;
}

export async function CheckEmail(email) {
    let Success = '';
    var tempUserId = sessionStorage.getItem('UserId');
    const UserId = parseInt(tempUserId);
    const data = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ Email: email })
    };
    await fetch(ServiceUrl + '/api/CheckEmail', data) //http://192.227.67.200/peachylifeapi/api/GetUserInfo
        .then((response) => response.json()) //https://peachylife.com/peachylifeapi/api/
        .then((responseJson) => {
            Success = responseJson;
        })
    return Success;
}