import React from 'react';
import { Collapse } from 'antd';
import MetaTags from 'react-meta-tags';
import 'antd/dist/antd.css';
import BoxComponent from '../BoxComponent/BoxComponent';
import Footer from '../Footer/Footer';
import Header from '../Dashboard/Dashboard';
import { GetAllFAQ, GetAnswered } from './questionService';
import TopQuestions from '../TopQuestions/TopQuestions';
import { ServiceUrl, FilesURL } from '../ServiceURL';
import { GetMenuDetails } from '../Home/HomeService';
import ReactGA from 'react-ga';

class PopularQuestions extends React.Component {
    constructor(props) {
        super(props);
        this.state = { CategoriesList: [], QuestionList: [], CatQuestionsList: [] }
    }

    async componentDidMount() {
        debugger;
        import('../Css/components.css')
        import('../Css/normalize.css')
        import('../Css/plmarriagetest.css')

        const script = document.createElement("script");
        //script.src = "http://192.227.67.200/Peachylife_/static/js/plmarriagetest3-944b06bbb-864f41afbdea5.js";
        script.src = FilesURL + "/Peachylife_/static/js/plmarriagetest3-944b06bbb-864f41afbdea5.js";
        script.async = true;
        document.body.appendChild(script);

        ReactGA.initialize('G-JT4HS6ZZSX');
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.gtag('event', 'conversion', { 'send_to': 'AW-10806936548/AfZeCJKY9YQDEOSHk6Eo' });


        window.scrollTo({ top: 0 })

        let categories = await GetMenuDetails();
        let questionList = await GetAllFAQ();
        let catQuestionsList = await GetAnswered("Family Law");
        this.setState({ QuestionList: questionList.question, CatQuestionsList: catQuestionsList.question, CategoriesList: categories.adviceMenus })
    }

    questionclick = () => {
        this.props.history.push('/Questions');
    }

    //Function to fetch all the questions and answers for specific category!
    getCatQuesList = async (category) => {
        let catQuestionsList = await GetAnswered(category);
        this.setState({ CatQuestionsList: catQuestionsList.question })
    }

    gtag_report_conversion(url) {
        // window.location = url;
        // var callback = function () {
        //     if (typeof (url) != 'undefined') {
        //         window.location = url;
        //     }
        // };'event_callback': callback 
        window.gtag('event', 'conversion', { 'send_to': 'AW-10806936548/AfZeCJKY9YQDEOSHk6Eo', });
        return false;
    }

    render() {

        const SEOJson = {
            "@context": "https://schema.org",
            "@type": "Informative",
            "Title": "Professional Advice, Resources and Community for Couples",
            "Publisher": "Peachy Life",
            "Genre": "instructional",
            "description": "Connect with top peer-reviewed specialists through video profiles. Content and resources for couples; legal, financial, counselling, therapy and guidance. Get the answers you need.",
            "interactionStatistic": {
                "@type": "InteractionCounter",
                "interactionType": { "@type": "http://schema.org/WatchAction" },
                "userInteractionCount": 5647018
            },
        }

        const { Panel } = Collapse;

        return (
            <div>
                <MetaTags>
                    <title>PopularQnA</title>
                    <meta property="og:url" content="http://www.mywye.com/#/PopularQnA" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="Divorce FAQ, Attorney Answer Questions, Child Custody and more " />
                </MetaTags>
                <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(SEOJson) }} />

                <div class="w-embed w-iframe">
                    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WQNXXP9" height="0" width="0" style={{ display: "none", visibility: "hidden" }}></iframe></noscript>
                    <script src={FilesURL + "/Peachylife_/static/js/plmarriagetest3-944b06bbb-864f41afbdea5.js"} crossOrigin="anonymous"></script>
                </div>
                <Header></Header>
                {/* <div class="headlinesection">
                    <h1 class="peachyheadline margin-left">Professional advice, resources and community by <span className="cardcontainer" style={{ color: "#eb616c" }}>MyWye...</span></h1>
                </div> */}

                <div class="headlinesection">
                    <div className="FAQCollapse">
                        <h1 class="peachyheadline">Questions Answered by the Pros</h1>
                        <Collapse accordion bordered={false} className="site-collapse-custom-collapse qandaCollapse">
                            {this.state.QuestionList.length !== 0 ? this.state.QuestionList.map((data, index) => {
                                return <Panel header={data.question} key={index}>
                                    <p className="ansCollapse">{data.answer}</p>
                                    <a className="ansCollapse" href={FilesURL + "/#/" + data.userName} onClick={() => this.gtag_report_conversion(window.location)} target="_blank"> Answered by - {data.expertFirstName} {data.expertLastName}</a>
                                </Panel>
                            }) : null}
                        </Collapse>
                    </div>
                </div>

                <div>
                    <h1 class="peachyheadline">Questions sorted in categories</h1>
                    <div class="article-body popularCategories">
                        <div class="w-layout-grid categoriesselector articlescategory">
                            {/* <a id="w-node-bd1bbb4818d0-89cdb2e6" href="#" class="category w-inline-block" onClick={() => this.getCatQuesList("Family Law")}>
                                <div class="text-block-68">Family Law<br /></div>
                            </a>
                            <a href="#" class="category w-inline-block" onClick={() => this.getCatQuesList("Insurance Experts")}>
                                <div class="text-block-68">Insurance Experts<br /></div>
                            </a>
                            <a href="#" class="category w-inline-block" onClick={() => this.getCatQuesList("Financial Experts")}>
                                <div class="text-block-68">Financial Experts<br /></div>
                            </a>
                            <a href="#" class="category w-inline-block" onClick={() => this.getCatQuesList("Accountants / Tax Planners")}>
                                <div class="text-block-68">Accountants / Tax Planners<br /></div>
                            </a>
                            <a href="#" class="category w-inline-block" onClick={() => this.getCatQuesList("Child Counselor")}>
                                <div class="text-block-68">Child Counselor<br /></div>
                            </a>
                            <a href="#" class="category w-inline-block" onClick={() => this.getCatQuesList("Mediation")}>
                                <div class="text-block-68">Mediation<br /></div>
                            </a>
                            <a href="#" class="category w-inline-block" onClick={() => this.getCatQuesList("Therapist / Counselors")}>
                                <div class="text-block-68">Therapist / Counselors<br /></div>
                            </a>
                            <a href="#" class="category w-inline-block" onClick={() => this.getCatQuesList("Life Coach")}>
                                <div class="text-block-68">Life Coach<br /></div>
                            </a>
                            <a href="#" class="category w-inline-block" onClick={() => this.getCatQuesList("Real Estate Agent")}>
                                <div class="text-block-68">Real Estate<br /></div>
                            </a> */}
                            {this.state.CategoriesList.map((data) => {
                                return <a href="#" class="category w-inline-block" onClick={() => this.getCatQuesList(data.menuName)}>
                                    <div class="text-block-68">{data.category}<br /></div>
                                </a>
                            })}
                        </div>
                    </div>
                </div>

                <div class="headlinesection">
                    <div className="FAQCollapse">
                        {/* <h1 class="peachyheadline">....</h1> */}
                        <Collapse accordion bordered={false} className="site-collapse-custom-collapse qandaCollapse">
                            {this.state.CatQuestionsList.length !== 0 ? this.state.CatQuestionsList.map((data, index) => {
                                return <Panel header={data.question} key={index}>
                                    <p className="ansCollapse">{data.answer}</p>
                                    <a className="ansCollapse" href={FilesURL + "/#/" + data.userName} target="_blank"> Answered by - {data.expertFirstName} {data.expertLastName}</a>
                                </Panel>
                            }) : <span className="descline">No Q & A Available</span>}
                        </Collapse>
                    </div>
                </div>

                <BoxComponent></BoxComponent>
                <div class="featues-section">
                    <div class="questionspromo">
                        <div class="section2">
                            <TopQuestions />
                        </div>
                    </div>
                    <div data-delay="4000" data-animation="cross" data-autoplay="1" data-easing="ease-in-quad" data-duration="500" data-infinite="1" class="bottomslider w-slider">
                        <div class="mask-3 w-slider-mask container">
                            <div class="bottomslidemaster w-slide">
                                <h1 class="peachyheadline a">What makes a great marriage ?<br />How do you keep it working ?<br />Get straight talk and real answers...</h1>
                            </div>
                            <div class="bottomslidemaster w-slide">
                                <h1 class="peachyheadline"><br />What to do if you are stuck in a<br />marriage that is not working ?</h1>
                            </div>
                            <div class="bottomslidemaster w-slide">
                                <h1 class="peachyheadline">If you are struggling in a marriage, there are resources, community and professionals to help right here.</h1>
                            </div>
                        </div>
                        <div class="left-arrow-3 w-slider-arrow-left">
                            <div class="w-icon-slider-left"></div>
                        </div>
                        <div class="right-arrow-3 w-slider-arrow-right">
                            <div class="w-icon-slider-right"></div>
                        </div>
                        <div class="slide-nav-2 w-slider-nav w-round"></div>
                    </div>
                </div>
                <Footer></Footer>
            </div>
        )
    }
}

export default PopularQuestions