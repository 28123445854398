import React from 'react';
import { Button, Modal, Input, Slider, Popover, Space, Carousel, Menu, Dropdown, Row, Col, Card, Divider, Form, Radio, Checkbox } from 'antd';
import 'antd/dist/antd.css';
import '../Css/Peachy.css';
import { connect } from 'react-redux';
import { PageName } from '../../Redux/Redux.Action';
import Information from '../Information/Information';
import BoxComponent from '../BoxComponent/BoxComponent'
import { ChangeEmail, ChangePassword, CheckMarriageTest } from './MyAccountService';
import { CheckEmail } from '../Register/RegisterService';
import Footer from '../Footer/Footer';
import Header from '../Dashboard/Dashboard';
import InviteFriend from '../InviteFriend/InviteFriend';
import ReactGA from 'react-ga';
import { MetaTags } from 'react-meta-tags';

class MyAccount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Name: '', Email: '', Password: '', RepeatPassword: '', loginShow: false, AdviceMenu: [], ArticlesMenu: [],
            ChangePassword: false, OldPassword: '', ChangeEmail: false, NewEmail: '', ReEnterEmail: '', AccountChange: false,
            InviteFriend: false,
        }

        this.ChangePassw = React.createRef();
        this.Invites = React.createRef();
        this.ChangeMail = React.createRef();
        this.AccountChanges = React.createRef();
    }

    async componentDidMount() {
        this.props.PageName("")

        ReactGA.initialize('G-JT4HS6ZZSX');
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.gtag('event', 'conversion', { 'send_to': 'AW-10806936548/AfZeCJKY9YQDEOSHk6Eo' });

    }

    behavior = (x) => {
        x.current.scrollIntoView({
            behavior: "smooth",
            block: "nearest"
        })
    }

    showChangePassword = () => {
        this.setState({ ChangePassword: true, ChangeEmail: false, AccountChange: false, InviteFriend: false })
        var x = document.getElementById('ChangePasswordDiv')
        x.scrollIntoView({ behavior: "smooth", })
    }
    showChangeEmail = () => {
        this.setState({ ChangeEmail: true, ChangePassword: false, AccountChange: false, InviteFriend: false })
        var x = document.getElementById('ChangePasswordDiv')
        x.scrollIntoView({ behavior: "smooth", })
    }
    AccountChangeClick = () => {
        this.setState({ ChangeEmail: false, ChangePassword: false, AccountChange: true, InviteFriend: false })
        var x = document.getElementById('ChangePasswordDiv')
        x.scrollIntoView({ behavior: "smooth", })
    }
    InviteClick = () => {
        this.setState({ ChangeEmail: false, ChangePassword: false, AccountChange: false, InviteFriend: true })
        var x = document.getElementById('ChangePasswordDiv')
        x.scrollIntoView({ behavior: "smooth", })
    }
    componentWillMount() {
        import('../Css/components.css')
        import('../Css/normalize.css')
        import('../Css/plmarriagetest.css')
    }
    showtest = async () => {
        if ((sessionStorage.getItem('UserId') !== "") && (sessionStorage.getItem('UserId') !== null) && (sessionStorage.getItem('UserId') !== "null")) {
            let exist = await CheckMarriageTest()
            if (exist === true) {
                Modal.confirm({
                    content: (<div><p>You have already taken a Test.</p><p style={{ marginLeft: 40 }}> Do you wish to take a Re-Test?</p></div>),
                    okText: "Take Test",
                    cancelText: "View Results",
                    onOk: this.MarriageTestClick,
                    onCancel: this.showReport
                });
            }
            else {
                this.MarriageTestClick();
            }
        }
        else {
            this.props.history.push('/MarriageTest')
        }
    }

    showReport = () => {
        this.props.history.push('/MarriageResult')
    }

    ChangePassword = async () => {
        var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        if (this.state.Password !== this.state.RepeatPassword) {
            Modal.warning({
                content: 'Password and Re-enter password does not match',
            });
        }
        else if (!(format.test(this.state.Password) && this.state.Password.length >= 6)) {
            Modal.warning({
                title: 'Password does not match the Criteria',
                content: 'Please enter more than 6 characters & 1 symbol',
            });
        }
        else if (this.state.Password === '' || this.state.RepeatPassword === '') {
            Modal.warning({
                content: 'All the fields should be filled!',
            });
        }
        else {
            var returnedData = await ChangePassword(this.state.OldPassword, this.state.Password, sessionStorage.getItem('UserId'));
            if (returnedData.success !== true) {
                Modal.error({ content: returnedData.alertMessage });
            }
            else {
                Modal.success({ content: 'Password changed successfully' })
                this.setState({ OldPassword: '', Password: '', RepeatPassword: '' })
            }
            if (returnedData === "") {
                Modal.error({ content: 'Server Error Occured' })
            }
            // this.setState({ Password: '', RepeatPassword: '', OldPassword: '' });
        }
    }

    ChangeEmail = async () => {
        debugger;
        var x = await CheckEmail(this.state.NewEmail);
        if (this.state.NewEmail === "" || this.state.NewEmail === null || this.state.NewEmail === undefined) {
            Modal.warning({
                content: 'New Email cannot be Empty',
            });
        }
        else if (!(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(this.state.NewEmail))) {
            Modal.warning({
                content: 'Please Enter a Valid Email',
            });
        }
        else if (x === true) {
            Modal.warning({
                content: 'Email id already exists . Please enter a different Email id',
            });
        }
        else {
            var returnedData = await ChangeEmail(this.state.NewEmail);
            if (returnedData.success !== true) {
                Modal.error({ content: "Invalid Credentials" });
            }
            else {
                Modal.success({ content: 'Email changed successfully' })
                this.setState({ NewEmail: '' })
            }
            if (returnedData === "") {
                Modal.error({ content: 'Server Error Occured' })
            }
            this.setState({ NewEmail: '' });
        }
    }

    dismissAll = () => {
        this.setState({ ChangePassword: false })
        this.setState({ ChangeEmail: false })
    }


    QuestionsClick = () => {
        //this.props.PageName("Questions");
        this.props.history.push('Questions')
    }

    MarriageTestClick = () => {
        this.props.history.push('MarriageTest')
    }

    TermsClick = () => {
        this.props.history.push('Terms')
    }

    render() {
        const { TextArea } = Input

        const items = this.state.StyleMatch;

        return (
            <div>
                <div class="w-embed w-iframe">
                    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WQNXXP9" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
                </div>
                <MetaTags>
                    <title>My Account</title>
                </MetaTags>
                <Header></Header>
                <div class="sectionone">
                    <div class="div-block-11">
                        {/* <p class="basicparagraph">USER ID:</p> */}
                        <h1 class="headline">Account </h1>
                        <p class="basicparagraph">We value your privacy, it is our business to safeguard it.<br />‍<br />1. We do not share your contact information unless you request it.<br />2. We do not sell your contact information or share it with partners.<br />3. We are not collecting data for advertising purposes.<br /><br /><span class="text-span-8" onClick={this.TermsClick}>Privacy Policy</span><br />‍<span class="text-span-7" onClick={this.TermsClick}>Terms and Conditions</span><br />‍</p>
                        <div class="div-block-23">
                            <div class="div-block-29">
                                <a class="actionbuttons w-button" onClick={this.showChangePassword}>Change Password</a>
                                <a class="actionbuttons w-button" onClick={this.QuestionsClick}>Questions</a>
                            </div>
                            <div class="div-block-24">
                                <a class="actionbuttons w-button" onClick={this.InviteClick}>Invites</a>
                                <a class="actionbuttons w-button" onClick={this.showtest}>Marriage TEST</a>
                            </div>
                            <div class="div-block-24">
                                <a class="actionbuttons w-button" onClick={this.AccountChangeClick}>Account Changes</a>
                                <a class="actionbuttons w-button" onClick={this.showChangeEmail}>Change email</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="ChangePasswordDiv">
                    {this.state.ChangePassword &&
                        <div ref={this.ChangePassw} class="w-layout-grid formgrid1">
                            <div id="w-node-78735125c002-933ed30c" class="formleft">
                                <h1 class="subheadline a">Change Password</h1>
                            </div>
                            <div id="w-node-535f9078c299-933ed30c" class="accountssectiondiv"></div>
                            <div id="w-node-efe23b320833-933ed30c" class="accountsection">
                                <div class="bluetitle">Change Password</div>

                                <div class="formboxtextentry w-inline-block">
                                    <Input type="password" required className="formfieldbox short" placeholder="Old Password" value={this.state.OldPassword} onChange={(e) => { this.setState({ OldPassword: e.target.value }) }} />
                                </div>
                                <div className="formboxtextentry w-inline-block">
                                    <Input type="password" required className="formfieldbox short" placeholder="New Password  (6 characters / 2 symbols)" value={this.state.Password} onChange={(e) => { this.setState({ Password: e.target.value }) }} />
                                </div>
                                <div className="formboxtextentry w-inline-block">
                                    <Input type="password" required placeholder="Repeat Password" className="formfieldbox short" value={this.state.RepeatPassword} onChange={(e) => { this.setState({ RepeatPassword: e.target.value }) }} />
                                </div>
                                <div class="savecaptcha">
                                    <a class="MyAccountsavebutton w-button" onClick={this.ChangePassword}>Save</a>
                                </div>
                            </div>
                        </div>}

                    {this.state.ChangeEmail &&
                        <div class="w-layout-grid formgrid1">
                            <div id="w-node-78735125c002-933ed30c" class="formleft">
                                <h1 class="subheadline a">Change Email</h1>
                            </div>
                            <div id="w-node-535f9078c299-933ed30c" class="accountssectiondiv"></div>
                            <div id="w-node-efe23b320833-933ed30c" class="accountsection">
                                <div class="bluetitle">Change Email</div>

                                <div class="formboxtextentry w-inline-block">
                                    <Input type="email" className="formfieldbox short" placeholder="New Email" value={this.state.NewEmail} onChange={(e) => { this.setState({ NewEmail: e.target.value }) }} />
                                </div>
                                <div class="savecaptcha">
                                    <a class="MyAccountsavebutton w-button" onClick={this.ChangeEmail}>Save</a>
                                </div>
                            </div>
                        </div>}

                    {this.state.AccountChange && <Information></Information>}

                    {this.state.InviteFriend && <InviteFriend />}
                </div>
                <BoxComponent></BoxComponent>
                <Footer></Footer>
            </div>
        )
    }
}

const mapStateToProp = state => {
    return {
        Value: state.Value
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        PageName: Value => dispatch(PageName(Value))
    }
}

export default connect(mapStateToProp, mapDispatchToProps)(MyAccount);