import './App.css';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module'
import { HashRouter as Router, Route, Switch, BrowserRouter, Link } from 'react-router-dom';
import Dashboard from './Component/Dashboard/Dashboard';
import MarriageTest from './Component/MarriageTest/MarriageTest';
import MarriageResult from './Component/MarriageResult/MarriageResult';
import ForgetPassword from './Component/ForgetPassword/ForgetPassword';
import Home from './Component/Home/Home';
import Register from './Component/Register/Register';
import Resource from './Component/Resourcess/Resource';
import Terms from './Component/TermsPolicy/TermsPolicy';
import Questions from './Component/Question/question';
import Advice from './Component/GetAdvice & Articles/ProfessionalProfiles';
import Profile from './Component/Profile/Profile';
import MyAccount from './Component/MyAccount/MyAccount';
import Articles from './Component/Articles/Articles'
import store from '../src/Redux/Store'
import { Provider } from 'react-redux'
import FAQ from './Component/FAQ/FAQ';
import AllProfiles from './Component/GetAdvice & Articles/AllProfiles';
import UserProfileLink from './Component/UserProfileLink/UserProfileLink';
import PopularQuestions from './Component/Question/PopularQuestions';
import NewHome from './Component/Home/NewHome';

function App() {

  ReactGA.initialize('G-JT4HS6ZZSX');

  const tagManagerArgs = {
    gtmId: 'GTM-TCJS738'
  }
  TagManager.initialize(tagManagerArgs)

  window.dataLayer.push({
    event: 'pageview'
  });

  return (
    <Provider store={store}>
      <div className="App">
        {/* <BrowserRouter basename="/"> */}
        <Router>
          <Route
            path="/"
            render={({ location }) => {
              window.gtag('config', 'AW-10806936548', {
                page_path: location.pathname + location.search,
              });
              window.gtag('config', 'G-JT4HS6ZZSX', {
                page_path: location.pathname + location.search,
              });
              // console.log("pathname" + location.pathname + " " + "search" + location.search)
            }}
          />
          <Switch>
            <Route exact path='/' component={NewHome} />
            <Route path='/Home' component={NewHome} />
            <Route path='/Videos' component={Resource} />
            <Route path='/Register' component={Register} />
            <Route path='/Terms' component={Terms} />
            <Route path='/Questions' component={Questions} />
            <Route path='/PopularQnA' component={PopularQuestions} />
            <Route path='/Advice' component={Advice} />
            <Route path='/Profile' component={Profile} />
            <Route path='/MyAccount' component={MyAccount} />
            <Route path='/Articles' component={Articles} />
            <Route path='/MarriageTest' component={MarriageTest} />
            <Route path='/ForgetPassword' component={ForgetPassword} />
            <Route path='/MarriageResult' component={MarriageResult} />
            <Route path='/FAQ' component={FAQ} />
            <Route path='/AllProfiles' component={AllProfiles} />
            <Route path='/:handle' component={Profile} />
            {/* <Route path='/UserProfile' component={UserProfileLink} /> */}
          </Switch>
        </Router>
        {/* </BrowserRouter> */}
      </div>
    </Provider>
  );
}

export default App;