import React from 'react';
import { Button, Modal, Input, Slider, Popover, Space, Carousel, Menu, Dropdown, Row, Col, Card, Divider, Form, Radio, Checkbox, message } from 'antd';
import 'antd/dist/antd.css';
import RLDD from 'react-list-drag-and-drop/lib/RLDD';
import '../Css/Peachy.css';
import { RegisterUser, SaveUserInformation, GetUserInformation, CheckEmail } from './RegisterService'
import { connect } from 'react-redux';
import { PageName, ForgetPwdEmail } from '../../Redux/Redux.Action';
import Information from '../Information/Information'
import BoxComponent from '../BoxComponent/BoxComponent'
import ReCAPTCHA from "react-google-recaptcha";
import Social from '../Social/Social';
import InviteFriend from '../InviteFriend/InviteFriend';
import Footer from '../Footer/Footer';
import Header from '../Dashboard/Dashboard';
import ReactGA from 'react-ga';
import { MetaTags } from 'react-meta-tags';

class Register extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Name: '', Email: '', Password: '', RepeatPassword: '', loginShow: false, AdviceMenu: [], ArticlesMenu: [], ReCaptchaResponse: false,
        }
    }

    FinalSubmit = async () => {
        debugger;
        //if (this.state.ReCaptchaResponse) {
        var x = await CheckEmail(this.state.Email);
        if (x === true) {
            Modal.warning({
                content: 'Email id already exists . Please login to continue',
            });
        }
        else {
            var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
            var returnedData;
            if (this.state.Name === "") {
                Modal.warning({
                    content: 'Please enter your Name',
                });
            }
            else if (this.state.Email === "" || this.state.Password === "") {
                Modal.warning({
                    content: 'Please enter your Username and Password',
                });
            }
            else if (this.state.Password != this.state.RepeatPassword) {
                Modal.warning({
                    content: 'Passwords does not match',
                });
            }
            else if (!(format.test(this.state.Password) && this.state.Password.length >= 6)) {
                Modal.warning({
                    title: 'Password does not match the Criteria',
                    content: 'Please enter more than 6 characters & 1 symbol',
                });
            }
            else if (!(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(this.state.Email))) {
                Modal.warning({
                    content: 'Please Enter a Valid Email',
                });
            }
            else {
                var returnedData = await RegisterUser(this.state.Name, this.state.Email, this.state.Password);
                if (returnedData.userID != "") {
                    Modal.success({
                        content: 'You have successfully registered.',
                    });
                    window.scrollTo({ top: 60 })
                }
                else {
                    Modal.warning({
                        content: 'User already exists, Please Login',
                    });
                }
                sessionStorage.setItem('UserId', returnedData.userID);
            }
        }
        // }
        // else {
        //     message.error("Please verify that you are not a robot!")
        // }
    }

    TermsClick = () => {
        this.props.history.push('/Terms')
    }

    componentWillMount() {
        import('../Css/components.css')
        import('../Css/normalize.css')
        import('../Css/plmarriagetest.css')

        ReactGA.initialize('G-JT4HS6ZZSX');
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.gtag('event', 'conversion', { 'send_to': 'AW-10806936548/AfZeCJKY9YQDEOSHk6Eo' });

    }

    RecaptchaFunc = async (value) => {
        let Success;

        console.log("Captcha value:", value);

        const data = {
            method: 'POST',
            mode: 'no-cors',
            headers: { 'Content-Type': 'application/json' },
        };
        await fetch('https://www.google.com/recaptcha/api/siteverify?secret=6Lcrs9kcAAAAALK-9I8VvRkCXpZ3gFAXWONFhSqO&response=' + value, data)
            .then((response) => response.json())
            .then((responseJson) => {
                Success = responseJson;
                console.log(Success);
            })

        if (Success.success) {
            this.setState({ ReCaptchaResponse: true })
        }
    }

    render() {
        const { TextArea } = Input

        const items = this.state.StyleMatch;

        return (
            <div>
                <div class="w-embed w-iframe">
                    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WQNXXP9" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
                </div>
                <MetaTags>
                    <title>Register</title>
                </MetaTags>
                <Header></Header>
                <div class="sectionone">
                    <div class="div-block-11">
                        {/* <p class="basicparagraph">USER ID:</p> */}
                        <h1 class="headline">Register </h1>
                        <p class="basicparagraph">We value your privacy, it is our business to safeguard it.<br />‍<br />1. We do not share your contact information unless you request it.<br />2. We do not sell your contact information or share it with partners.<br />3. We are not collecting data for advertising purposes.<br /><br />
                            <span class="text-span-8 privacy" onClick={this.TermsClick}>Privacy Policy</span><br />‍
                        <span class="text-span-7 privacy" onClick={this.TermsClick}>Terms and Conditions</span><br />‍</p>
                    </div>
                    <div class="div-block-21">
                        <div class="w-layout-grid formgrid1">
                            <div id="w-node-78735125c002-933ed30c" class="formleft">
                                <h1 class="subheadline a">ACCOUNT</h1>
                                <h3 class="heading j">We will never share or abuse this</h3>
                                <h3 class="heading b">Re-enter password</h3>
                                <div class="div-block-22">
                                    <div><img src={process.env.PUBLIC_URL + '/images/RobotHead.png'} loading="lazy" alt="" class="image-14" /></div>
                                    <h3 class="heading">Click here to help ensure<br /> the robots don’t win</h3>
                                </div>
                            </div>
                            <div id="w-node-535f9078c299-933ed30c" class="accountssectiondiv"></div>
                            <div id="w-node-efe23b320833-933ed30c" class="accountsection">
                                <Form style={{ maxWidth: '500px' }}>
                                    <div class="bluetitle">Set up your Account</div><br /><br />
                                    <Form.Item name="name" className="formboxtextentry w-inline-block">
                                        <Input className="RegisterPlaceHolder" placeholder="Your Name" onChange={(e) => { this.setState({ Name: e.target.value }) }} />
                                    </Form.Item>

                                    <Form.Item name="email" className="formboxtextentry w-inline-block"
                                        rules={[{ type: 'email', message: 'The input is not valid E-mail!', },
                                        {
                                            required: true,
                                            message: 'Please input your E-mail!',
                                        },]}>
                                        <Input className="RegisterPlaceHolder" placeholder="Email Address" onChange={(e) => { this.setState({ Email: e.target.value }) }} />
                                    </Form.Item>

                                    <Form.Item name="password" id="password" className="formboxtextentry w-inline-block"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your password!',
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
                                                    if (value.length >= 6 && format.test(value)) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject();
                                                }
                                            })
                                        ]}
                                        hasFeedback
                                    >
                                        <Input type="password" placeholder="Password  (6 characters / 1 symbols)" onChange={(e) => { this.setState({ Password: e.target.value }) }} />
                                    </Form.Item>

                                    <Form.Item name="confirm" dependencies={['password']} hasFeedback className="formboxtextentry w-inline-block"
                                        rules={[{ required: true, message: 'Please confirm your password!', },
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject('The two passwords that you entered do not match!');
                                            },
                                        }),
                                        ]}
                                    >
                                        <Input type="password" placeholder="Repeat Password" onChange={(e) => { this.setState({ RepeatPassword: e.target.value }) }} />
                                    </Form.Item>
                                    <div class="savecaptcha">
                                        <ReCAPTCHA class="captcha w-button" sitekey="6Lcrs9kcAAAAADw_oaTgaNyZ2HRvfv0mq0MArCxK" onChange={this.RecaptchaFunc} />
                                        {/* SECRET KEY - 6Lcrs9kcAAAAALK-9I8VvRkCXpZ3gFAXWONFhSqO */}
                                        <a href="#" class="basicsavebutton w-button" onClick={this.FinalSubmit} >Save</a>
                                    </div>
                                </Form>
                            </div>
                        </div>
                        <Information></Information>
                        <InviteFriend />
                        <Social></Social>
                        <h1 class="headline-center">PeachyLife is full of articles, books, resources and professionals to help you with your relationship.</h1>
                    </div>
                </div>
                <BoxComponent></BoxComponent>
                <Footer></Footer>
            </div>
        )
    }
}

const mapStateToProp = state => {
    return {
        Value: state.Value
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        PageName: Value => dispatch(PageName(Value))
    }
}

export default connect(mapStateToProp, mapDispatchToProps)(Register);