import { ServiceUrl } from '../ServiceURL';

export async function GetPageInformation(Category) {
    debugger;
    let Success = '';
    const data = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ Category: Category })
    };
    await fetch(ServiceUrl + '/api/GetAdviceTabContent', data)
        .then((response) => response.json())
        .then((responseJson) => {
            Success = responseJson;
        })
    return Success;
}

export async function GetAllSpecialistInformation() {
    debugger;
    let Success = '';
    const data = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };
    await fetch(ServiceUrl + '/api/GetAllSpecialistInformation', data)
        .then((response) => response.json())
        .then((responseJson) => {
            Success = responseJson;
        })
    return Success;
}

export async function GetbyExperience(Category) {
    let Success = '';
    const data = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ Category: Category })
    };
    await fetch(ServiceUrl + '/api/GetbyExperiance', data)
        .then((response) => response.json())
        .then((responseJson) => {
            Success = responseJson;
        })
    return Success;
}

export async function GetbyRating(Category) {
    let Success = '';
    const data = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ Category: Category })
    };
    await fetch(ServiceUrl + '/api/GetbyRating', data)
        .then((response) => response.json())
        .then((responseJson) => {
            Success = responseJson;
        })
    return Success;
}