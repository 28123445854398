import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { PageName, AdviceCategory } from '../../Redux/Redux.Action';
import { ProgressBar } from 'react-bootstrap';
import 'antd/dist/antd.css';
import { Button, Modal, Input, Popover, Space, Carousel, Menu, Dropdown, Row, Col, Card, Divider, Form } from 'antd';
import BoxComponent from '../BoxComponent/BoxComponent';
import { GetMarriageResult } from './MarriageResultService.js';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Social from '../Social/Social'
import InviteFriend from '../InviteFriend/InviteFriend';
import Footer from '../Footer/Footer';
import Header from '../Dashboard/Dashboard';
import { ServiceUrl, FilesURL } from '../ServiceURL';
import ReactGA from 'react-ga';
import { MetaTags } from 'react-meta-tags';

class MarriageResult extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Email: '', page1value: '', page2value: '', page3value: '', Password: '', LoginStatus: false, Result: [], AdviceMenu: [],
            ArticlesMenu: [], page1total: '', page2total: '', page3total: '', Totalvalue: '', pagetotal: ''
        }
        AOS.init({ duration: 1000 });
    }
    async componentDidMount() {
        debugger;

        import('../Css/components.css')
        import('../Css/normalize.css')
        import('../Css/plmarriagetest.css')

        const script = document.createElement("script");
        //script.src = "http://192.227.67.200/Peachylife_/static/js/plmarriagetest3-944b06bbb-864f41afbdea5.js";
        script.src = FilesURL + "/Peachylife_/static/js/plmarriagetest3-944b06bbb-864f41afbdea5.js";
        script.async = true;
        document.body.appendChild(script);

        ReactGA.initialize('G-JT4HS6ZZSX');
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.gtag('event', 'conversion', { 'send_to': 'AW-10806936548/AfZeCJKY9YQDEOSHk6Eo' });

        var MarriageResult
        // const query = this.props.match.params.UserId;

        // if (query !== undefined) {
        //     MarriageResult = await GetMarriageResult(query)
        // }
        // else {
        MarriageResult = await GetMarriageResult(sessionStorage.getItem('UserId'))
        //}

        if (MarriageResult !== undefined || MarriageResult !== null) {
            this.setState({ Result: MarriageResult })
        }

        this.calculation();
        window.scrollTo({ top: 0 })
    }

    calculation = () => {
        debugger;
        var Page1percent = (this.state.Result.page1Score / 40) * 100
        var page1totalvalue = 100 - Page1percent;
        var Page2percent = (this.state.Result.page2Score / 40) * 100
        var page2totalvalue = 100 - Page2percent;
        var Page3percent = (this.state.Result.page3Score / 40) * 100
        var page3totalvalue = 100 - Page3percent;
        var Page = (this.state.Result.total / 120) * 100
        var Total = 100 - Page;
        this.setState({
            page1value: Page1percent, page2value: Page2percent, page3value: Page3percent, Totalvalue: Total,
            page1total: page1totalvalue, page2total: page2totalvalue, page3total: page3totalvalue, pagetotal: Page
        })
    }
    render() {
        return (
            <div>
                <div class="w-embed w-iframe">
                    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WQNXXP9" height="0" width="0" style={{ display: "none", visibility: "hidden" }}></iframe></noscript>
                </div>
                <MetaTags>
                    <title>Marriage Result</title>
                </MetaTags>
                <Header></Header>
                <div class="sectionone">
                    <div class="div-block-11">
                        {/* <p class="basicparagraph">USER ID:</p> */}
                        <h1 class="headline">Marriage Test Results</h1>
                        <p class="basicparagraph">Congratulations, you have completed the marriage test. The following summary of results is a guide, please keep in mind it’s only based on a few questions.</p>
                    </div>
                    <div class="div-block-21">
                        <div class="w-layout-grid grid-4">
                            <div id="w-node-535f9078c299-1447e187" class="div-block-12"></div>
                            <div data-aos="fade-left" data-aos-anchor-placement="center-bottom" id="w-node-35c7527139b9-1447e187" data-w-id="db22bffe-b9f2-7134-acf2-35c7527139b9" class="div-block-14"><img src={process.env.PUBLIC_URL + '/images/Image3.png'}></img></div>
                            <div id="w-node-efe23b320833-1447e187" class="resultsone">
                                <p class="basicparagraph">SECTION ONE:</p>
                                <h1 class="subheadline">COMMUNICATION</h1>
                                <div class="div-block-16">
                                    <div class="div-block-17">
                                        <p class="paragraph-3">GRADE:</p>
                                        <div class="div-block-15"><span>{this.state.Result.page1Score <= 10 ? <img src={process.env.PUBLIC_URL + '/images/GradeA.png'}></img> : this.state.Result.page1Score <= 20 ? <img src={process.env.PUBLIC_URL + '/images/GradeB.png'}></img> : this.state.Result.page1Score <= 30 ? <img src={process.env.PUBLIC_URL + '/images/GradeC.png'}></img> : this.state.Result.page1Score <= 40 ? "D" : null}</span></div>
                                    </div>
                                    <div id="marriageResult">
                                        <ProgressBar className="bargraph _2">
                                            <ProgressBar style={{ background: "#fff", borderTopLeftRadius: 6, borderBottomLeftRadius: 6, paddingLeft: 10 }} className="ResultProgress" now={this.state.page1value} label={this.state.Result.page1Score} key={1}></ProgressBar>
                                            <ProgressBar style={{ background: "#ff6778", color: '#fff', textAlign: 'right', borderTopRightRadius: 6, borderBottomRightRadius: 6, paddingRight: 10 }} className="ResultProgress" now={this.state.page1total} label={40} key={2}></ProgressBar>
                                        </ProgressBar>
                                    </div>
                                </div>
                                <p class="basicbodytext"><span class="text-span-6">Love Language</span><br />You and your spouse have the same love language and it’s serving your relationship well. Continue to nurture the positive communication you have together and it will flourish for all your years together.</p>
                            </div>
                        </div>
                        <div class="w-layout-grid grid-4">
                            <div id="w-node-c65ef50970e6-1447e187" class="div-block-12"></div>
                            <div id="w-node-c65ef50970e7-1447e187" class="resultsone">
                                <p class="basicparagraph">SECTION TWO:</p>
                                <h1 class="subheadline">MUTUAL RESPECT</h1>
                                <div class="div-block-16">
                                    <div class="div-block-17">
                                        <p class="paragraph-3">GRADE:</p>
                                        <div class="div-block-15 _2"><span>{this.state.Result.page2Score <= 10 ? <img src={process.env.PUBLIC_URL + '/images/GradeA.png'}></img> : this.state.Result.page2Score <= 20 ? <img src={process.env.PUBLIC_URL + '/images/GradeB.png'}></img> : this.state.Result.page2Score <= 30 ? <img src={process.env.PUBLIC_URL + '/images/GradeC.png'}></img> : this.state.Result.page2Score <= 40 ? "D" : null}</span></div>
                                    </div><div id="marriageResult"><ProgressBar className="bargraph _2">
                                        <ProgressBar style={{ background: "#fff", borderTopLeftRadius: 6, borderBottomLeftRadius: 6, paddingLeft: 10 }} className="ResultProgress" now={this.state.page2value} label={this.state.Result.page2Score} key={1}></ProgressBar>
                                        <ProgressBar style={{ background: "#ff6778", color: '#fff', textAlign: 'right', borderTopRightRadius: 6, borderBottomRightRadius: 6, paddingRight: 10 }} className="ResultProgress" now={this.state.page2total} label={40} key={2}></ProgressBar>
                                    </ProgressBar></div>
                                </div>
                                <p class="basicbodytext"><span class="text-span-6">Floating Along</span><br />You and your partner are in the same boat and it’s fairly smooth sailing. Think about ways that you might build upon the respect in your marriage to make your relationship even better so that your love with be strong for many years.</p>
                            </div>
                            <div data-aos="fade-left" data-aos-anchor-placement="center-bottom" id="w-node-c65ef50970f7-1447e187" data-w-id="cc460a75-c028-0b8e-fd43-c65ef50970f7" class="div-block-14 _2"><img src={process.env.PUBLIC_URL + '/images/Image1.png'}></img></div>
                        </div>
                        <div class="w-layout-grid grid-4">
                            <div id="w-node-bd31ba3dcf2f-1447e187" class="div-block-12"></div>
                            <div id="w-node-bd31ba3dcf30-1447e187" class="resultsone">
                                <p class="basicparagraph">SECTION THREE:</p>
                                <h1 class="subheadline">TRUST AND SATISFACTION</h1>
                                <div class="div-block-16">
                                    <div class="div-block-17">
                                        <p class="paragraph-3">GRADE:</p>
                                        <div class="div-block-15 _1"><span>{this.state.Result.page3Score <= 10 ? <img src={process.env.PUBLIC_URL + '/images/GradeA.png'}></img> : this.state.Result.page3Score <= 20 ? <img src={process.env.PUBLIC_URL + '/images/GradeB.png'}></img> : this.state.Result.page3Score <= 30 ? <img src={process.env.PUBLIC_URL + '/images/GradeC.png'}></img> : this.state.Result.page3Score <= 40 ? "D" : null}</span></div>
                                    </div><div id="marriageResult"><ProgressBar className="bargraph _2">
                                        <ProgressBar style={{ background: "#fff", borderTopLeftRadius: 6, borderBottomLeftRadius: 6, paddingLeft: 10 }} className="ResultProgress" now={this.state.page3value} label={this.state.Result.page3Score} key={1}></ProgressBar>
                                        <ProgressBar style={{ background: "#ff6778", color: '#fff', textAlign: 'right', borderTopRightRadius: 6, borderBottomRightRadius: 6, paddingRight: 10 }} className="ResultProgress" now={this.state.page3total} label={40} key={2}></ProgressBar>
                                    </ProgressBar></div>
                                </div>
                                <p class="basicbodytext"><span class="text-span-6">Turning Green</span><br />Are you feeling a bit ill from your bumpy relationship? It’s difficult to feel satisfied when your head is spinning from the inconsistencies in your marriage.  Seeking out guidance in couples counseling could help you and your partner build a steadier relationship.</p>
                            </div>
                            <div data-aos="fade-left" data-aos-anchor-placement="center-bottom" id="w-node-bd31ba3dcf40-1447e187" data-w-id="7ee44a8d-b084-6363-8017-bd31ba3dcf40" class="div-block-14 _3"><img src={process.env.PUBLIC_URL + '/images/Image2.png'}></img></div>
                        </div>
                        <div class="w-layout-grid grid-4">
                            <div id="w-node-f770d553684c-1447e187" class="div-block-12"></div>
                            <div id="w-node-f770d553684d-1447e187" class="resultsone">
                                <h1 class="subheadline">OVERALL</h1>
                                <div class="div-block-16">
                                    <div class="div-block-17">
                                        <p class="paragraph-3">GRADE:</p>
                                        <div class="div-block-15 _2"><span>{this.state.Result.total <= 30 ? <img src={process.env.PUBLIC_URL + '/images/GradeA.png'}></img> : this.state.Result.total <= 60 ? <img src={process.env.PUBLIC_URL + '/images/GradeB.png'}></img> : this.state.Result.total <= 90 ? <img src={process.env.PUBLIC_URL + '/images/GradeC.png'}></img> : this.state.Result.total <= 120 ? "D" : null}</span></div>
                                    </div><div id="marriageResult"><ProgressBar className="bargraph _2">
                                        <ProgressBar style={{ background: "#fff", borderTopLeftRadius: 6, borderBottomLeftRadius: 6, paddingLeft: 10 }} className="ResultProgress" now={this.state.pagetotal} label={this.state.Result.total} key={1}></ProgressBar>
                                        <ProgressBar style={{ background: "#ff6778", color: '#fff', textAlign: 'right', borderTopRightRadius: 6, borderBottomRightRadius: 6, paddingRight: 10 }} className="ResultProgress" now={this.state.Totalvalue} label={120} key={2}></ProgressBar>
                                    </ProgressBar></div>
                                </div>
                                <p class="basicbodytext"><span class="text-span-6">How things look</span><br />Are you feeling a bit ill from your bumpy relationship? It’s difficult to feel satisfied when your head is spinning from the inconsistencies in your marriage.  Seeking out guidance in couples counseling could help you and your partner build a steadier relationship.</p>
                            </div>
                            <div data-aos="fade-left" data-aos-anchor-placement="center-bottom" id="w-node-f770d553685b-1447e187" data-w-id="b15a51ee-1115-6133-a96c-f770d553685b"><div class="div-block-15 _2"><span>{this.state.Result.total <= 30 ? <img src={process.env.PUBLIC_URL + '/images/GradeA.png'}></img> : this.state.Result.total <= 60 ? <img src={process.env.PUBLIC_URL + '/images/GradeB.png'}></img> : this.state.Result.total <= 90 ? <img src={process.env.PUBLIC_URL + '/images/GradeC.png'}></img> : this.state.Result.total <= 120 ? "D" : null}</span></div></div>
                        </div>
                        <InviteFriend />
                        <Social></Social>
                    </div>
                </div>
                <BoxComponent></BoxComponent>
                <Footer></Footer>
            </div>
        )
    }
}
export default MarriageResult