import { ServiceUrl } from '../ServiceURL';

export async function GetMarriageResult(Id) {
    debugger;
    let GetUserField = []; //https://localhost:5001/api/GetTestReport
    const data = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ UserId: Id })
    };
    await fetch(ServiceUrl + '/api/GetTestReport', data) //http://192.227.67.200/peachylifeapi/api/GetTestReport
        .then((response) => response.json()) //https://peachylife.com/peachylifeapi/api/
        .then((responseJson) => {
            GetUserField = responseJson;
        })
    return GetUserField;
}